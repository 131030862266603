import React from "react";
import axios from "axios";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import * as myConsList from "../../Common/BaseUrl";
import socketIOClient from "socket.io-client";
import TickerTable from "../../ExchangeFull/TickerTable";


const baseUrl = myConsList.baseUrl;
const imgUrl = myConsList.imgUrl;

class Table extends React.Component {

  constructor(props) {
    
    super(props);
    //console.log('table props--->',  this.props.frontData[1])
    this.socket = socketIOClient('wss://stream.dbiexchange.com');

    // let ids = setInterval(
    //this.GetCoinData()

    //   1500);
    this.state = {
      isActive: false,
      // intervelId: ids
    }
    //window.addEventListener('beforeunload', this.beforeUnload);
  }
  componentDidMount() {
    // this.socket.open();
    // this.socket.emit('coin_Pair_Data');
    // this.socket.on('coin_Pair_Data_on', (resp) => {
    //  // console.log(resp);
    //   this.setState({ CoinTableData: resp.data, coinTableStatus: resp.status });
    //   // we get settings data and can do something with it
    //   // this.setState({
    //   //   settings: data,
    //   // })
    // });
  }

  componentWillUnmount() {
    this.socket.close();
  }

  beforeUnload = () => {
    //console.log("before call");
  }
  GetCoinData = () => {
    //console.log("api call",this.state);
    let streams = [];
    axios.get(baseUrl + "/frontapi/Coin_Pair_Data", {}).then((resp) => {
      // console.log(resp.data.data);

      resp.data.data.map((val) => {
       // console.log(val);
        if (val.coin_first_name != "CTC7" && val.coin_first_name != "CTC(TM)" && val.coin_first_name !="DBI") {
          let coin = (val.coin_first_name + val.coin_Second_name).toLowerCase() + "@ticker";
          // streams = streams + coin+"@ticker/"
          streams.push(coin)
        }
        // console.log(coin);
      })
      streams = streams.join("/");
      // console.log(streams);

      // var resp = resp.data;

    //  this.setState({ pairData: resp.data.data, coinTableStatus: resp.status, streams });
      this.getbinance(streams);
      //clearInterval(this.state.intervelId);
    })
  };

  getbinance = (streams) => {
   // console.log("getbinance => ", streams);
    // ethusdt@ticker/btcusdt@ticker/solusdt@ticker/bnbusdt@ticker/maticusdt@ticker/dotusdt@ticker/dogeusdt@ticker/shibusdt@ticker/etcusdt@ticker/xrpusdt@ticker/atomusdt@ticker/manausdt@ticker/bttcusdt@ticker/linkusdt@ticker/adausdt@ticker/vetusdt@ticker/avaxusdt@ticker/chrusdt@ticker/spellusdt@ticker/bicousdt@ticker/trxbnb@ticker/solbnb@ticker/avaxbnb@ticker/maticbnb@ticker/xrpbnb@ticker/etcbnb@ticker/linkbnb@ticker/atombnb@ticker/adabnb@ticker/chrbnb@ticker/atombtc@ticker/avaxbtc@ticker/trxbtc@ticker/solbtc@ticker/dogebtc@ticker/maticbtc@ticker/manabtc@ticker/xrpbtc@ticker/etcbtc@ticker/dotbtc@ticker/linkbtc@ticker/axsusdt@ticker/axsbtc@ticker/adabtc@ticker/cakeusdt@ticker/cakebtc@ticker/cakebnb@ticker/aaveusdt@ticker/aavebtc@ticker/aavebnb@ticker/bnbbtc@ticker/dbiusdt@ticker/ctc(tm)usdt@ticker/ctc7usdt@ticker/

    let binanceWebsocket = new WebSocket(`wss://stream.binance.com:9443/stream?streams=`+streams);
    binanceWebsocket.onmessage = async (evt) => {
      const eventData = JSON.parse(evt.data);
      if (eventData.stream.endsWith("@ticker")) {
        // console.log("========> ");
        const obj = eventData.data;
        // console.log("obj => ", obj.s);
        //  console.log(this.state.pairData);
        let tempData = this.state.pairData;
        if (tempData != undefined) {
        // console.log(tempData);
        let obj1 = tempData.findIndex(o => (o.coin_first_name + o.coin_Second_name) === obj.s);
        // console.log(obj," ==> ",obj1);
        // tempData[obj1] = {currentPrice:0}
        if (obj1 != -1) {
          tempData[obj1]['c'] = obj.c;
          tempData[obj1]['l'] = obj.l
          tempData[obj1].p = obj.p
          tempData[obj1]['h'] = obj.h
          tempData[obj1].q = obj.q

          tempData[obj1]['oneDayMaxPrice'] = obj.h
          tempData[obj1].oneDayMinPrice = obj.l
          tempData[obj1].currentPrice = obj.c
          tempData[obj1].changePriceAmount = obj.p
          tempData[obj1].oneDayVolume = obj.q


         // this.setState({ pairData: tempData });
        }
      }
      }
    }
  }





  AddressListHtml = () => {

    if (this.state.pairData || this.state.binanceTicker) {

      const html = [];

      this.state.pairData.map(function (value, i) {
        // if (value.coin_Second_name === "USDT") {

        html.push(
          <tr>
            <td >{<img src={baseUrl + "/static/currencyImage/" + value.coinIcon} />}</td>
            <td>
              {value.coin_first_name} / {value.coin_Second_name}
            </td>
            <TickerTable {...value} />

            {/* <td>{parseFloat(value.currentPrice ? value.currentPrice : 0).toFixed(2)}</td>
              <td>{parseFloat(value.changePriceAmount ? value.changePriceAmount : 0).toFixed(2)}</td>
              <td>{parseFloat(value.oneDayMaxPrice ? value.oneDayMaxPrice : parseFloat(value.currentPrice ? value.currentPrice : 0).toFixed(2))}</td>
              <td>{parseFloat(value.oneDayMinPrice ? value.oneDayMinPrice : parseFloat(value.currentPrice ? value.currentPrice : 0).toFixed(2))}</td>
              <td>{parseFloat(value.oneDayVolume ? value.oneDayVolume : 0).toFixed(2)}</td> */}
          </tr>
        );
        //}
      });

      return <tbody className="main">{html}</tbody>;
    }
  };
  changeClass = () => {
    //this.setState({ isActive: !this.state.isActive })
  }
  render() {
    const { t } = this.props;
   // console.log('table t--->',  baseUrl)
    return (
      <div className="p60 intro-main-outer" id="token" data-scroll-index="3">
        <div className="container">
          <div className="intro-outer">
            <div className="row align-items-center">
              <div className="col-md-4 col-12 ml-auto">
                <div className="intro-left">
                  {/* <img src="assets/img/logo-main350.png" alt="" className="img-fluid" /> */}
                  <img 
                  src={imgUrl+'/static/front/'+this.props.frontData[1]?.image} 
                  alt="" className="img-fluid" /> 
                  
                </div>
              </div>
              <div className="col-md-6 col-12 mr-auto">
                <div className="intro-right">
                  {/* <h2>{t("Introducing DBIEXC")}</h2> */}
                  <h2 dangerouslySetInnerHTML={{__html: this.props.frontData[1]?.title}}></h2>
                  {/* <h2>{t("Introducing DBIEXC")}</h2>  */}
                  {/* <h6> {t("dbiexc is a currency exc developed by Hans Block.")}</h6> */}
                  {/* <p>{t("By introducing new technology, dbiexc protects customer assets by linking all assets of exc users with a safe token bank.")} {t("Token bank: (Token bank refers to a bank that manages tokens and cash. Customer assets can be withdrawn through the banking app).")}</p> */}
                  <p dangerouslySetInnerHTML={{__html: this.props.frontData[1]?.description}}></p>

                </div>
              </div>
            </div>
          </div>
          {/* <div className="white_box5">
            <h4 className=" ">{t("DBI Market Price")}</h4>
            <div className="table-responsive">
              <div className="scrool_wallet_box">
                <table className=" table table-striped mb-0 ">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{t("Pair")}</th>
                      {/* <th>Coin</th> 
                      <th>{t("Last Price")} ($)</th>
                      <th>{t("24h")} {t("Change")} ($)</th>
                      <th>{t("24h-High")} ($)</th>
                      <th>{t("24h-Low")} ($)</th>
                      <th>{t("24h Vol")} ($)</th>
                    </tr>
                  </thead>
                  this.AddressListHtml()
                </table>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Table);
