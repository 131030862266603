import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import socketIOClient from "socket.io-client";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "../Common/BaseUrl";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import Moment from "moment";
var moment = require('moment-timezone');
const baseUrl = myConstList.baseUrl;
const socketUrl = myConstList.socketUrl;

const options = {
  rangeSelector: {
    selected: 1,
  },

  title: {
    text: "Price",
  },

  series: [
    {
      type: "candlestick",
      // name: 'BTC Price',
      data: [],
      dataGrouping: {
        units: [
          [
            "day", // unit name
            [1], // allowed multiples
          ],
          [
            "week", // unit name
            [1], // allowed multiples
          ],
          ["month", [1, 2, 3, 4, 6]],
        ],
      },
    },
  ],
};

// const siteBaseUrl = window.location.protocol + "//" + window.location.host;
class Exchange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "Guest",
      setUrl: this.props.match.params.secondCoin,
      exchange: "",
      priceChange: "",
      priceChangePercentage: "",
      buyButton: "",
      sellButton: "",
      loadingState: true,
      firstCoin: "",
      secondCoin: "",
      firstCoinId: "",
      secondCoinId: "",
      coinListData: "",
      searchedValue: "",
    };
    this.tradesCount = 10;
    this.streams = ["@ticker", "@depth20", "@trade"];
  }

  _connectSocketStreams(streams) {
    streams = streams.join("/");
    let connection = btoa(streams);
    this[connection] = new WebSocket(
      `wss://stream.binance.com:9443/stream?streams=${streams}`
    );
    this[connection].onmessage = (evt) => {
      let eventData = JSON.parse(evt.data);
      if (eventData.stream.endsWith("@ticker")) {
        eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0;
        this.props.dispatch({
          type: "SET_TICKER",
          data: eventData.data,
        });
        this.setState({
          loadedTicker: true,
        });
      }
      if (eventData.stream.endsWith("@trade")) {
        if (this.props.trades && Object.keys(this.props.trades).length > 0) {
          let trades = this.props.trades;
          trades.push(eventData.data);
          trades = trades.slice(-1 * this.tradesCount);
          this.props.dispatch({
            type: "SET_TRADES",
            data: trades,
          });
          this.setState({
            loadedTrades: true,
          });
        }
      }
      if (eventData.stream.endsWith("@depth20")) {
        this.props.dispatch({
          type: "SET_DEPTH",
          data: eventData.data,
        });
        this.setState({
          loadedDepth: true,
        });
      }
      this.setState({
        isLoaded: true,
      });
    };
    this[connection].onerror = (evt) => {
      console.error(evt);
    };
  }

  _disconnectSocketStreams(streams) {
    streams = streams.join("/");
    let connection = btoa(streams);
    if (this[connection].readyState === WebSocket.OPEN) {
      this[connection].close();
    }
  }

  componentDidMount() {

    console.log(moment.tz.guess());
    this.startDataLoad();
    //"Asia/Kolkata"

    var pairName =
      this.props.match.params.firstCoin + this.props.match.params.secondCoin;
      console.log(pairName);
    var widget = (window.tvWidget = new window.TradingView.widget({
      // debug: true, // uncomment this line to see Library errors and warnings in the console
      fullscreen: false,
      symbol: pairName,
      interval: "5",
      container: "tv_chart_container1",
      height: 400,
      width: "100%",

      //	BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        baseUrl + "/frontapi/"
      ),
      library_path: "/charting_library/",
      locale: "en",

      disabled_features: ["use_localstorage_for_settings","create_volume_indicator_by_default"],
      enabled_features: ["study_templates", "hide_left_toolbar_by_default"],
      charts_storage_url: "https://saveload.tradingview.com",
      charts_storage_api_version: "1.1",
      client_id: "tradingview.com",
      user_id: "public_user_id",
      loading_screen: { backgroundColor: "#1a1a1a" },
      theme: "dark",
      timezone: "Asia/Kolkata",
      overrides: {
        "mainSeriesProperties.style": 1,
        // "paneProperties.background": "#14264B",
        // "paneProperties.vertGridProperties.color": "#14264B",
        // "paneProperties.horzGridProperties.color": "#14264B",
        // "scalesProperties.textColor" : "#AAA"
      },
      drawings_access: {
        type: "black",
        tools: [
          {
            name: "Brush",
          },
          {
            name: "Rectangle",
          },
        ],
      },
    }));

    widget.onChartReady(() => {
      console.log("onChartReady=> ");
    
      widget.chart().createStudy('Volume', false, false, [], null, {
        "Pane": "new"
      });
     // this.updateResolution(this.temp)
      // this.checkTheamChange();
    });

    const Url = window.location.pathname.split("/");
    // if (Url) {
    //   this.setState({ setUrl: Url[3] });
    // }
    var urlsymbol = Url[2] + "" + Url[3];
    let symbolType = urlsymbol;
    let symbol = urlsymbol.toLowerCase();

    let sendCoinData = {
      firstCoin: Url[2],
      secondCoin: Url[3],
    };

    axios
      .post(baseUrl + "/frontapi/checkpair", sendCoinData)
      .then((respData) => {
        var apiResp = respData.data;

        if (apiResp.success === false) {
          //this.props.history.push('/')
        }

        var apiRespData = apiResp.data;

        if (apiRespData.pairId) {
          let sendCoinPair = {
            pair_id: apiRespData.pairId,
          };
          axios
            .post(baseUrl + "/frontapi/checkpairexchange", sendCoinPair)
            .then((respData) => {
              var apiExchangeResp = respData.data;
              if (apiExchangeResp) {
                this.setState({
                  exchange: apiExchangeResp.data.exchange,
                });
              }
            });
        }
      });

    this._connectSocketStreams(this.streams.map((i) => `${symbol}${i}`));

    fetch(
      `https://www.binance.com/api/v1/aggTrades?limit=10&symbol=${urlsymbol}`
    )
      .then((response) => response.json())
      .then((response) => {
        this.props.dispatch({
          type: "SET_TRADES",
          data: response,
        });
        this.setState({
          isLoaded: true,
          loadedTrades: true,
        });
      })
      .catch((error) => {
        this.setState({
          isLoaded: false,
          error: error,
        });
      });

    fetch(`https://www.binance.com/api/v3/ticker/24hr?symbol=${urlsymbol}`)
      .then((response) => response.json())
      .then((response) => {
        //console.log("Responce", response.priceChangePercent);
        // var resp = response.data
        this.setState({ priceChange: response.lastPrice });
        this.setState({ priceChangePercent: response.priceChangePercent });
      })
      .catch();
  }

  onSearch = (event) => {
    this.setState({ searchedValue: event.target.value });
  };
  EmptySearch = () => {
    this.setState({ searchedValue: "" });
  };

  AuthAction = () => {
    var token = localStorage.getItem("token");
    if (token) {
      return <button className="btn w100 btn_man my_color_buy">Buy</button>;
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border2 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">Sign Up</Link> or{" "}
            <Link to="/login-to-buy-sell-crypto">Login</Link>
          </div>
        </div>
      );
    }
  };

  AuthActionSell = () => {
    var token = localStorage.getItem("token");
    if (token) {
      return <button className="btn w100 btn_man my_color_sell">Sell</button>;
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border3 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">Sign Up</Link> or{" "}
            <Link to="/login-to-buy-sell-crypto">Login</Link>
          </div>
        </div>
      );
    }
  };

  linkPairClick = () => {
    const Url = window.location.pathname.split("/");
    // console.log(Url, Url[2], Url[3]);

    let sendCoinData = {
      firstCoin: Url[2],
      secondCoin: Url[3],
    };
    // console.log(sendCoinData);

    axios
      .post(baseUrl + "/frontapi/checkpair", sendCoinData)
      .then((respData) => {
        var apiResp = respData.data;

        if (apiResp.success === false) {
          //this.props.history.push('/')
        }

        var apiRespData = apiResp.data;

        // console.log("apiRespData", apiRespData.pairId);

        if (apiRespData.pairId) {
          let sendCoinPair = {
            pair_id: apiRespData.pairId,
          };
          // console.log(sendCoinPair);
          axios
            .post(baseUrl + "/frontapi/checkpairexchange", sendCoinPair)
            .then((respData) => {
              var apiExchangeResp = respData.data;
              if (apiExchangeResp) {
                this.setState({
                  exchange: apiExchangeResp.data.exchange,
                });
              }
            });
        }
      });
  };

  startDataLoad = () => {
    var userToken = localStorage.getItem("token");
    this.setState({
      firstCoin: this.props.match.params.firstCoin,
      secondCoin: this.props.match.params.secondCoin,
      firstCoinBalance: 0,
      secondCoinBalance: 0,
      userToken: userToken,
      currentPrice: "",
      activeBuyOrder: false,
      userName: "Guest",
      graphDataStatus: false,
      graphData: [],
      options: {
        rangeSelector: {
          selected: 1,
        },

        title: {
          text: "Price",
        },

        series: [
          {
            type: "candlestick",
            // name: 'BTC Price',
            data: [],
            dataGrouping: {
              units: [
                [
                  "day", // unit name
                  [1], // allowed multiples
                ],
                [
                  "week", // unit name
                  [1], // allowed multiples
                ],
                ["month", [1, 2, 3, 4, 6]],
              ],
            },
          },
        ],
      },
    });
    this.getCoinPairList(this.props.match.params.secondCoin);
    this.getPairDetails();
  };

  componentDidUpdate() {
    /* console.log('this.state.firstCoin',this.state.firstCoin);
    console.log('this.props.match.params.firstCoin',this.props.match.params.firstCoin); */
    if (
      this.state.firstCoin !== this.props.match.params.firstCoin ||
      this.state.secondCoin !== this.props.match.params.secondCoin
    ) {
      var getSocket = this.state.socketCollect;
      getSocket.emit("disconnectManual");
      this.startDataLoad();
    }
  }

  getCoinPairList = (v) => {
    this.setState({ secondCoinId: v });
    let sendData = {
      secondCoin: v,
    };
    axios
      .post(baseUrl + "/frontapi/coin_pair_list", sendData)
      .then((respData) => {
        var apiResp = respData.data;
        this.setState({
          coinListStatus: apiResp.status,
          coinListData: apiResp.data,
          //   img: apiResp.data[0].icon,
        });
      });
  };

  getPairDetails = () => {
    let sendData = {
      firstCoin: this.props.match.params.firstCoin,
      secondCoin: this.props.match.params.secondCoin,
    };
    axios.post(baseUrl + "/frontapi/checkpair", sendData).then((respData) => {
      var apiResp = respData.data;
      if (apiResp.success === false) {
        //this.props.history.push('/')
      }
      var apiRespData = apiResp.data;

      this.setState({
        firstCoinId: apiRespData.firstCoinId,
        secondCoinId: apiRespData.secondCoinId,
        currentPrice: apiRespData.currentPairPrice,
        buyPrice: apiRespData.currentPairPrice,
        sellPrice: apiRespData.currentPairPrice,
      });

      this.getUserDetails();
    });
  };

  showHighChartHtml = () => {
    return (
      <div id="tv_chart_container1" style={{ height: 400 + "px" }}></div>
      /*<HighchartsReact
        highcharts={Highcharts}
        options={this.state.options}
        ref={"chartComponent"}
        constructorType={"stockChart"}
      />*/
    );
  };

  getUserDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      var userId = 0;
      var userName = "Guest";
      if (resp.status === true) {
        // this.setState({Whitelist_Status: resp.data[0].whitelist_status })
        var userId = resp.data[0].id;

        this.setState({
          userId: resp.data[0].id,
          userName: resp.data[0].email,
        });
      } else {
        this.setState({ userId: 0, userName: userName });
      }
      this.getUserBalance(
        this.state.firstCoinId,
        this.state.secondCoinId,
        userId
      );
    });
  };

  getGraphData = (getFirstCoinId, getSecondCoinId) => {
    axios
      .post(baseUrl + "/frontapi/graph_data", {
        firstCoinId: getFirstCoinId,
        secondCoinId: getSecondCoinId,
      })
      .then((resp) => {
        var resp = resp.data;
        var jsonData = [];
        if (resp.success === true) {
          for (var i = 0; i < resp.data.length; i++) {
            var singleData = resp.data[0];
            var ddt = [
              parseFloat(singleData.datecol + "000"),
              parseFloat(singleData.open_price),
              parseFloat(singleData.max_price),
              parseFloat(singleData.min_price),
              parseFloat(singleData.close_price),
            ];
            jsonData.push(ddt);
          }
        }
        this.setState({ graphDataStatus: resp.success, graphData: jsonData });
      });
  };

  /* 

getUserBalance = (getFirstCoinId,getSecondCoinId) =>{
  let sendData = {userId:this.state.userId,
          firstCoinId:getFirstCoinId,
          secondCoinId:getSecondCoinId};
  axios.post(baseUrl + '/frontapi/user_balance_by_pair', sendData).then((respData) => {
    var apiResp = respData.data;
    var apiRespData = apiResp.data;
    this.setState({firstCoinBalance:apiRespData.firstCoinBalance,
             secondCoinBalance:apiRespData.secondCoinBalance});
    	
  })
} */

  getUserBalance = (getFirstCoinId, getSecondCoinId, userId) => {
    const socket = socketIOClient(socketUrl, {
      query:
        "userId=" +
        userId +
        "&firstCoinId=" +
        getFirstCoinId +
        "&secondCoinId=" +
        getSecondCoinId,
    });
    // console.log("socket", socket);

    this.setState({ socketCollect: socket });
    var collectThis = this;

    // client-side
    socket.on("connect", () => {
      collectThis.setState({ currentSocketId: socket.id });
      //console.log("socket.id===================",socket.id); // ojIckSD2jqNzOqIrAGzL
    });
    socket.on("socketDataApi", function (apiRespData) {
      	console.log("socket.id===================",socket.id, apiRespData);
      if (socket.id == collectThis.state.currentSocketId) {
        var balanceApi = apiRespData.balanceApi;
        var marketTradeApi = apiRespData.marketTradeApi;
        var activeOrderListApi = apiRespData.activeOrderListApi;
        var activeBuyOrderData = activeOrderListApi.buyOrderData;
        var activeSellOrderData = activeOrderListApi.sellOrderData;
        var myActiveOrderListApi = apiRespData.myActiveOrderListApi;
        var myActiveBuyOrderData = myActiveOrderListApi.buyOrderData;
        var myActiveSellOrderData = myActiveOrderListApi.sellOrderData;
        var myCompletedOrderListApi = apiRespData.myCompletedOrderListApi;
        var myCompletedBuyOrderData = myCompletedOrderListApi.buyOrderData;
        var myCompletedSellOrderData = myCompletedOrderListApi.sellOrderData;
        var coinListApi = apiRespData.coinListApi;
        var topBarApi = apiRespData.topBarApi;
        var graphDataApi = apiRespData.graphDataApi;
        collectThis.setState({
          firstCoinBalance: balanceApi.firstCoinBalance,
          secondCoinBalance: balanceApi.secondCoinBalance,
          activeBuyOrder: activeBuyOrderData.status,
          activeBuyOrderData: activeBuyOrderData.data,
          activeSellOrder: activeSellOrderData.status,
          activeSellOrderData: activeSellOrderData.data,
          marketTrade: marketTradeApi.status,
          marketTradeData: marketTradeApi.data,
          myActiveBuyOrder: myActiveBuyOrderData.status,
          myActiveBuyOrderData: myActiveBuyOrderData.data,
          myActiveSellOrder: myActiveSellOrderData.status,
          myActiveSellOrderData: myActiveSellOrderData.data,
          myCompletedBuyOrder: myCompletedBuyOrderData.status,
          myCompletedBuyOrderData: myCompletedBuyOrderData.data,
          myCompletedSellOrder: myCompletedSellOrderData.status,
          myCompletedSellOrderData: myCompletedSellOrderData.data,
          topBarStatus: topBarApi.status,
          topBarData: topBarApi.data,
          loadingState: false,
        });
        if (collectThis.refs.chartComponent === undefined) {
          return false;
        }

        var jsonData = [];
        for (var i = 0; i < graphDataApi.data.length; i++) {
          var singleData = graphDataApi.data[i];
          var ddt = [
            parseFloat(singleData.datecol + "000"),
            parseFloat(singleData.open_price),
            parseFloat(singleData.max_price),
            parseFloat(singleData.min_price),
            parseFloat(singleData.close_price),
          ];
          collectThis.refs.chartComponent.chart.series[0].addPoint(ddt);
        }
      }
    });
  };

  getCurrentPairPrice = (getFirstCoinId, getSecondCoinId) => {
    axios
      .get(
        baseUrl +
        "/frontapi/pair_current_price/" +
        getFirstCoinId +
        "/" +
        getSecondCoinId
      )
      .then((respData) => {
        var apiResp = respData.data;
        var currentPrice = apiResp.data;
        this.setState({
          currentPrice: currentPrice,
          buyPrice: currentPrice,
          sellPrice: currentPrice,
        });
      });
  };

  handleBuyChange = (event) => {
    var tradeMinlimitErrorMsg = "";
    let { name, value } = event.target;
    var buyOrderTotal = "";
    if (name == "buyPrice" && this.state.buyAmount > 0) {
      buyOrderTotal = parseFloat(value) * parseFloat(this.state.buyAmount);
    } else if (name == "buyAmount" && this.state.buyPrice > 0) {
      buyOrderTotal = parseFloat(value) * parseFloat(this.state.buyPrice);
    }

    this.setState({ [name]: value, buy_order_total: buyOrderTotal });
  };

  handleBuyPercent = (percentAmt) => {
    var userBalance = this.state.secondCoinBalance;
    var percentAmt = (parseFloat(percentAmt) * parseFloat(userBalance)) / 100;
    if (this.state.buyPrice > 0) {
      var buyAmt = percentAmt / this.state.buyPrice;
      this.setState({ buyAmount: buyAmt, buy_order_total: percentAmt });
    } else {
      toast.error("Please Enter Price");
      return false;
    }
  };

  handleSellChange = (event) => {
    let { name, value } = event.target;
    var sellOrderTotal = "";
    if (name == "sellPrice" && this.state.sellAmount > 0) {
      sellOrderTotal = parseFloat(value) * parseFloat(this.state.sellAmount);
    } else if (name == "sellAmount" && this.state.sellPrice > 0) {
      sellOrderTotal = parseFloat(value) * parseFloat(this.state.sellPrice);
    }
    this.setState({ [name]: value, sell_order_total: sellOrderTotal });
  };

  handleSellPercent = (percentAmt) => {
    var userBalance = this.state.firstCoinBalance;
    var percentAmt = (parseFloat(percentAmt) * parseFloat(userBalance)) / 100;
    if (this.state.sellPrice > 0) {
      var sell_order_total = percentAmt * this.state.sellPrice;
      this.setState({
        sellAmount: percentAmt,
        sell_order_total: sell_order_total,
      });
    } else {
      toast.error("Please Enter Price");
      return false;
    }
  };

  handleBuySubmit = (event) => {
    event.preventDefault();

    console.log("eve ====> ");
    if (this.state.buyPrice === undefined || this.state.buyPrice < 0) {
      toast.error("Invalid Price");
      return false;
    }

    if (this.state.buyAmount === undefined || this.state.buyAmount < 0) {
      toast.error("Invalid Amount");
      return false;
    }

    let submitData = {
      orderType: "buy",
      per_price: this.state.buyPrice,
      volume: this.state.buyAmount,
      firstCoinId: this.state.firstCoinId,
      secondCoinId: this.state.secondCoinId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/create_order", submitData, config)
      .then((resp) => {
        var apiResp = resp.data;
        if (apiResp.success === false) {
          toast.error(apiResp.message);
        } else {
          toast.success(apiResp.message);
          this.setState({ buyAmount: "", buy_order_total: "" });
        }
      });
  };

  handleSellSubmit = (event) => {
    event.preventDefault();
    console.log("eve ====> ");
    if (this.state.sellPrice === undefined || this.state.sellPrice < 0) {
      toast.error("Invalid Price");
      return false;
    }

    if (this.state.sellPrice === undefined || this.state.sellPrice < 0) {
      toast.error("Invalid Amount");
      return false;
    }

    let submitData = {
      orderType: "sell",
      per_price: this.state.sellPrice,
      volume: this.state.sellAmount,
      firstCoinId: this.state.firstCoinId,
      secondCoinId: this.state.secondCoinId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/create_order", submitData, config)
      .then((resp) => {
        var apiResp = resp.data;
        if (apiResp.success === false) {
          toast.error(apiResp.message);
        } else {
          toast.success(apiResp.message);
          this.setState({ sellAmount: "", sell_order_total: "" });
        }
      });
  };

  deleteOrder = (orderId, orderType) => {
    //	event.preventDefault();

    if (orderId === undefined || orderId < 0) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType === undefined) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType !== "buy" && orderType !== "sell") {
      toast.error("Invalid Order Type");
      return false;
    }

    let submitData = {
      order_type: orderType,
      order_id: orderId,
    };
    // console.log("sdfsdfsdfsdf", submitData);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/delete_order", submitData, config)
      .then((resp) => {
        var apiResp = resp.data;
        if (apiResp.success === false) {
          toast.error(apiResp.message);
        } else {
          toast.success(apiResp.message);
        }
      });
  };

  buyBoxHtml = () => {
    return (
      <div className="col-sm-6">
        <div className="border1">
          <div className="orderforms-hd">
            <div>
              <label className="ng-binding">Buy {this.state.firstCoin}</label>
              <span className="f-fr ng-binding">
                {this.state.secondCoin} Balance: {this.state.secondCoinBalance}
              </span>
            </div>
          </div>
          <form
            name="buyForm"
            onSubmit={this.handleBuySubmit}
            autoComplete="off"
            className="ng-pristine ng-invalid ng-invalid-required"
          >
            <div className="orderforms-inputs">
              <div className="field f-cb">
                <label className="ng-binding">Price: </label>
                <div className="iptwrap leftBig">
                  <input
                    type="text"
                    value={this.state.buyPrice}
                    onChange={this.handleBuyChange}
                    name="buyPrice"
                    className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                  />

                  <span className="unit ng-binding">
                    {this.state.secondCoin}
                  </span>
                  {/*<span className="jiantou"><i className="fa fa-angle-up"></i><i className="fa fa-angle-down" aria-hidden="true" role="button" tabindex="0"></i></span>*/}
                </div>
                <div className="cls"></div>
              </div>
              <div className="field f-cb">
                <label className="ng-binding">Amount: </label>
                <div className="iptwrap">
                  <input
                    type="text"
                    name="buyAmount"
                    onChange={this.handleBuyChange}
                    value={this.state.buyAmount}
                    id="buyAmount"
                    aria-invalid="true"
                  />
                  <span className="unit ng-binding">
                    {this.state.firstCoin}
                  </span>
                </div>
                <div className="cls"></div>
              </div>
              <div className="field percent f-cb">
                <div className="field4">
                  <span
                    className="col"
                    onClick={() => this.handleBuyPercent(25)}
                  >
                    25%
                  </span>
                  <span
                    className="col"
                    onClick={() => this.handleBuyPercent(50)}
                  >
                    50%
                  </span>
                  <span
                    className="col"
                    onClick={() => this.handleBuyPercent(75)}
                  >
                    75%
                  </span>
                  <span
                    className="col"
                    onClick={() => this.handleBuyPercent(100)}
                  >
                    100%
                  </span>
                </div>
              </div>
              <div className="field f-cb">
                <label className="ng-binding">Total: </label>
                <div className="iptwrap">
                  <input
                    type="text"
                    readOnly="readonly"
                    id="buy_order_total"
                    onChange={this.handleBuyChange}
                    name="buy_order_total"
                    value={this.state.buy_order_total}
                    className="ng-pristine ng-untouched ng-valid ng-empty"
                  />
                  <span className="unit ng-binding">
                    {this.state.secondCoin}
                  </span>
                </div>
                <div className="cls"></div>
              </div>
              {this.AuthAction()}
            </div>
          </form>
        </div>
      </div>
    );
  };

  sellBoxHtml = () => {
    return (
      <div className="col-sm-6">
        <div className="border1">
          <div className="orderforms-hd">
            <div>
              <label className="ng-binding">Sell {this.state.firstCoin}</label>
              <span className="f-fr ng-binding">
                {this.state.firstCoin} Balance: {this.state.firstCoinBalance}{" "}
              </span>
            </div>
          </div>
          <form
            name="buyForm"
            onSubmit={this.handleSellSubmit}
            autoComplete="off"
            className="ng-pristine ng-invalid ng-invalid-required"
          >
            <div className="orderforms-inputs">
              <div className="field f-cb">
                <label className="ng-binding">Price: </label>
                <div className="iptwrap leftBig">
                  <input
                    type="text"
                    value={this.state.sellPrice}
                    name="sellPrice"
                    onChange={this.handleSellChange}
                  />

                  <span className="unit ng-binding">
                    {this.state.secondCoin}
                  </span>
                  <span className="jiantou">
                    <i className="fa fa-angle-up"></i>
                    <i
                      className="fa fa-angle-down"
                      aria-hidden="true"
                      role="button"
                      tabIndex="0"
                    ></i>
                  </span>
                </div>
                <div className="cls"></div>
              </div>
              <div className="field f-cb">
                <label className="ng-binding">Amount: </label>
                <div className="iptwrap">
                  <input
                    type="text"
                    name="sellAmount"
                    id="sellAmount"
                    onChange={this.handleSellChange}
                    value={this.state.sellAmount}
                  />
                  <span className="unit ng-binding">
                    {this.state.firstCoin}
                  </span>
                </div>
                <div className="cls"></div>
              </div>
              <div className="field percent f-cb">
                <div className="field4">
                  <span
                    className="col"
                    onClick={() => this.handleSellPercent(25)}
                  >
                    25%
                  </span>
                  <span
                    className="col"
                    onClick={() => this.handleSellPercent(50)}
                  >
                    50%
                  </span>
                  <span
                    className="col"
                    onClick={() => this.handleSellPercent(75)}
                  >
                    75%
                  </span>
                  <span
                    className="col"
                    onClick={() => this.handleSellPercent(100)}
                  >
                    100%
                  </span>
                </div>
              </div>
              <div className="field f-cb">
                <label className="ng-binding">Total: </label>
                <div className="iptwrap">
                  <input
                    type="text"
                    id="sell_order_total"
                    name="sell_order_total"
                    value={this.state.sell_order_total}
                    readOnly="readonly"
                    onChange={this.handleSellChange}
                    className="ng-pristine ng-untouched ng-valid ng-empty"
                  />
                  <span className="unit ng-binding">
                    {this.state.secondCoin}
                  </span>
                </div>
                <div className="cls"></div>
              </div>
              {this.AuthActionSell()}
            </div>
          </form>
        </div>
      </div>
    );
  };

  activeBuyOrderHtml = () => {
    if (this.state.exchange == "binance") {
      if (this.props.depth.bids) {
        var collectHtml = [];
        for (var i = 0; i < 10; i++) {
          var activeBuyOrderSingle = this.props.depth.bids[i];
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {activeBuyOrderSingle[0]}
              </td>
              <td className="f-left ng-binding">{activeBuyOrderSingle[1]}</td>
              <td className="f-left ng-binding">
                {activeBuyOrderSingle[0] * activeBuyOrderSingle[1]}
              </td>
            </tr>
          );
        }
        return <tbody>{collectHtml}</tbody>;
      }
    } else {
      if (this.state.activeBuyOrder) {
        var collectHtml = [];
        for (var i = 0; i < this.state.activeBuyOrderData.length; i++) {
          var activeBuyOrderSingle = this.state.activeBuyOrderData[i];
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {activeBuyOrderSingle.per_price}
              </td>
              <td className="f-left ng-binding">
                {activeBuyOrderSingle.buy_get_amount}
              </td>
              <td className="f-left ng-binding">
                {activeBuyOrderSingle.per_price *
                  activeBuyOrderSingle.buy_get_amount}
              </td>
            </tr>
          );
        }
        return <tbody>{collectHtml}</tbody>;
      }
    }
  };

  activeSellOrderHtml = () => {
    if (this.state.exchange == "binance") {
      if (this.props.depth.asks) {
        var collectHtml = [];
        for (var i = 0; i < 10; i++) {
          var activeSellOrderSingle = this.props.depth.asks[i];
          //let maxBid = BigNumber.maximum(activeSellOrderSingle.map(bid => bid[0])).toFormat()
          //let minAsk = BigNumber.minimum(activeSellOrderSingle.map(ask => ask[0])).toFormat()
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding magenta">
                {activeSellOrderSingle[0]}
              </td>
              <td className="f-left ng-binding">{activeSellOrderSingle[1]}</td>
              <td className="f-left ng-binding">
                {activeSellOrderSingle[0] * activeSellOrderSingle[1]}
              </td>
            </tr>
          );
        }
        return <tbody>{collectHtml}</tbody>;
      }
    } else {
      if (this.state.activeSellOrder) {
        var collectHtml = [];
        for (var i = 0; i < this.state.activeSellOrderData.length; i++) {
          var activeSellOrderSingle = this.state.activeSellOrderData[i];
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding magenta">
                {activeSellOrderSingle.per_price}
              </td>
              <td className="f-left ng-binding">
                {activeSellOrderSingle.sell_spend_amount}
              </td>
              <td className="f-left ng-binding">
                {activeSellOrderSingle.per_price *
                  activeSellOrderSingle.sell_spend_amount}
              </td>
            </tr>
          );
        }
        return <tbody>{collectHtml}</tbody>;
      }
    }
  };

  myActiveSellOrderHtml = () => {
    if (this.state.myActiveSellOrder) {
      var collectHtml = [];

      this.state.myActiveSellOrderData.forEach((e) => {
        var getTime = e.created_at;
        getTime = getTime.replace("T", " ");
        getTime = getTime.replace(".000Z", "");
        collectHtml.push(
          <tr className="ng-scope">
            <td className="f-left ng-binding green">
              {Moment(getTime).format("lll")}
            </td>
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">{e.per_price}</td>
            <td className="f-left ng-binding">{e.sell_spend_amount}</td>
            <td className="f-left ng-binding">
              {e.per_price * e.sell_spend_amount}
            </td>
            <td className="f-left ng-binding">
              <button
                className="btn btn-danger"
                onClick={() => this.deleteOrder(e.sell_id, "sell")}
              >
                Cancel
              </button>
            </td>
          </tr>
        );
      });

      // var collectHtml = [];
      // for (var i = 0; i < this.state.myActiveSellOrderData.length; i++) {
      //   var myActiveSellOrderSingle = this.state.myActiveSellOrderData[i];
      //   var getTime = myActiveSellOrderSingle.created_at;
      //   getTime = getTime.replace("T", " ");
      //   getTime = getTime.replace(".000Z", "");
      //   collectHtml.push(
      //     <tr className="ng-scope">
      //       <td className="f-left ng-binding green">
      //         {Moment(getTime).format("lll")}
      //       </td>
      //       <td className="f-left ng-binding green">
      //         {myActiveSellOrderSingle.coin_pair}
      //       </td>
      //       <td className="f-left ng-binding magenta">
      //         {myActiveSellOrderSingle.per_price}
      //       </td>
      //       <td className="f-left ng-binding">
      //         {myActiveSellOrderSingle.sell_spend_amount}
      //       </td>
      //       <td className="f-left ng-binding">
      //         {myActiveSellOrderSingle.per_price *
      //           myActiveSellOrderSingle.sell_spend_amount}
      //       </td>
      //       <td className="f-left ng-binding">
      //         <button
      //           className="btn btn-danger"
      //           onClick={() =>
      //             this.deleteOrder(myActiveSellOrderSingle.sell_id, "sell")
      //           }
      //         >
      //           Cancel
      //         </button>
      //       </td>
      //     </tr>
      //   );
      // }
      return <tbody>{collectHtml}</tbody>;
    }
  };

  myActiveBuyOrderHtml = () => {
    if (this.state.myActiveBuyOrder) {
      // console.log()
      var collectHtml = [];

      this.state.myActiveBuyOrderData.forEach((e) => {
        var getTime = e.created_at;
        getTime = getTime.replace("T", " ");
        getTime = getTime.replace(".000Z", "");
        collectHtml.push(
          <tr className="ng-scope">
            <td className="f-left ng-binding green">
              {Moment(getTime).format("lll")}
            </td>
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">{e.per_price}</td>
            <td className="f-left ng-binding">{e.buy_get_amount}</td>

            <td className="f-left ng-binding">
              {e.per_price * e.buy_get_amount}
            </td>
            <td className="f-left ng-binding">
              <button
                className="btn btn-danger"
                onClick={() => this.deleteOrder(e.buy_id, "buy")}
              >
                Cancel
              </button>
            </td>
          </tr>
        );
      });

      /* var collectHtml = [];
      for (var i = 0; i < this.state.myActiveBuyOrderData.length; i++) {
        var myActiveBuyOrderSingle = this.state.myActiveBuyOrderData[i];
        var getTime = myActiveBuyOrderSingle.created_at;
        console.log("myActiveBuyOrderSingle.buy_id",myActiveBuyOrderSingle)
        // console.log("myCompletedBuyOrderSingle",myActiveBuyOrderSingle.buy_get_amount)
        getTime = getTime.replace("T", " ");
        getTime = getTime.replace(".000Z", "");
        collectHtml.push(
          <tr className="ng-scope">
            <td className="f-left ng-binding green">{Moment(getTime).format("lll")}</td>
            <td className="f-left ng-binding green">
              {myActiveBuyOrderSingle.coin_pair}
            </td>
            <td className="f-left ng-binding green">
              {myActiveBuyOrderSingle.per_price}
            </td>
            <td className="f-left ng-binding">
              {myActiveBuyOrderSingle.buy_get_amount}
            </td>
            <td className="f-left ng-binding">
              {myActiveBuyOrderSingle.per_price *
                myActiveBuyOrderSingle.buy_get_amount}
            </td>
            <td className="f-left ng-binding">
              <button
                className="btn btn-danger"
                onClick={() =>
                  this.deleteOrder(myActiveBuyOrderSingle.buy_id, "buy")
                }
              >
                Cancel
              </button>
            </td>
          </tr>
        );
      } */

      return <tbody>{collectHtml}</tbody>;
    }
  };

  myCompletedSellOrderHtml = () => {
    if (this.state.myCompletedSellOrder) {
      var collectHtml = [];
      for (var i = 0; i < 6; i++) {
        var myCompletedSellOrderSingle = this.state.myCompletedSellOrderData[i];
        if (myCompletedSellOrderSingle) {
          var getTime = myCompletedSellOrderSingle.created_at;

          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {Moment(getTime).format("lll")}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.per_price}
              </td>
              <td className="f-left ng-binding">
                {myCompletedSellOrderSingle.total_sell_spend_amount}
              </td>
              {/* {console.log(myCompletedSellOrderSingle,"e.buy_get_amount")} */}
              <td className="f-left ng-binding">
                {myCompletedSellOrderSingle.per_price *
                  myCompletedSellOrderSingle.total_sell_spend_amount}
              </td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    }
  };

  openTradingChart = (symbol) => (
    <TradingViewWidget
      symbol={"BINANCE:" + symbol}
      theme={Themes.LIGHT}
      locale="en"
      autosize
      details={false}
    />
  );

  // myCompletedSellOrderHtml = () => {
  //   if (this.state.myCompletedSellOrder) {
  //     var collectHtml = [];
  //     for (var i = 0; i < this.state.myCompletedSellOrderData.length; i++) {
  //       var myCompletedSellOrderSingle = this.state.myCompletedSellOrderData[i];
  //       var getTime = myCompletedSellOrderSingle.created_at;
  //       getTime = getTime.replace("T", " ");
  //       getTime = getTime.replace(".000Z", "");
  //       collectHtml.push(
  //         <tr className="ng-scope">
  //           <td className="f-left ng-binding green">{getTime}</td>
  //           <td className="f-left ng-binding green">
  //             {myCompletedSellOrderSingle.coin_pair}
  //           </td>
  //           <td className="f-left ng-binding magenta">
  //             {myCompletedSellOrderSingle.per_price}
  //           </td>
  //           <td className="f-left ng-binding">
  //             {myCompletedSellOrderSingle.sell_spend_amount}
  //           </td>
  //           <td className="f-left ng-binding">
  //             {myCompletedSellOrderSingle.per_price *
  //               myCompletedSellOrderSingle.sell_spend_amount}
  //           </td>
  //         </tr>
  //       );
  //     }
  //     return <tbody>{collectHtml}</tbody>;
  //   }
  // };

  myCompletedBuyOrderHtml = () => {
    if (this.state.myCompletedBuyOrder) {
      var collectHtml = [];
      for (var i = 0; i < 6; i++) {
        var myCompletedBuyOrderSingle = this.state.myCompletedBuyOrderData[i];
        if (myCompletedBuyOrderSingle) {
          // console.log("myCompletedBuyOrderSingle",myCompletedBuyOrderSingle)
          var getTime = myCompletedBuyOrderSingle.created_at;
          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {Moment(getTime).format("lll")}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.per_price}
              </td>
              <td className="f-left ng-binding">
                {myCompletedBuyOrderSingle.total_buy_get_amount}
              </td>
              <td className="f-left ng-binding">
                {myCompletedBuyOrderSingle.per_price *
                  myCompletedBuyOrderSingle.total_buy_get_amount}
              </td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    }
  };

  marketTradeHtml = () => {
    if (this.state.marketTrade) {
      var collectHtml = [];
      for (var i = 0; i < this.state.marketTradeData.length; i++) {
        var singleData = this.state.marketTradeData[i];
        var setColor = singleData.extype == "buy" ? "green" : "magenta";
        var getTime = singleData.created_at;
        getTime = getTime.replace("T", " ");
        getTime = getTime.replace(".000Z", "");
        collectHtml.push(
          <tr className="ng-scope">
            <td className={"f-left ng-binding " + setColor}>
              {singleData.get_per_price}
            </td>
            <td className="f-left ng-binding">{singleData.get_amount}</td>
            <td className="f-left ng-binding">
              {Moment.utc(getTime).format("lll")}
            </td>
          </tr>
        );
      }
      return <tbody>{collectHtml}</tbody>;
    }
  };

  coinListHtml = (coinListData) => {
    // console.log("coinListData",coinListData)
    const filteredItems = coinListData.filter((item) =>
      item.first_coin_name.includes(this.state.searchedValue.toUpperCase())
    );
    return filteredItems.map((item, index) => {
      var percentShow =
        ((parseFloat(item.current_price) -
          parseFloat(item.oneday_before_price)) *
          100) /
        item.oneday_before_price;
      percentShow = percentShow.toFixed(2);
      var active_currency = false;
      var showActive =
        this.state.firstCoin === item.first_coin_name &&
        this.state.secondCoin === item.second_coin_name;
      active_currency = "currency_" + item.second_coin_name;
      let checkClass = "ng-scope show_tab " + active_currency;

      if (showActive) {
        checkClass = "active " + "ng-scope " + "show_tab " + active_currency;
      }
      checkClass = "ng-scope " + "show_tab ";

      return (
        // <tr key={index} className={checkClass}>
        <tr key={index} className={showActive ? "ng-scope active" : "ng-scope"}>
          <a
            href={
              "/exchange/" + item.first_coin_name + "/" + item.second_coin_name
            }
          >
            <td className="r-market-pair ng-binding fw600">
              {item.icon ? (
                <img
                  className="currency_icon"
                  src={baseUrl + "/static/currencyImage/" + item.icon}
                />
              ) : (
                <img className="currency_icon" src="/assets/img/btc.png" />
              )}
              {item.first_coin_name}
              <small>/{item.second_coin_name}</small>
            </td>
            <td className="r-market-price ng-binding fw600">
              {item.current_price}
            </td>
            <td className="r-market-rate ng-scope tr">
              <span className="ng-binding ng-scope green">{percentShow}%</span>
            </td>
          </a>
        </tr>
      );
    });
  };

  // coinListHtml = () => {
  // 	if(this.state.coinListStatus) {

  // 		var collectHtml = [] ;
  // 		for(var i=0; i < this.state.coinListData.length;i++) {
  // 			var singleData = this.state.coinListData[i];
  // 			var percentShow = ((parseFloat(singleData.current_price) - parseFloat(singleData.oneday_before_price))*100) / singleData.oneday_before_price;
  // 			percentShow = percentShow.toFixed(2);
  // 			var showActive  = (this.state.firstCoin===singleData.first_coin_name && this.state.secondCoin===singleData.second_coin_name)
  // 			collectHtml.push(
  // 					<tr className="ng-scope">
  // 						<td className="r-market-star">{showActive}</td>
  // 						<td className="r-market-pair ng-binding fw600"><Link to={"/exchange/"+singleData.first_coin_name+"/"+singleData.second_coin_name} >{singleData.first_coin_name}/{singleData.second_coin_name}</Link></td>
  // 						<td className="r-market-price ng-binding fw600">
  // 						{singleData.current_price}
  // 						</td>

  // 						<td className="r-market-rate ng-scope">
  // 							<span className="ng-binding ng-scope green">{percentShow}%</span>
  // 						</td>
  // 					</tr>
  // 			)
  // 		}

  // 		return  <tbody>{collectHtml}</tbody>

  // 	}

  // }

  topBarHtml = () => {
    if (this.state.topBarStatus) {
      var topBarData = this.state.topBarData;
      var collectHtml = (
        <ul>
          <li>
            <h4 className="ng-binding">Last Price</h4>
            <strong className="ng-binding">
              {this.state.secondCoin == "INR" ? (
                <span style={{ color: "black", fontWeight: "500" }}>₹</span>
              ) : (
                <span></span>
              )}
              {topBarData.currentPrice}
            </strong>
            <strong className="transMoney ng-binding ng-scope"></strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-Change</h4>
            {topBarData.changePriceAmount.toFixed(2) == null ? (
              <strong className="changeRate ng-binding ng-scope green">
                {topBarData.changePriceAmount}
              </strong>
            ) : (
              <strong className="changeRate ng-binding ng-scope green">
                {topBarData.changePriceAmount.toFixed(2)}
              </strong>
            )}
            {/* <strong className="changeRate ng-binding ng-scope green">
              {topBarData.changePriceAmount}
            </strong> */}
            {topBarData.changePricePercent == null ||
              topBarData.changePricePercent.toFixed(2) == null ? (
              <strong className="changeRate ng-binding ng-scope green">
                {topBarData.changePricePercent}%
              </strong>
            ) : (
              <strong> ({topBarData.changePricePercent.toFixed(2)}%)</strong>
            )}
          </li>
          <li>
            <h4 className="ng-binding">24h-High</h4>
            <strong className="ng-binding">{topBarData.oneDayMaxPrice}</strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-Low</h4>
            <strong className="ng-binding">{topBarData.oneDayMinPrice}</strong>
          </li>
          <li>
            <h4 className="ng-binding">24h Volume</h4>
            <strong className="ng-binding">{topBarData.oneDayVolume}</strong>
          </li>
        </ul>
      );

      return <ul>{collectHtml}</ul>;
    }
  };
  activeCurrency = (value) => {
    this.getCoinPairList(value);
    /*if (value === "INR") {
      $(".show_tab").show();
    } else {*/
    // $(".show_tab").hide();
    // var showCurrencyClass = "currency_" + value;
    // $("." + showCurrencyClass).show();
    //}
    this.setState({
      setActiveTab: value,
      secondCoin: value,
      setUrl: value,
      secondCoin: this.props.match.params.secondCoin,
    });
  };
  render() {
    let { setUrl } = this.state;
    return (
      <div style={{ backgroundColor: "#333" }}>
        <div className="chart" style={{ backgroundColor: "#333" }}>
          {this.showHighChartHtml()}
        </div>
      </div>
    );
  }
}
export default connect((state) => state)(Exchange);
