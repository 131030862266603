import { React, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LeftTab() {
  const { t } = useTranslation();
   const location = useLocation();
  const [pathname, setPathName] = useState([]);
  useEffect(() => {
   // var path = window.location.pathname;
   // path = path.split("/");
    //setPathName(path, () => {});
  }, []);

  return (
    <div className="left_tab">
      <ul>
        <li>
          <Link
            to="/profile"
            className={location.pathname == "/profile" ? "active" : ""}
          >
            {t("Profile")}
          </Link>
        </li>
        <li>
          <Link to="/Kyc" className={location.pathname == "/Kyc" ? "active" : ""}>
          {t("KYC")} 
          </Link>
        </li>
        <li>
          <Link
            to="/whitelist"
            className={location.pathname == "/whitelist" ? "active" : ""}
          >
            {t("Whitelist")} 
            
          </Link>
        </li>
        {/* <li>
          <Link
            to="/Google_auth"
            className={pathname[1] == "Google_auth" ? "active" : ""}
          >
            Two-Factor Authentication
          </Link>
        </li> 
        <li>
          <Link
            to="/transactions"
            className={
              pathname[1] == "transactions" ||
              pathname[1] == "Transactions" ||
              pathname[1] == "depositeHistory" ||
              pathname[1] == "coinDeposite" ||
              pathname[1] == "coinFundRequest"
                ? "active"
                : ""
            }
          >
            Transactions History
          </Link>
        </li>
        <li>
          <Link
            to="/spot"
            className={
              pathname[1] == "spot" || pathname[1] == "spotSellHistory"
                ? "active"
                : ""
            }
          >
            Trade History
          </Link>
        </li>*/}
        {/* <li>
          <Link to="/refer" className={pathname[1] == "refer" ? "active" : ""}>
            Refer & Earn
          </Link>
        </li> */}
        <li>
          <Link to="/help" className={location.pathname == "/help" ? "active" : ""}>
          {t("Support")} 
            
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default LeftTab;
