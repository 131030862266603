import React from "react";
import MainHeader from "../Common/MainHeader";
import Footer from "../Common/Footer";
import { Component } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const baseUrl = myConstList.baseUrl;
export class GoogleAuthLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      respStatus: "",
      checked: false,
      faType: "none",
      userId: "",
      qrcodeurl: "",
    };

    this.SwitchChange = this.SwitchChange.bind(this);
    this.getUserDetails();
    this.GoogleAuthStatus();
    this.generateGoogleCode();
    var userIdData = {
      userId: localStorage.id,
    };
  }

  componentDidMount() {
    this.generateGoogleCode();
    this.SwitchApi();
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, emptyField: false, errMsg: "" });
  };

  generateGoogleCode = () => {
    const config = {
      headers: {
        lang: localStorage.getItem("lang"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/generateGoogleCode", {}, config)
      .then((resp1) => {
        var resp = resp1.data;

        this.setState({
          qrcodeurl: resp.data.qrCodeUrl,
          SKey: resp.data.secretKey,
        });
      });
  };

  verifyCode = () => {
    const config = {
      headers: {
        lang: localStorage.getItem("lang"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };
    if(!this.state.vcode){
      this.props.history.push("/login");
    }
    let faData = {
      code: this.state.vcode,
    };

    axios
      .post(baseUrl + "/frontapi/verifyGoogleCode", faData, config)
      .then((resp) => {
        toast.error(resp.data.message);
        this.setState({ message: resp.data.message });

        if (resp.data.status == true) {
          this.props.history.push("/exchange/BTC/INR");
        } else {
          this.setState({ CodeErrMsg: resp.data.message });
        }
      });
  };

  getUserDetails = () => {
    const config = {
      headers: {
        lang: localStorage.getItem("lang"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp1) => {
      var resp = resp1.data;
      this.setState({
        authentication_with: resp.data[0].authentication_with,
        TwoFA: resp.data[0].authentication_with,
      });
    });
  };
  GoogleAuthStatus = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/G_auth_status", {}, config)
      .then((resp1) => {
        var resp = resp1.data;
        this.setState({ g_auth_status: resp.Data[0].google_auth_verify });
      });
  };
  SwitchApi() {
    const config = {
      headers: {
        lang: localStorage.getItem("lang"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/enable2faStatus", {}, config)
      .then((resp) => {
        var resp1 = resp.data;
        this.setState({ StateValue: resp1.Data[0].enable_2fa });
        if (resp1.Data[0].enable_2fa === "N") {
          this.setState({ checked: false });
          return false;
        } else {
          this.setState({ checked: true });
        }
      });
  }
  SwitchChange(checked) {
    if (
      this.state.StateValue === "N" &&
      this.state.authentication_with === null
    ) {
      toast.dismiss();
      toast.error("Please Select 2FA Option First.");
      return false;
    }

    this.setState({ checked });
    var ToogleValue = "";

    if (this.state.StateValue === "Y") {
      ToogleValue = "N";
    } else {
      ToogleValue = "Y";
    }

    if (this.state.authentication_with !== null || ToogleValue === "N") {
      const config = {
        headers: {
          lang: localStorage.getItem("lang"),
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let LoginData = {
        enableValue: ToogleValue,
      };

      axios
        .post(baseUrl + "/frontapi/Update2faStatus", LoginData, config)
        .then((resp) => {
          var resp1 = resp.data;

          if (resp1.status === true) {
            return false;
          }
        });
    } else {
      toast.dismiss();
      toast.error("Verify Google Authentication !");
    }
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, errMsg: "" });
  };

  handleOnBlurOldPassword(event) {
    var password = event.target.value;
    if (!password) {
      toast.dismiss();
      toast.error("Old Password required");
    }
  }

  handleOnBlurNewPassword(event) {
    var Npassword = event.target.value;
    if (!Npassword) {
      toast.dismiss();
      toast.error("New Password required");
    }
    if (Npassword.length < 8) {
      toast.dismiss();
      toast.error("Enter Minimum 8 Character");
      return false;
    }
  }

  handleOnBlurCPassword(event) {
    var CPassword = event.target.value;
    if (!CPassword) {
      toast.dismiss();
      toast.error("Re-enter Your New Password");
      return false;
    }
    if (CPassword.length < 8) {
      toast.dismiss();
      toast.error("Enter Minimum 8 Character");
      return false;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const config = {
      headers: {
        lang: localStorage.getItem("lang"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      NewPassword: this.state.NewPassword,
      OldPassword: this.state.OldPassword,
      CPassword: this.state.CPassword,
    };

    axios
      .post(baseUrl + "/frontapi/changePassword", LoginData, config)
      .then((resp) => {
        var response = resp.data;

        this.setState({ NewPassword: "", OldPassword: "", CPassword: "" });
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.messagecu);
          return false;
        } else {
          toast.dismiss();
          toast.error(response.messagecu);
          return false;
        }
      });
  };
  Two_FASubmit = (event) => {
    event.preventDefault();
    const config = {
      headers: {
        lang: localStorage.getItem("lang"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let LoginData = {
      TwoFA: this.state.TwoFA,
    };

    if (
      this.state.TwoFA === undefined ||
      this.state.TwoFA === "" ||
      this.state.TwoFA === null
    ) {
      toast.dismiss();
      toast.error("Please Setup 2FA");
      return false;
    }
    axios.post(baseUrl + "/frontapi/twoFA", LoginData, config).then((resp) => {
      var resp1 = resp.data;

      if (resp1.status === true) {
        this.getUserDetails();
        toast.dismiss();
        toast.success(" Authentication details updated successfully");
        return false;
      }
    });
  };

  LoginActivity = () => {
    const config = {
      headers: {
        lang: localStorage.getItem("lang"),
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };
    if (!config) {
      window.location.href = "/login-to-buy-sell-crypto";
    }

    axios.post(baseUrl + "/frontapi/login-list", {}, config).then((resp) => {
      var respNew = resp.data;
      if (respNew.status === true) {
        this.setState({
          tabledata: respNew.findData,
          respStatus: respNew.status,
        });
      }
    });
  };
  render() {
    return (
      <div className="">
        <MainHeader />

        <div className="box5 p60">
          <div className=" container">
            <div className="row">
              <div className="col-md-3 "></div>
              <div className="col-md-6 ">
                <div className="boxbody login_box">
                  <h4 className="tc mb-4">2FA Verification</h4>
                  <div className="form-group">
                    <label className="fw-500">Enter Auth Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="vcode"
                      placeholder="Enter Code"
                      value={this.state.vcode}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      onClick={this.verifyCode}
                      className="btn btn_man w100px"
                    >
                      Verify
                    </button>
                    <p className="succTag">{this.state.CodeErrMsg} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer limit={1} />
      </div>
    );
  }
}

export default GoogleAuthLogin;
