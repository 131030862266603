import React from "react";
import { Link } from "react-router-dom";
import * as myConstList from "./BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';
import ChangeLang from "./ChangeLang";
import authService from "../../services/auth.service";
import "react-toastify/dist/ReactToastify.css";

const baseUrl = myConstList.baseUrl;
class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      pair: "",
      email: "",
    };

    this.getData();
    this.getOpenPair();
  }

  componentWillMount() {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", true);
    }

    var theme = localStorage.getItem("theme");
    if (theme == null) {
      theme = false;
    }
    if (theme == "true" || theme == "false") {
      theme = JSON.parse(theme);
      this.handleChange(theme);
    }
  }
  getOpenPair = () => {
    axios
      .get(baseUrl + "/frontapi/get-open-pair")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            pair: `${res.data.data[0].first_name}/${res.data.data[0].second_name}`,
          });
        }
      })
      .catch((err) => { });
  };
  getData = () => {
    if (localStorage.getItem("token")) {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
        //console.log(res.data);
        const resp = res.data;
        if (resp.status) {
          this.setState({
            email: resp.data[0].email,
            mobile_no: resp.data[0].mobile_no,
            kycStatus: resp.data[0].kycVerification_status
          });
        }
      });
    }
  };
  showHeaderPart = () => {
    
      var token = localStorage.getItem("token");
      const { t } = this.props;
      if (token === null) {
        return (

          <ul className="">
            {/* <li> <ChangeLang /></li> */}
            <li className="">
              <Link className="btn btn_man" to="/login-to-buy-sell-crypto">
                {t("login")}
              </Link>
            </li>
            <li className="mo_none">
              <Link className="btn btn_man" to="/register-for-bitcoin-exchange">
                {t("sign_up")}
              </Link>
            </li>
          </ul>
        );
      } else {
        return (
          <ul className="ment_right ml-auto ">
            {/* <li>
            <ChangeLang />
          </li> */}
            <li className="">
              <div className="dropdown user_dropdown">
                <span
                  type="button"
                  className=" dropdown-toggle d-flex align-items-center"
                  data-toggle="dropdown"
                >
                  <i className="fa fa-user-circle mr-1"></i>{" "}
                  <span className="name_max_w">{this.state.email ? this.state.email : this.state.mobile_no}</span>
                </span>
                <div className="dropdown-menu2">
                  {/* console.log("nav kycStatus=> ") */}
                  <Link className="a_flex" to="/profile">
                    <i className="fa fa-user "></i> {t("Profile")}
                  </Link>
                  <Link className="a_flex" to="/Kyc">
                    <i className="fa fa-id-card-o"></i> {t("My KYC")} {(this.state.kycStatus && this.state.kycStatus == "Y") ? <i className="fa fa-check ml-3 text-success" aria-hidden="true"></i> : ""}
                  </Link>
                  <Link className="a_flex" to="/help">
                    <i className="fa fa-headphones"></i> {t("Support")}
                  </Link>
                  <Link to="#" className="a_flex" onClick={this.logout}>
                    <i className="fa fa-sign-out"></i>{t("Logout")}
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        );
      }
    
  };
  logout = () => {
    const { t } = this.props;
    authService.logout();
    toast.success(t("Logout Successfully"));
    setTimeout(() => window.location.reload(), 1000)

    //var theme = localStorage.getItem("theme");
    //  localStorage.clear();
    // localStorage.setItem("theme", theme);
    //sessionStorage.clear();
  };
  handleChange = (checked) => {
    let tags = document.getElementsByTagName("body");
    if (checked === true) {
      tags[0].classList.add("dark-theme");
      this.setState({ checked: false });
    } else {
      tags[0].classList.remove("dark-theme");
      this.setState({ checked: true });
    }
    localStorage.setItem("theme", checked);
  };
  render() {
    var token = localStorage.getItem("token");
    const { t } = this.props;
    return (
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand navbar-brand2" to="/">
            <img src="/assets/img/logo.png" alt="Logo" className="logo logo1" />
            <img
              src="/assets/img/logo-light.png"
              alt="Logo"
              className="logo logo2"
            />
          </Link>

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav  line align-items-center">
              <li className="nav-item">
                {!token ? (
                  <Link
                    className="nav-link"
                    to={`/exchange/${this.state.pair}`}
                  >
                    {t("Exchange")}
                  </Link>
                ) : (
                  <Link
                    className="nav-link"
                    to={`/exchange/${this.state.pair}`}
                  >
                    {t("Exchange")}
                  </Link>
                )}
              </li>
              <li className="nav-item">

                <Link
                  className="nav-link"
                  to={`/account`}
                >
                  {t("Account")}
                </Link>

              </li>
              <li className="nav-item">

                <Link
                  className="nav-link"
                  to={`/transactions`}
                >
                  {t("Transactions")}
                </Link>

              </li>
              <li className="nav-item">

                <Link
                  className="nav-link"
                  to={`/trade`}
                >
                  {t("Trade")}
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link" to="/coinExchange" >
                  {t("Sale market")} &#8595;%
                </Link>
              </li>
              {/* <li>
              <a className="nav-link" href={`/listing/${this.state.pair}`}>
                    {t("Listing")}
                  </a>
              </li> */}
              <li><ChangeLang /></li>
              <li className="nav-item">
                {this.state.checked == false ? (
                  <img
                    src="/assets/img/sun.png"
                    alt=""
                    width="25px"
                    className="sun-img theme-img"
                    onClick={() => this.handleChange(this.state.checked)}
                    checked={this.state.checked}
                  />
                ) : (
                  <img
                    src="/assets/img/moon.png"
                    width="25px"
                    alt=""
                    className="moon-img theme-img"
                    onClick={() => this.handleChange(this.state.checked)}
                    checked={this.state.checked}
                  />
                )}
              </li>

            </ul>
            {/* <!--End of Main menu navigation list--> */}
          </div>

          <div className="dashbord_menu">{this.showHeaderPart()}</div>
          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <span className="icon-bar">
              <i className="fa fa-bars fa-2x"></i>
            </span>
          </button>
        </div>
      </nav>
    );
  }
}
export default withTranslation()(Nav);
