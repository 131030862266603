import React,{useEffect} from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { baseUrl, aheader } from '../Common/BaseUrl'
import { ToastContainer, toast } from "react-toastify";
import Nav from '../Common/Nav';
import Footer from '../Common/Footer';
function ApproveVerification() {
    const {id}=useParams()
   
    useEffect(() => {
        rejectAmount()
    }, [id])
    const rejectAmount=()=>{
        const data={
            id:id
        }
        axios.post(baseUrl+"/api/coinRefundOnApproveByUser",data, aheader).then((res)=>{
            console.log("res",res)
             if(res.data.success){
                toast.success(res.data.message)
             }
             else{
                toast.error(res.data.message)
             }
        }).catch()
    }
    
  return (
    <div className="">
     <Nav/>
	  <div className="new_inpage ">
      <div className="container">
    <div className="vr_img">
			<img src="assets/img/check.gif" alt="check" className="m-auto" />
		</div>
   </div>
    </div>

    <ToastContainer/>
    <Footer/>
  </div>
    
  )
}

export default ApproveVerification
