import * as axios from 'axios';
import { toast } from "react-toastify";
import { addFavCoinApi, cancelBackapi, checkFavCoinApi, checkPairapi, checkPairExchangeApi, cryptoDataByTab, deleteOrderApi, generatePayIdApi, getOpenPairApi, getOrderApi } from "../Common/api";
import { baseUrl, aheader, aheader_form } from "../Common/BaseUrl";
import authService from "../../services/auth.service";
import * as dataUrl from "./DatUrl";
import * as crud from "./OpsServies";

const CoinPairList = (id) => {
    let coinPairResult = crud.post(baseUrl + "/frontapi" + dataUrl.base.coinPairList, id)
    return coinPairResult
}

const UserData = (token) => {
    axios.post(baseUrl + "/frontapi" + dataUrl.base.userData, {}, aheader).then((response) => {
       // console.log("respost ", response);
        if (response.data.status) {
            return response;
        }

        else {
            if (!response.data.status && response.data.key == "logout") {
                toast.error(response.data.message);
                authService.logout();
                setTimeout(() => window.location = "/")
            }
        }

    }).catch((e) => {
        console.log("respost error", e);
        // toast.error(e.response.data.message);
        if (e.response.status == 401) {
            toast.error(e.response.message);
            setTimeout(() => {
                //  authService.logout();
                window.location = "/";
            }, 1000);
            // history.push("/");
        }

    })

}

const checkPair = async (sendData) => {
    //console.log("ycheckPair==> ", checkPairapi, sendData);
    let data = await crud.post(checkPairapi, sendData)
   // console.log("checkPair==> ", data);
    return data
}

const checkPairExchange = (sendData) => {
    let data = crud.post(checkPairExchangeApi, sendData)
    return data
}

const checkFavCoin = (sendData, token) => {
    let data = crud.post(checkFavCoinApi, sendData, token)
    return data
}

const orderCreate = (endPoint, sendData, token) => {
    let data = crud.post(baseUrl + endPoint, sendData, token)
    return data
}

const getOrder = (sendData, token) => {
    let data = crud.post(getOrderApi, sendData, token)
    return data
}

const deleteOrderFun = (sendData, token) => {
    let data = crud.post(deleteOrderApi, sendData, token)
    return data
}
const cancleBack = (sendData, token) => {
    let data = crud.post(cancelBackapi, sendData, token)
    return data
}

const getOpenPair = () => {
    let data = crud.get(getOpenPairApi)
    return data
}

const getCryptoDataByTab = () => {
    let data = crud.get(cryptoDataByTab)
    return data
}

const addFavCoin = (sendData, token) => {
    let data = crud.post(addFavCoinApi, sendData, token)
    return data
}

const generatePayId = (sendData, token) => {
    let data = crud.post(generatePayIdApi, sendData, token)
    return data
}

export { CoinPairList, UserData, checkPair, checkPairExchange, checkFavCoin, orderCreate, getOrder, deleteOrderFun, cancleBack, getOpenPair, getCryptoDataByTab, addFavCoin, generatePayId }