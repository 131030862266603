import React from "react";
import LoginHeader from "../Common/LoginHeader";
import { Link } from "react-router-dom";
import { Component } from "react";
import DocumentMeta from "react-document-meta";
import { baseUrl, socketUrl, imgUrl, aheader, aheader_form } from "../Common/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { ProductName } from "../Common/ProductName";
import { withTranslation } from 'react-i18next';

import QRCode from "react-qr-code";
//const baseUrl = myConstList.baseUrl;
//const imgUrl = myConstList.imgUrl;

export class account extends Component {
  constructor(props) {
    super(props);
    var maxNumber = 999999;
    var rand = Math.floor(Math.random() * maxNumber + 1);
    this.state = {
      copySuccess: false,
      isDepositModelOpen: false,
      isWithdrawalModelOpen: false,
      disabledInrDeposit: false,
      Withdraw_address: "",
      isTransferModelOpen: false,
      isINRDepositModelOpen: false,
      isPaymentModelOpen: "none",
      depositeInrBtn: "block",
      disableTransferButton: false,
      disabledInrWithdrawal: false,
      disabledCoinWithdrawal: false,
      walletBalanceAmount: 0,
      balanceOfAmount: 0,
      balanceOfAmountSpot: 0,
      spotBalance: 0,
      confirmInrBtn: "none",
      random: rand,
      getAmount: "",
      usdtPortfolio: 0,
      inrPortfolio: 0,
      withdraw_amount: "",
      return_url: "" + localStorage.getItem("id") + "-web",
      getCoinNameINr: false,
      getCoinName_withdrawl: false,
      isGoing: false,
      isKycModalOpen: false,
      kycStatus: "",
      adminbankdetails: [],
      estimatedCoin: "INR",
      networkName: "",
      type: "percentage",
      screenWidth: window.innerWidth,
      toWallet: "transactions",
      fromWallet: "wallet",
      withdrawalDisableModalShow: true,
      afxInterval: false,
      refreshDisable: true
    };
    // this.afxInterval = null;
    this.dotInterval = null;
    this.dogeInterval = null;
    this.xrpInterval = null;
    this.adaInterval = null;
    this.vetInterval = null;
    this.bttcInterval = null;
    this.usdtInterval = null;
    this.maticInterval = null;
    this.bicoInterval = null;
    this.shibInterval = null;
    this.axsInterval = null;
    this.manaInterval = null;
    this.chrInterval = null;
    this.spellInterval = null;
    this.linkInterval = null;
    this.trxInterval = null;
    this.ethInterval = null;
    this.bnbInterval = null;
    this.ctc7Interval = null;
    this.ctctmInterval = null;
    this.trxInterval = null;

    this.GetUserData();
    if (localStorage.getItem("token")) {
      this.portfolioData();
    }
    this.adminbankinfo();

  }

  closeOpenINRDepositModal = () => {
    this.setState({
      isINRDepositModelOpen: false,
      confirmInrBtn: "none",
      depositeInrBtn: "block",
      inr_amount: "",
      transactionIdError: "",
      transactionImageError: "",
      inrDepositeError: "",
    });
  };

  adminbankinfo = () => {
    axios.get(baseUrl + "/frontapi/adminbankinfo").then((res) => {
      const resp = res.data;
      this.setState({ adminbankdetails: resp.data[0] });
    });
  };

  portfolioData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/walletTotalBalance", {}, aheader)
      .then((res) => {
        const resp = res.data;
        this.setState({
          usdtPortfolio: resp.data[0].USDT,
          inrPortfolio: resp.data[0].INR,
        });
      });
  };

  closeKycModal = () => {
    this.setState({ isKycModalOpen: false });
  };
  closeWhitelistModal = () => {
    this.setState({ isWhitelistModalOpen: false });
  };

  inr_amountWithdrawl = (event) => {
    var W_to = event.target.value;
    if (!W_to) {
      toast.dismiss();
      this.setState({ amountErr: "Amount filed is required" });
      return false;
    }
    if (W_to <= 0) {
      toast.dismiss();
      this.setState({ amountErr: "Please enter valid amount" });
    } else {
      this.setState({ amountErr: "" });
    }
  };

  inrWithdrawalSubmit = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    this.setState({ disabledInrWithdrawal: true });
    setTimeout(() => this.setState({ disabledInrWithdrawal: false }), 1000);
    if (!this.state.inr_amountWithdrawl) {
      toast.dismiss();
      this.setState({ amountErr: "Enter amount" });
      return false;
    }
    if (this.state.inr_amountWithdrawl <= 0) {
      toast.dismiss();
      this.setState({ amountErr: "Please enter valid amount" });
      return false;
    }
    const dataSubmit = {
      amount: this.state.inr_amountWithdrawl,
      withdrawFees: this.state.fees,
      type: this.state.type,
    };
    if (this.state.inr_amountWithdrawl < this.state.min) {
      toast.dismiss();
      toast.error(`You can't withdrawal less than ${this.state.min}`);
      return false;
    }
    if (this.state.inr_amountWithdrawl > this.state.max) {
      toast.dismiss();
      toast.error(`You can't withdrawal more than ${this.state.max}`);
      return false;
    }

    axios
      .post(baseUrl + "/frontapi/inr_Withdrawal", dataSubmit, aheader)
      .then((res) => {
        const resp = res.data;
        this.setState({
          inr_amountWithdrawl: "",
          getAmount: "",
          percentValue: "",
          isWithdrawalModelOpen: false,
          getCoinName_withdrawl: false,
        });
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
        } else {
          this.WalletAllCoinBalance();
          this.setState({ isWithdrawalModelOpen: false });
          toast.dismiss();
          toast.success(resp.message);
        }
      });
  };

  OpenDepositModel = (getCoinName, show_wallet_address, chainType) => {
    const { t } = this.props;
    if (this.state.kycStatus === "N") {
      toast.error(t("Please complete your kyc first"));
      return false;
    }
    //this.generateQR(show_wallet_address)
    this.setState({
      isDepositModelOpen: true,
      wallet_address: show_wallet_address,
      networkName: getCoinName,
      chainType,
    });
  };

  closeOpenDepositModal = () => {
    this.setState({ isDepositModelOpen: false, copySuccess: "" });
  };

  OpenWithdrawalModel = (

    Currency_name,
    wallet_currency_amount,
    currency_id,
    fees,
    min,
    max,
    type,
    chainType
  ) => {
    console.log("OpenWithdrawalModel", this.state.kycStatus);
    const { t } = this.props;

    if (this.state.kycStatus != "Y") {
      console.log("OpenWithdrawalModel");
      toast.error(t("Please complete your kyc first"));
      return false;
    }
    console.log("min", min, max);
    this.setState({
      //   isDepositModelOpen: false,
      //   isWithdrawalModelOpen: true,
      //   networkName: Currency_name,
      //   BtnDisable3: true,
      //   chainType,
      // // });

      // // this.setState({
      //   Currency_name: Currency_name,
      //   wallet_currency_amount: wallet_currency_amount,
      //   currency_id: currency_id,
      //   fees: fees,
      //   min: min,
      //   max: max,
      //   chainType,
    });
    let LoginData = {
      coin_id: currency_id,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/whitelistDataById", LoginData, aheader)
      .then((res) => {
        const resp = res.data;
        if (res.data.status) {
          this.setState({
            whitelist_Data: resp.data,
            whiteListAddressStatus: resp.status,
            isDepositModelOpen: false,
            isWithdrawalModelOpen: true,
            networkName: Currency_name,
            BtnDisable3: true,
            chainType,
            // });

            // this.setState({
            Currency_name: Currency_name,
            wallet_currency_amount: (wallet_currency_amount > 0) ? wallet_currency_amount : 0,
            currency_id: currency_id,
            fees: fees,
            min: min,
            max: max,
            chainType,
          });
        } else {
          toast.error(res.data.message);
        }
      }).catch((e) => {
        console.log(e)
      })


  };

  closeWithdrawalModal = () => {
    this.setState({
      isWithdrawalModelOpen: false,
      getCoinName_withdrawl: false,
      percentValue: "",
      inr_amountWithdrawl: "",
      getAmount: "",
      withdraw_amount: "",
      Withdraw_address: "",
      amountErr: "",
      walletErr: "",
    });
  };

  OpenTransferModel = (
    transferCoinName,
    transferCoinId,
    spotBalance,
    walletBalance,
    available_amount,
    unavailable_amount
  ) => {
    console.log(transferCoinName, transferCoinId, spotBalance, walletBalance, available_amount, unavailable_amount
    );
    this.setState({
      isTransferModelOpen: true,
      transferCoinName: transferCoinName,
      transferCoinId: transferCoinId,
      walletBalanceAmount: (walletBalance) ? walletBalance : 0,
      spotBalance: (spotBalance) ? spotBalance : 0,
      balanceOfAmount: (walletBalance) ? walletBalance : 0,
      balanceOfAmountSpot: (spotBalance) ? spotBalance : 0
    });
  };

  closeTransferModal = () => {
    this.setState({
      isTransferModelOpen: false,
      balanceOfAmountSpot: 0,
      balanceOfAmount: 0,
      fromWallet: "wallet",
      toWallet: "transactions",
      amount: "",
    });
  };

  componentDidMount = () => {
    if (localStorage.getItem("token")) {

      if (localStorage.getItem("refreshButton")) {
        console.log(localStorage.getItem("refreshButton"))
        let timeStr = parseInt(Date.now() / 1000);
        let rebutton = parseInt(localStorage.getItem("refreshButton"));
        console.log(timeStr, " = >> ", rebutton)
        if (timeStr > rebutton) {
          console.log(" if = >> ")
          localStorage.removeItem("refreshButton");
          this.setState({ refreshDisable: false })
        } else {
          this.setState({ refreshDisable: true });
          rebutton = rebutton - timeStr
          console.log(" else = >>", rebutton)
          setTimeout(() => {
            console.log(" timeout = >>")
            toast.success("time over");
            this.setState({ refreshDisable: false });
            localStorage.removeItem("refreshButton");
          }, rebutton * 1000)
        }
      } else {
        this.setState({ refreshDisable: false });
        localStorage.removeItem("refreshButton");
      }

      // this.timerId2();

      // this.timerId4();
      // this.timerId5();
      // this.timerId6();

      // this.timerId9();
      // this.timerId10();
      // this.timerId11();
      // this.timerId12();
      // this.timerId13();
      // this.timerId14();
      // this.timerId15();
      // this.timerId3();
      this.WalletAllCoinBalance();
      // this.timerId();
      // this.timerId18();
      // this.timerId7();
      // this.timerId8();
      // this.timerId16();
      // this.timerId17();
      // this.timerId19();
      // this.timerId20();
      // this.timerIdSol()
      // this.timerIdBNBToken()

      this.timer_wallet_deposit()
    }
    //  this.timerIdTrx();

  };

  exchangeValue = () => {
    console.log(this.state)
    let toWallet = this.state.toWallet;
    let fromWallet = this.state.fromWallet;
    this.setState({
      fromWallet: toWallet,
      toWallet: fromWallet,
      balanceOfAmount: this.state.balanceOfAmountSpot,
      balanceOfAmountSpot: this.state.balanceOfAmount,
      coinAmount: "",
      CoinAmount1: "",
    });
  };

  copyCodeToClipboard = () => {
    const el = this.Copy;
    el.select();
    document.execCommand("copy");
    this.setState({ copySuccess: true });
  };

  GetCryptoData = () => {
    axios.get(baseUrl + "/frontapi/cryptocoin_list", {}).then((res) => {
      const resp = res.data;
      this.setState({
        Status1: resp.status,
        tabledata: resp.data,
        name: resp.name,
        short_name: resp.short_name,
        wallet: resp.wallet,
        spot: resp.spot,
        reserved: resp.reserved,
      });
    });
  };
  WalletAllCoinBalance = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
      .then((res) => {
        const resp = res.data;
        this.setState({ Status: resp.status, tableData1: resp.data });
      });
  };
  GetUserData = () => {
    if (localStorage.getItem("token")) {
      const config = {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      };
      axios.post(baseUrl + "/frontapi/UserData", {}, aheader).then((res) => {
        const resp = res.data;
        this.setState({
          kycStatus: resp.data[0].kycVerification_status,
          Bonous_status: resp.data[0].Bonous_status,
        });
      });
    }
  };

  liveUsdtPrice = (coin, value) => {
    // return new Promise((resolve, reject) => {
    if (coin) {
      let url = "https://api.binance.com/api/v3/ticker/price?symbol=" + coin + "USDT";
      axios.get(url).then((res) => {
        const resp = res.data;
        console.log(resp.price);
        this.setState({ addressInput: resp.price * value });
        //resolve(resp.price)
      });
    } else {
      // resolve(0)
    }
    //  })
  }

  liveDbiCtc7CtcTmPrice = (coin, value) => {
    // return new Promise((resolve, reject) => {
    if (coin) {
      let url = baseUrl + "/v1/frontapi/get_coin_price";
      //coin_second_id : 7 (USDT)
      axios.post(url, { coin_first_id: coin, coin_second_id: 7 }, aheader).then((res) => {
        const resp = res.data;
        //   console.log("resp ", resp);
        if (resp.data.length) {
          // console.log(resp.data[0].current_price, " ", resp.data[0].current_price * value);
          this.setState({ addressInput: resp.data[0].current_price * value });
        }
        //resolve(resp.price)
      });
    } else {
      // resolve(0)
    }
    //  })
  }

  withdrawSubmit = (event) => {
    const { t } = this.props;
    event.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    console.log(this.state);

    const dataSubmit = {
      Wamount: this.state.withdraw_amount,
      coinList: this.state.currency_id,
      walletAddress: this.state.Withdraw_address,
      withdrawFees: this.state.fees,
      type: this.state.type,
    };
    if (!this.state.withdraw_amount) {
      toast.dismiss();
      this.setState({ amountErr: "Amount filed is required" });
      return false;
    }
    if (!this.state.Withdraw_address) {
      this.setState({ walletErr: "Wallet address is required" });
      return false;
    } else {
      this.setState({ walletErr: "" });
    }

    if (this.state.withdraw_amount <= 0) {
      // toast.dismiss();
      this.setState({ amountErr: "Please enter valid amount" });
      return false;
    }

    if (this.state.wallet_currency_amount < this.state.withdraw_amount) {
      //  toast.dismiss();
      toast.error(t("Insufficient Balance!"));
      return false;
    }
    if (this.state.withdraw_amount < this.state.min) {
      // toast.dismiss();
      toast.error(t(`You can't withdrawal less than`)`${this.state.min}`);
      return false;
    }

    if (this.state.withdraw_amount > this.state.max) {
      //toast.dismiss();
      toast.error(t(`You can't withdrawal more than`)`${this.state.max}`);
      return false;
    }
    this.setState({ disabledCoinWithdrawal: true });
    setTimeout(() => this.setState({ disabledCoinWithdrawal: false }), 1000);
    console.log("dataSubmit", dataSubmit);
    axios
      .post(baseUrl + "/frontapi/Wallet_Balance", dataSubmit, aheader)
      .then((res) => {
        var resp = res.data;
        console.log(res.data);
        this.setState({
          withdraw_amount: "",
          Withdraw_address: "",
          percentValue: "",
          getAmount: "",
          isWithdrawalModelOpen: false,
        });
        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
          console.log("call");
          setTimeout(() => {
            // window.location.reload();
          }, 3000);
        } else {
          this.WalletAllCoinBalance();
          this.setState({ percentValue: "" });
          toast.dismiss();
          toast.success(resp.message);
        }
      });
  };

  calculateCheckSum = (event) => {
    event.preventDefault();
    this.setState({ disabledInrDeposit: true });
    setTimeout(() => this.setState({ disabledInrDeposit: false }), 1000);
    if (
      this.state.transactionImageError ||
      this.state.inrDepositeError ||
      this.state.transactionIdError
    ) {
      return false;
    }
    if (
      !this.state.IdentityPic3 &&
      !this.state.trNumber &&
      !this.state.inr_amount
    ) {
      this.setState({
        transactionImageError: "Transaction image is required",
        inrDepositeError: "Deposit amount field is required",
        transactionIdError: "Transaction id is required",
      });
      return false;
    }
    if (!this.state.inr_amount) {
      this.setState({
        inrDepositeError: "Deposit amount field is required",
      });
      return false;
    }
    if (!this.state.IdentityPic3) {
      this.setState({
        transactionImageError: "Transaction image is required",
      });
      return false;
    }
    if (!this.state.trNumber) {
      this.setState({
        transactionIdError: "Transaction id is required",
      });
      return false;
    }

    if (this.state.inr_amount <= 0) {
      this.setState({
        inrDepositeError: "Please enter valid deposit amount",
      });
      return false;
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    var formData = new FormData();
    formData.append("tx_image", this.state.IdentityPic3);
    formData.append("utrId", this.state.trNumber);
    formData.append("amount", this.state.inr_amount);

    axios
      .post(baseUrl + "/frontapi/inr_request", formData, aheader_form)
      .then((res) => {
        const resp = res.data;
        if (resp.status === true) {
          toast.success(resp.message);
          this.setState({
            trNumber: "",
            inr_amount: "",
            IdentityPic3: "",
            isINRDepositModelOpen: false,
          });
        }
        if (resp.status === false) {
          toast.error(resp.message);
          this.setState({
            trNumber: "",
            inr_amount: "",
            IdentityPic3: "",
            isINRDepositModelOpen: false,
          });
        }
      });
  };

  whiteListAddressbyID = (currency_id) => {
    let LoginData = {
      coin_id: currency_id,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/whitelistDataById", LoginData, aheader)
      .then((res) => {
        const resp = res.data;
        if (!resp.status) {
          this.setState(
            { otpWithdrawalModel: false, isWhitelistModalOpen: true },
            () => {
              this.setState({
                isWithdrawalModelOpen:
                  this.state.isWhitelistModalOpen === true ? false : true,
              });
            }
          );
          return false;
        }
        this.setState({
          isWithdrawalModelOpen: true,
          whitelist_Data: resp.data,
          whiteListAddressStatus: resp.status,
        });
      });
  };

  redirect = () => {
    window.location = "/whitelist";
  };

  AddressListHtml = () => {
    const { t } = this.props;
    if (this.state.whiteListAddressStatus === true) {
      const html = [];
      html.push(<option value="">{t("Select")}</option>);
      this.state.whitelist_Data.map(function (value, i) {
        html.push(
          <option value={value.whitelistAddress}>
            {value.whitelistAddress}
          </option>
        );
      });

      return (
        <select
          className="form-control"
          name="Withdraw_address"
          value={this.state.Withdraw_address}
          onChange={this.handleChange1}
        // onBlur={this.onBlurWithdraw_address}
        >
          {html}
        </select>
      );
    } else {
      return (
        <option
          className="form-control add-wallet-address"
          name="Withdraw_address"
          value="Please add wallet address from Whitelist"
          onClick={() => {
            window.location.href = "/whitelist";
          }}
        >
          Please add wallet address
        </option>
      );
    }
  };

  coinListHtml = () => {
    if (this.state.Status1 === true) {
      const html = [];
      this.state.tabledata.map(function (value, i) {
        html.push(<option value={value.id}>{value.short_name}</option>);
      });

      return (
        <select
          className="form-control"
          name="coinList"
          value={this.state.coinList}
          onChange={this.handleChange1}
          onBlur={this.onBlurCoinList}
        >
          {html}
        </select>
      );
    }
  };

  showTableHtml = () => {
    const { t } = this.props;
    if (this.state.Status === true) {
      const html = [];
      const classThis = this;
      // console.log("tableData1", this.state.tableData1);
      this.state.tableData1.map(function (value, i) {
        //  console.log("val => ", value);
        let availableAmount = (value.getValue.main[0].unavailable_amount != null) ? value.getValue.main[0].coin_amount - value.getValue.main[0].unavailable_amount : 0;
        if (value.getValue.main[0].coin_amount === null) {
          var getID = value.coin_id;
          var walletAmount = 0;
        } else {
          walletAmount = value.getValue.main[0].coin_amount;
          getID = value.coin_id;
        }
        if (value.getValue.spot[0].coin_amount === null) {
          getID = value.coin_id;
          var spotAmount = 0;
        } else {
          spotAmount = value.getValue.spot[0].coin_amount;
          getID = value.coin_id;
        }
        var myLink = value.coin_tron;
        var coinName = value.name;
        let chainType = value.chainType;
        var fees = 0;
        var min = 0;
        var max = 0;
        if (value.fees == undefined || value.fees.length == 0) {
          fees = 0;
          min = 0;
        } else {
          fees = value.fees[0].commission;
          max = value.fees[0].max_withdrawal;
          min = value.fees[0].min_withdrawal;
        }
        // console.log("fees", fees);
        var getWalletAddress = value.getValue.WalletAddress;

        if (classThis.state.isGoing === false) {
          html.push(
            <tr>
              <td className="tl">
                {value.coin_icon ? (
                  <img
                    src={imgUrl + "/static/currencyImage/" + value.coin_icon}
                    alt="coin_icon"
                    className="currency_icon"
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt="coin_icon"
                  />
                )}

                <a target="_blank" rel="noreferrer" href={myLink}>
                  {value.name}
                </a>
                <small className="ml-1">
                  {value.getValue.main[0].short_name}
                </small>
              </td>

              <td>{walletAmount}</td>
              <td>{(availableAmount > 0) ? (availableAmount % 1) ? availableAmount.toFixed(5) : availableAmount : 0}</td>
              <td>{spotAmount}</td>

              <td>
                <div className="four_btn">
                  <button
                    className="btn btn_man fs14 mr-3"
                    onClick={() =>
                      classThis.OpenDepositModel(
                        coinName,
                        getWalletAddress,
                        chainType
                      )
                    }
                    disabled={value.idDeposit == 1 ? false : true}
                  >
                    {t("Deposit")}
                  </button>

                  {!fees ? (
                    <span>
                      <button
                        className="btn btn_man fs14 mr-3"
                        onClick={() =>
                          classThis.OpenWithdrawalModel(
                            value.name,
                            value.getValue.main[0].coin_amount,
                            getID,
                            "0",
                            min,
                            max,
                            value.changeType
                          )
                        }
                        disabled={value.isWithdrawal == 1 ? false : true}
                      >
                        {t("Withdrawal")}
                      </button>
                    </span>
                  ) : (
                    <span>
                      <button
                        className="btn btn_man fs14 mr-3"
                        onClick={() =>
                          classThis.OpenWithdrawalModel(
                            value.name,
                            value.getValue.main[0].available_amount,
                            getID,
                            fees,
                            undefined,
                            undefined,
                            undefined,
                            value.chainType
                          )
                        }
                        disabled={value.isWithdrawal == 1 ? false : true}
                      >
                        {t("Withdrawal")}
                      </button>
                    </span>
                  )}
                  <button
                    className="btn btn_man fs14 mr-3"
                    onClick={() =>
                      classThis.OpenTransferModel(
                        value.name,
                        getID,
                        value.getValue.spot[0].coin_amount,
                        value.getValue.main[0].coin_amount,
                        value.getValue.main[0].available_amount,
                        value.getValue.main[0].unavailable_amount
                      )
                    }
                  >
                    {t("Transfer")}
                  </button>
                </div>
              </td>
            </tr>
          );
        }
        if (
          classThis.state.isGoing === true &&
          ((value.getValue.main[0].coin_amount !== null &&
            value.getValue.main[0].coin_amount != 0) ||
            (value.getValue.spot[0].coin_amount !== null &&
              value.getValue.spot[0].coin_amount != 0))
        ) {
          html.push(
            <tr>
              <td className="tl">
                {value.coin_icon ? (
                  <img
                    src={imgUrl + "/static/currencyImage/" + value.coin_icon}
                    alt="coin_icon"
                    className="currency_icon"
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt="coin_icon"
                  />
                )}
                <a target="_blank" rel="noreferrer" href={myLink}>
                  {value.name}
                </a>
                <small className="ml-1">
                  {value.getValue.main[0].short_name}
                </small>
              </td>
              <td>{walletAmount}</td>
              <td>{spotAmount}</td>

              <td>
                <div className="four_btn">
                  <button
                    className="btn btn_man fs14 mr-3"
                    onClick={() =>
                      classThis.OpenDepositModel(coinName, getWalletAddress)
                    }
                    disabled={value.idDeposit == 1 ? false : true}
                  >
                    Deposit
                  </button>
                  {!fees ? (
                    <span>
                      <button
                        className="btn btn_man fs14 mr-3"
                        onClick={() =>
                          classThis.OpenWithdrawalModel(
                            value.name,
                            value.getValue.main[0].coin_amount,
                            getID,
                            fees,
                            min,
                            max
                          )
                        }
                        disabled={value.isWithdrawal == 1 ? false : true}
                      >
                        Withdrawal
                      </button>
                    </span>
                  ) : (
                    <span>
                      <button
                        className="btn btn_man fs14 mr-3"
                        onClick={() =>
                          classThis.OpenWithdrawalModel(
                            value.name,
                            value.getValue.main[0].coin_amount,
                            getID,
                            fees,
                            min,
                            max
                          )
                        }
                        disabled={value.isWithdrawal == 1 ? false : true}
                      >
                        Withdrawal
                      </button>
                    </span>
                  )}
                  <button
                    className="btn btn_man fs14 mr-3"
                    onClick={() =>
                      classThis.OpenTransferModel(
                        value.name,
                        getID,
                        value.getValue.spot[0].coin_amount,
                        value.getValue.main[0].coin_amount
                      )
                    }
                  >
                    Transfer
                  </button>
                </div>
              </td>
            </tr>
          );
        }
      });
      return <tbody className="main">{html}</tbody>;
    }
  };

  showTableHtml_M = () => {
    const { t } = this.props;
    if (this.state.Status === true) {
      const html = [];
      const classThis = this;
      console.log("tableData1", this.state.tableData1);
      this.state.tableData1.map(function (value, i) {
        var walletAmount = 0;
        var availableAmount = (value.getValue.main[0].available_amount != null) ? value.getValue.main[0].available_amount : 0;;
        if (value.getValue.main[0].coin_amount !== null) {
          walletAmount = value.getValue.main[0].coin_amount;
        }
        var spotAmount = 0;
        if (value.getValue.spot[0].coin_amount !== null) {
          spotAmount = value.getValue.spot[0].coin_amount;
        }
        var myLink = value.coin_tron;
        var coinName = value.name;
        let chainType = value.chainType;
        var getWalletAddress = value.getValue.WalletAddress;

        if (value.getValue.main[0].coin_amount === null) {
          var getID = value.coin_id;
          var walletAmount = 0;
        } else {
          walletAmount = value.getValue.main[0].coin_amount;
          getID = value.coin_id;
        }
        if (value.getValue.spot[0].coin_amount === null) {
          getID = value.coin_id;
          var spotAmount = 0;
        } else {
          spotAmount = value.getValue.spot[0].coin_amount;
          getID = value.coin_id;
        }
        var fees = 0;
        var min = 0;
        var max = 0;

        if (value.fees && value.fees.length) {

          fees = value.fees[0].commission;
          max = value.fees[0].max_withdrawal;
          min = value.fees[0].min_withdrawal;
        }

        html.push(
          <div className="account_box">
            <div className="d-flex align-items-center">
              <img src={imgUrl + "/static/currencyImage/" + value.coin_icon} alt="coin_icon" className="currency_icon" />
              <a target="_blank" rel="noreferrer">{value.name}</a>
              <span className="aero_btn ml-auto collapsed" data-toggle="collapse" data-target={"#caaount" + i}><i className="fa fa-angle-down"></i></span>
            </div>
            <small className="d-flex mb-2 mt-1 text-center">
              <span className="mr-auto ml-1">Main wallet <br />{walletAmount}</span>
              <span className="mr-auto ml-1">main wallet available <br />{availableAmount}</span>
              <span className="ml-1">Spot wallet<br />{spotAmount}</span></small>
            <div id={"caaount" + i} className="collapse">
              <div className="d-flex justify-content-between">
                <button className="btn btn_man fs14" onClick={() =>
                  classThis.OpenDepositModel(coinName, getWalletAddress)
                }
                  disabled={value.idDeposit == 1 ? false : true}>Deposit</button>
                <button className="btn btn_man fs14" onClick={() =>
                  classThis.OpenWithdrawalModel(
                    value.name,
                    value.getValue.main[0].coin_amount,
                    getID,
                    fees,
                    min,
                    max
                  )
                }
                  disabled={value.isWithdrawal == 1 ? false : true}   >Withdrawal</button>
                <button className="btn btn_man fs14" onClick={() =>
                  classThis.OpenTransferModel(
                    value.name,
                    getID,
                    value.getValue.spot[0].coin_amount,
                    value.getValue.main[0].coin_amount
                  )
                }>Transfer</button>
              </div>
            </div>
          </div>)

      });
      return <div>{html}</div>;
    }
  };

  handleChange = async (event) => {
    let { name, value } = event.target;
    console.log("livePrice ===> ", name, " = ", value, " = ", this.state);
    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });

    if (value > 0) {
      this.setState({ amountErr: "" });
    }

    if (name === "fromWallet") {
      this.setState({ [name]: value, errMsg: "" });
      if (event.target.value === "wallet") {
        this.setState({
          balanceOfAmount: this.state.walletBalanceAmount,
          balanceOfAmountSpot: this.state.spotBalance,
          toWallet: "transactions",
        });
      }
      if (event.target.value === "transactions") {
        this.setState({
          balanceOfAmount: this.state.spotBalance,
          balanceOfAmountSpot: this.state.walletBalanceAmount,
          toWallet: "wallet",
        });
      }
    }
    if (name === "toWallet") {
      this.setState({ [name]: value, errMsg: "" });
      if (event.target.value === "wallet") {
        this.setState({
          balanceOfAmountSpot: this.state.walletBalanceAmount,
          balanceOfAmount: this.state.spotBalance,
          fromWallet: "transactions",
        });
      }
      if (event.target.value === "transactions") {
        this.setState({
          balanceOfAmountSpot: this.state.spotBalance,
          balanceOfAmount: this.state.walletBalanceAmount,
          fromWallet: "wallet",
        });
      }
    }
    if (name === "withdraw_amount" || name === "inr_amountWithdrawl") {
      // console.log(value, " valuetype", this.state);
      let livePrice = 0;
      let fValue = value;
      if (this.state.currency_id != 7 && this.state.currency_id != 50 && this.state.currency_id != 46 && this.state.currency_id != 47 && this.state.currency_id != 48 && this.state.currency_id != 51) {
        this.liveUsdtPrice(this.state.Currency_name, value);
        // console.log("livePrice ===> ", livePrice * value);
        //fValue = livePrice * fValue;
        //this.setState({ addressInput: fValue});
      } else if (this.state.currency_id == 46 || this.state.currency_id == 47 || this.state.currency_id == 48) {
        this.liveDbiCtc7CtcTmPrice(this.state.currency_id, value);

      } else {
        this.setState({ addressInput: value });
      }
      var number = Number(value);

      if (number === Math.floor(number)) {
        if (value.length < 9) {
          this.setState({ [name]: value, errMsg: "" });
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          this.setState({ [name]: value, errMsg: "" });
        }
      }

      if (event.target.value < 0) {
        var withdrawalError = "Please enter valid amount";
        this.setState({ amountErr: withdrawalError });
        return false;
      }
      var calculatedValue = 0;
      if (this.state.type === "flat") {
        calculatedValue =
          parseFloat(event.target.value) - parseFloat(this.state.fees);
      }
      //console.log(event.target.value);
      if (this.state.type === "percentage") {
        let calculated = 0;
        if (event.target.value && event.target.value > 0) {
          var percentValue = this.decemalConvert(
            parseFloat(event.target.value) *
            (100 / (100 + parseFloat(this.state.fees)))
          );

          calculated = parseFloat(event.target.value - percentValue).toFixed(
            4
          );
        }
        //console.log(calculated);
        this.setState({ percentValue: calculated });
        calculatedValue = parseFloat(percentValue);

      }
      this.setState({ getAmount: calculatedValue });
    }
    if (name === "amount") {
      number = Number(value);

      if (number === Math.floor(number)) {
        if (value.length < 9) {
          this.setState({ [name]: value, errMsg: "" });
        }
        // this.setState({ [name]: value, errMsg: "" });
      } else {
        if (value.toString().split(".")[1].length < 9) {
          this.setState({ [name]: value, errMsg: "" });
        }
      }
    }
  };

  handleInrChange = (event) => {
    let { name, value } = event.target;

    if (name === "inr_amount") {
      if (!value) {
        this.setState({
          inrDepositeError: "Deposit amount field is required",
        });
      } else {
        this.setState({
          inrDepositeError: "",
        });
      }
    }
    if (name === "trNumber") {
      if (!value) {
        this.setState({
          transactionIdError: "Transaction id is required",
        });
      } else {
        this.setState({
          transactionIdError: "",
        });
      }
    }
    this.setState({
      [name]: value,
      errMsg: "",
      depositeInrBtn: "block",
      confirmInrBtn: "none",
    });
  };

  handleChange1 = (event) => {
    let { name, value } = event.target;
    console.log("handlechange1", event.target.value);
    if (!value) {
      this.setState({ walletErr: "Wallet address is required" });
    } else {
      this.setState({ walletErr: "" });
    }
    this.setState({ [name]: value, errMsg: "" });
  };

  WalletBalance = (event) => {
    let LoginData = {
      fromWallet: this.state.fromWallet,
      coinList: this.state.coinList,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/deduction_from_wallet", LoginData, config)
      .then((res) => {
        const resp = res.data;

        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
        } else {
          toast.dismiss();
          toast.error(resp.message);
        }
      });
  };

  DepositBalance = (event) => {
    let LoginData = {
      txid: this.state.wallet_address,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/depositBtc", LoginData, config)
      .then((res) => {
        const resp = res.data;

        if (resp.status === false) {
          toast.dismiss();
          toast.error(resp.message);
        } else {
          toast.dismiss();
          toast.error(resp.message);
        }
      });
  };

  onBlurFrom(event) {
    var W_from = event.target.value;
    if (!W_from) {
      toast.dismiss();
      toast.error("Select Wallet Type");
    }
  }
  onBlurTo(event) {
    var W_to = event.target.value;
    if (!W_to) {
      toast.dismiss();
      toast.error("Select Second Wallet Type");
    }
  }

  kycModel = () => {
    return this.state.isKycModalOpen === true ? (
      <div
        id="account-deposit"
        className="collapse"
        aria-labelledby="faqhead1"
        data-parent="#faq"
      >
        <div className="card-body">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
          terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
          skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
          Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
          single-origin coffee nulla assumenda shoreditch et. Nihil anim
          keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
          sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
          occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
          you probably haven't heard of them accusamus labore sustainable VHS.
        </div>
      </div>
    ) : (
      ""
    );
  };

  onBlurCoinList(event) {
    var W_to = event.target.value;
    if (!W_to) {
      toast.dismiss();
      toast.error("Select Coin Type");
    }
  }

  onBlurWithdraw_amount = (event) => {
    var W_to = event.target.value;
    if (!W_to) {
      this.setState({ amountErr: "Amount filed is required" });
      return false;
    } else {
      this.setState({ amountErr: "" });
    }
    if (W_to <= 0) {
      toast.dismiss();
      this.setState({ amountErr: "Please enter valid amount" });
    } else {
      this.setState({ amountErr: "" });
    }
  };

  handleCheckChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  onBlurWithdraw_address(event) {
    var W_to = event.target.value;
    if (!W_to) {
      toast.dismiss();
      toast.error("Select wallet address");
    }
  }
  handleTransfer = (event) => {
    console.log("click", this.state);
    event.preventDefault();
    const { t } = this.props;
    if (!this.state.fromWallet || !this.state.toWallet) {
      toast.error(t("Please select transfer wallet type"));
      return false;
    }
    if (!this.state.amount) {
      toast.error(t("Amount field is required"));
      return false;
    }
    const config = {
      headers: {
        lang: localStorage.getItem("lang"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      fromWallet: this.state.fromWallet,
      toWallet: this.state.toWallet,
      amount: this.state.amount,
      amountType: this.state.transferCoinId, //coin id
    };
    this.setState({ disableTransferButton: true });
    //setTimeout(() => this.setState({ disableTransferButton: false }), 1000);

    axios
      .post(baseUrl + "/frontapi/walletTransfer", LoginData, config)
      .then((res) => {
        this.setState({ disableTransferButton: false })
        const resp = res.data;
        toast.dismiss();
        if (resp.status === true) {
          this.setState({
            amount: "",
            // balanceOfAmountSpot: 0,
            //  balanceOfAmount: 0,
            fromWallet: "wallet",
            toWallet: "transactions",
          });
          this.WalletAllCoinBalance();
          this.portfolioData();
          //  toast.dismiss();
          toast.success(resp.message);
          this.setState({ isTransferModelOpen: false });
          return false;
        } else {

          toast.error(resp.message);
          // return false;
        }
      }).catch((e) => {
        console.log("Error: ", e);
        this.setState({ disableTransferButton: false })
      })
  };

  changeInrValue = (value) => {
    if (value) {
      this.setState({ inr_amount: value, inrDepositeError: "" });
    }
  };
  onChangeImage3 = (e) => {
    e.preventDefault();
    var transactionImageError = "";
    var IdentityPic3 = e.target.files[0];
    this.setState({ IdentityPic3: e.target.files[0] });
    this.setState({ userUploadImage3: URL.createObjectURL(IdentityPic3) });
    if (IdentityPic3 === false) {
      this.setState({ userUploadImage3: false });
      transactionImageError = "Please select valid image jpg|jpeg|png|gif.";
      this.setState({
        transactionImageError,
      });
      return false;
    }
    if (!IdentityPic3.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      this.setState({ userUploadImage3: false });
      transactionImageError = "Please select valid image jpg|jpeg|png|gif.";
      this.setState({
        transactionImageError,
      });
      return false;
    }
    this.setState({
      transactionImageError,
    });
  };
  autoAmount = (e) => {
    console.log("this.state.balanceOfAmount", this.state.balanceOfAmount);
    if (this.state.balanceOfAmount) {
      this.setState({ ["amount"]: this.state.balanceOfAmount, errMsg: "" });
    } else {
      this.setState({ ["amount"]: "", errMsg: "" });
    }
  };
  decemalConvert = (value) => {
    if (value > 0) {
      var number = Number(value);
      if (number === Math.floor(number)) {
        return value;
      } else {
        if (value.toString().split(".")[1].length < 6) {
          return value;
        } else {
          return parseFloat(value).toFixed(5);
        }
      }
    }
  };

  timerId = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-dbi", {}, config).then((res) => {
      var resp = res.data;
      //  console.log("Resp", resp.status);
      if (!this.afxInterval) {
        this.afxInterval = setInterval(() => {
          this.timerId();
        }, 600000);
      }
    });
  };

  timer_wallet_deposit = () => {
    // toast.success("wallet deposit");
    const config = {
      headers: {
        devicetype: 1,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/account_wallet_deposit", {}, config).then((res) => {
      var resp = res.data;
      //  console.log("Resp", resp);
      if (!this.state.afxInterval) {
        this.setState({ afxInterval: true })
        this.WalletAllCoinBalance();
        let afxInterval = setInterval(() => {
          this.timer_wallet_deposit();
        }, 240000);

        // this.setState({ afxInterval: afxInterval })
      }
    })

  };

  timerId1 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-dot", {}, config).then((res) => {
      var resp = res.data;
      // console.log("Resp", resp.status);
      if (!this.dotInterval) {
        this.dotInterval = setInterval(() => {
          this.timerId1();
        }, 600000);
      }
    });
  };

  timerId2 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-doge", {}, config).then((res) => {
      var resp = res.data;
      // console.log("Resp", resp.status);
      if (!this.dogeInterval) {
        this.dogeInterval = setInterval(() => {
          this.timerId2();
        }, 600000);
      }
    });
  };

  timerId3 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-xrp", {}, config).then((res) => {
      var resp = res.data;
      //  console.log("Resp", resp.status);
      if (!this.xrpInterval) {
        this.xrpInterval = setInterval(() => {
          this.timerId3();
        }, 600000);
      }
    });
  };

  timerId4 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-ada", {}, config).then((res) => {
      var resp = res.data;
      //  console.log("Resp", resp.status);
      if (!this.adaInterval) {
        this.adaInterval = setInterval(() => {
          this.timerId4();
        }, 600000);
      }
    });
  };

  timerId5 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-vet", {}, config).then((res) => {
      var resp = res.data;
      // console.log("Resp", resp.status);
      if (!this.vetInterval) {
        this.vetInterval = setInterval(() => {
          this.timerId5();
        }, 600000);
      }
    });
  };

  timerId6 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-bttc", {}, config).then((res) => {
      var resp = res.data;
      // console.log("Resp", resp.status);
      if (!this.this.bttcInterval) {
        this.this.bttcInterval = setInterval(() => {
          this.timerId6();
        }, 600000);
      }
    });
  };

  timerId7 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-usdt", {}, config).then((res) => {
      var resp = res.data;
      //  console.log("Resp", resp.status);
      if (!this.usdtInterval) {
        this.usdtInterval = setInterval(() => {
          this.timerId7();
        }, 600000);
      }
    });
  };

  timerId8 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-matic", {}, config).then((res) => {
      var resp = res.data;
      console.log("Resp", resp.status);
      if (!this.maticInterval) {
        this.maticInterval = setInterval(() => {
          this.timerId8();
        }, 600000);
      }
    });
  };

  timerId9 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-bico", {}, config).then((res) => {
      var resp = res.data;
      console.log("Resp", resp.status);
      if (!this.this.bicoInterval) {
        this.this.bicoInterval = setInterval(() => {
          this.timerId9();
        }, 600000);
      }
    });
  };

  timerId10 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-shib", {}, config).then((res) => {
      var resp = res.data;
      console.log("Resp", resp.status);
      if (!this.this.shibInterval) {
        this.this.shibInterval = setInterval(() => {
          this.timerId10();
        }, 600000);
      }
    });
  };

  timerId11 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-axs", {}, config).then((res) => {
      var resp = res.data;
      console.log("Resp", resp.status);
      if (!this.this.axsInterval) {
        this.this.axsInterval = setInterval(() => {
          this.timerId11();
        }, 600000);
      }
    });
  };

  timerId12 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-mana", {}, config).then((res) => {
      var resp = res.data;
      console.log("Resp", resp.status);
      if (!this.this.manaInterval) {
        this.this.manaInterval = setInterval(() => {
          this.timerId12();
        }, 600000);
      }
    });
  };

  timerId13 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-chr", {}, config).then((res) => {
      var resp = res.data;
      console.log("Resp", resp.status);
      if (!this.this.chrInterval) {
        this.this.chrInterval = setInterval(() => {
          this.timerId13();
        }, 600000);
      }
    });
  };

  timerId14 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-spell", {}, config).then((res) => {
      var resp = res.data;
      console.log("Resp", resp.status);
      if (!this.this.spellInterval) {
        this.this.spellInterval = setInterval(() => {
          this.timerId14();
        }, 600000);
      }
    });
  };

  timerId15 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-link", {}, config).then((res) => {
      var resp = res.data;
      console.log("Resp", resp.status);
      if (!this.this.linkInterval) {
        this.this.linkInterval = setInterval(() => {
          this.timerId15();
        }, 600000);
      }
    });
  };

  timerId16 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit_trx", {}, config).then((res) => {
      var resp = res.data;
      // console.log("Resp", resp.status);
      if (!this.trxInterval) {
        this.trxInterval = setInterval(() => {
          this.timerId16();
        }, 600000);
      }
    });
  };

  timerId17 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-eth", {}, config).then((res) => {
      var resp = res.data;
      //console.log("Resp", resp.status);
      if (!this.ethInterval) {
        this.ethInterval = setInterval(() => {
          this.timerId17();
        }, 600000);
      }
    });
  };

  timerId18 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-bnb", {}, config).then((res) => {
      var resp = res.data;
      //   console.log("Resp", resp.status);
      if (!this.bnbInterval) {
        this.bnbInterval = setInterval(() => {
          this.timerId18();
        }, 600000);
      }
    });
  };

  timerIdBNBToken = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-bnb-token", {}, config).then((res) => {
      var resp = res.data;
      //   console.log("Resp", resp.status);
      if (!this.bnbInterval) {
        this.bnbInterval = setInterval(() => {
          this.timerIdBNBToken();
        }, 600000);
      }
    });
  };

  timerId19 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-ctc7", {}, config).then((res) => {
      var resp = res.data;
      // console.log("Resp", resp.status);
      if (!this.ctc7Interval) {
        this.ctc7Interval = setInterval(() => {
          this.timerId19();
        }, 600000);
      }
    });
  };

  timerId20 = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-ctctm", {}, config).then((res) => {
      var resp = res.data;
      //  console.log("Resp", resp.status);
      if (!this.ctctmInterval) {
        this.ctctmInterval = setInterval(() => {
          this.timerId20();
        }, 600000);
      }
    });
  };

  timerIdSol = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-sol", {}, config).then((res) => {
      var resp = res.data;
      console.log("Resp", resp.status);
      if (!this.spellInterval) {
        this.spellInterval = setInterval(() => {
          this.timerIdSol();
        }, 600000);
      }
    });
  };

  timerIdTrx = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/deposit-trx", {}, config).then((res) => {
      var resp = res.data;
      //  console.log("Resp", resp.status);
      if (!this.trxInterval) {
        this.trxInterval = setInterval(() => {
          this.timerIdTrx();
        }, 600000);
      }
    });
  };
  generateQR = (show_wallet_address) => {

    console.log("copy", show_wallet_address);
    // await QRCode.toDataURL(this.state.wallet_address)
    // console.log(await QRCode.toDataURL(show_wallet_address))
    // let baseImg =  await QRCode.toDataURL(show_wallet_address)
    return <img src="" alt="Base64 Example" />;


  }

  handleRefreshClick = () => {

    this.setState({ afxInterval: true, refreshDisable: true });
    if (!this.state.refreshDisable) {
      let timeStr = parseInt(Date.now() / 1000);
      console.log("Refresh button clicked! ", timeStr);
      // Perform your refresh logic here

      timeStr = timeStr + 120;
      localStorage.setItem("refreshButton", timeStr.toString());
      this.timer_wallet_deposit();
      setTimeout(() => {
        this.setState({ afxInterval: false, refreshDisable: false });
        //localStorage.removeItem("refreshButton");
      }, 120000)
    } else {
      setTimeout(() => {
        this.setState({ afxInterval: false });
        //localStorage.removeItem("refreshButton");
      }, 4000)
    }

    //alert("Refreshing...");
  };

  render() {
    const { t } = this.props;
    const meta = {
      title: `Crypto Wallet Balance | ${ProductName} Exchange`,
      description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    const BtcAddressQr =
      "http://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=" +
      this.state.wallet_address;
    const BTCencoded = encodeURI(BtcAddressQr);
    let { getCoinName_withdrawl } = this.state;
    return (
      <DocumentMeta {...meta}>
        <div className="">
          <LoginHeader />

          {/* Deposit Modal Start */}
          <Modal
            show={this.state.isDepositModelOpen}
            onHide={this.closeOpenDepositModal}
            className="account-popup-outer"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Deposit")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="mt-0 note">
                {t("Please ensure to select")} {t(this.state.chainType)} {t("network at sender's wallet")}.
              </p>

              <div className="">
                <div className="">
                  <label>{t("Your wallet address")}:</label>
                  <div className="link-address position-relative">
                    <div className="form-group  ">
                      <input
                        className="form-control fs12"
                        type="textarea"
                        ref={(textarea) => (this.Copy = textarea)}
                        readonly=""
                        name="wallet_address"
                        value={this.state.wallet_address}
                      />
                    </div>
                    <div className="copy-btn-outer ">
                      <button
                        type="button"
                        className="btn btn_man position-absolute copy_button"
                        onClick={() => this.copyCodeToClipboard()}
                      >
                        <i className="fa fa-clone" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  {(this.state.wallet_address) ?
                    <div className="qr_box">

                      <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={this.state.wallet_address}
                        viewBox={`0 0 256 256`}
                      />

                    </div>
                    : ""}
                  {this.state.copySuccess ? (
                    <div style={{ color: "green" }}>{t("Address Copied")}!</div>
                  ) : null}
                </div>
                <div className="col-sm-3">
                  <div className="tc">
                    <img src={BTCencoded} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="disclaimer-main border-top w-100">
                  <div className="disclaimer-outer p-3">
                    <h6>
                      <i
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                      ></i>{" "}
                      {t("Disclaimer")}
                    </h6>
                    <ul>
                      <li>
                        {t("Send only using the")} {t(this.state.chainType)} {t("network")}.
                        {t("Using any other network will result in loss of funds")}.
                      </li>
                      <li>
                        {t("Deposit only")} {t(this.state.networkName)} {t("to this deposit address")}
                        . {t("Depositing any other asset will result in a loss of funds")}.
                      </li>
                      {this.state.networkName === "USDT" && (
                        <li style={{ color: "green" }}>
                          {t("Note")}:- {t("Payment accept only in BEP20")}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* Deposit Modal End */}

          {/* Withdrawal Modal Start */}
          <Modal
            show={this.state.isWithdrawalModelOpen}
            onHide={this.closeWithdrawalModal}
            className="account-popup-outer"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Withdrawal")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="wallet-balance form-group row">
                <div className="col-md-6 mb-3">
                  <span className="mb-0 form-control d-flex">
                    {" "}
                    {t("Wallet Balance")}:{" "}
                    <b className="ml-auto">
                      {this.state.wallet_currency_amount}{" "}
                      {this.state.Currency_name}{" "}
                    </b>
                  </span>
                </div>

                <div className="col-md-6">
                  <span className="form-control  d-flex">
                    {t("Withdrawal Fees")}:{" "}
                    <b className="ml-auto">
                      {this.state.fees
                        ? Number.isInteger(this.state.fees)
                          ? this.state.fees
                          : parseFloat(this.state.fees)
                        : 0}{" "}
                      {this.state.type === "flat" ? "(Flat)" : "(%)"}{" "}
                    </b>
                  </span>
                </div>
              </div>
              {getCoinName_withdrawl ? (
                <div className="form-group">
                  <label className="mb-0">{t("Amount")}</label>
                  <div className="relative">
                    <input
                      type="number"
                      className="form-control pr-5"
                      name="inr_amountWithdrawl"
                      value={this.state.inr_amountWithdrawl}
                      onChange={this.handleChange}
                      onBlur={this.inr_amountWithdrawl}
                    />
                    <span className="absolute_btc">
                      {" "}
                      {this.state.Currency_name}
                    </span>
                    <div className="show">
                      <span style={{ color: "red" }}>
                        {this.state.amountErr && this.state.amountErr}
                      </span>
                      {this.state.inr_amountWithdrawl > 0 &&
                        this.state.getAmount
                        ? t("You will get") + ": " +
                        parseFloat(this.state.getAmount.toFixed(3))
                        : ""}
                    </div>
                    <div className="show">
                      {console.log(
                        "this.state.percentValue",
                        this.state.percentValue
                      )}
                      {this.state.percentValue
                        ? t("Fees Amount") + ": " + parseFloat(this.state.percentValue)
                        : ""}
                    </div>
                  </div>
                  <div className="tc mt-3">
                    <button
                      className="btn btn_man fs14"
                      onClick={this.inrWithdrawalSubmit}
                      disabled={this.state.disabledInrWithdrawal}
                      value="Send Request"
                    >
                      {t("Send Request")}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="form-group ">
                    <div className="relative">
                      <div className="form-group ">

                        {(this.state.withdraw_amount) ? (
                          <> <label className="mb-0">{t("Address")}</label>
                            {this.state.addressInput > 750 ? (
                              <div className="relative">{this.AddressListHtml()}</div>
                            ) : (
                              <input type="text" className="form-control" name="Withdraw_address"
                                value={this.state.Withdraw_address}
                                onChange={this.handleChange1} />
                            )}

                          </>
                        ) : ""

                        }
                      </div>
                      <span style={{ color: "red" }}>
                        {this.state.walletErr && t(this.state.walletErr)}
                      </span>
                    </div>
                  </div>
                  <div className="form-group ">
                    <label className="mb-0">{t("Amount")}</label>

                    <div className="relative">
                      <input
                        type="number"
                        // value="0"
                        className="form-control pr-5"
                        name="withdraw_amount"
                        value={this.state.withdraw_amount}
                        onChange={this.handleChange}
                        onBlur={this.onBlurWithdraw_amount}
                        placeholder={t("Enter Amount")}
                      />

                      <span className="absolute_btc p-2">
                        {" "}
                        {this.state.Currency_name}
                      </span>
                      <div className="show">
                        <span style={{ color: "red" }}>
                          {this.state.amountErr && t(this.state.amountErr)}
                        </span>
                        {this.state.withdraw_amount > 0 && this.state.getAmount
                          ? t("You will get") + ": " +
                          parseFloat(this.state.getAmount.toFixed(4))
                          : ""}
                      </div>
                      <div className="show">

                        {this.state.percentValue
                          ? t("Fees Amount") + ": " +
                          parseFloat(this.state.percentValue)
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="mb-0">{t("Coin")}</label>
                    <div className="form-control ">
                      {this.state.Currency_name}
                    </div>{" "}
                  </div>



                  <div className="tc">
                    <button
                      className="btn btn_man fs14"
                      onClick={this.withdrawSubmit}
                      disabled={this.state.disabledCoinWithdrawal}
                    >
                      {t("Withdraw Amount")}
                    </button>
                  </div>
                  <div className="disclaimer-main border-top w-100 pl-0 pr-0 mt-3">
                    <div className="disclaimer-outer">
                      <h6>
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        {t("Disclaimer")}
                      </h6>
                      <ul>
                        <li>
                          {t("Send only using the")} {this.state.chainType} {t("network")}.
                          {t("Using any other network will result in loss of funds")}.
                        </li>
                        <li>
                          {t("Deposit only")} {this.state.networkName} {t("to this deposit address")}
                          . {t("Depositing any other asset will result in a loss of funds")}.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
          {/* Withdrawal Modal End */}

          {/* Transfer Modal Start */}
          <Modal
            show={this.state.isTransferModelOpen}
            onHide={this.closeTransferModal}
            className="account-popup-outer"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title> {t("Transfer")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row align-items-center mb-3">
                <div className="form-group col mb-0">
                  <label className="mb-0"> </label>
                  <span>{t("From")} : {this.state.balanceOfAmount}</span>

                  {this.state.toWallet === "wallet" ? (
                    <div className="form-control">Spot</div>
                    // <select
                    //   className="form-control"
                    //   name="fromWallet"
                    //   value={this.state.fromWallet}
                    //   onChange={this.handleChange}
                    // >
                    /* <option value="">{t("Select")}</option> */
                    //   <option value="transactions">{t("Spot")}</option>
                    // </select>
                  ) : this.state.toWallet === "transactions" ? (
                    <div className="form-control">Wallet</div>
                    // <select
                    //   className="form-control"
                    //   name="fromWallet"
                    //   value={this.state.fromWallet}
                    //   onChange={this.handleChange}
                    // >
                    /* <option value="">{t("Select")}</option> */
                    // <option value="wallet">{t("Wallet")}</option>
                    //</select>
                  ) : (
                    <div className="form-control">Wallet</div>
                    // <select
                    //   className="form-control"
                    //   name="fromWallet"
                    //   value={this.state.fromWallet}
                    //   onChange={this.handleChange}
                    // >
                    /* <option value="">{t("Select")}</option> */
                    // <option value="wallet">{t("Wallet")}</option>
                    /* <option value="transactions">{t("Spot")}</option> */

                    // </select>
                  )}

                  <small>{this.state.coinAmount}</small>
                </div>
                <div className="form-group col directions_35_OP pr-0 pl-0 mb-0">
                  <span onClick={this.exchangeValue}>
                    <i className="fa fa-exchange"></i>
                  </span>
                </div>
                <div className="form-group col mb-0">
                  <label className="mb-0"></label>
                  <span>{t("To")} : {this.state.balanceOfAmountSpot}</span>
                  {this.state.fromWallet === "wallet" ? (
                    <>
                      <div className="form-control">Spot</div>
                      {/* <select
                      className="form-control"
                      name="toWallet"
                      value={this.state.toWallet}
                      onChange={this.handleChange}
                    >
                      {/* <option value="">{t("Select")}</option> 
                      <option value="transactions">{t("Spot")}</option>
                    </select> */}
                    </>
                  ) : this.state.fromWallet === "transactions" ? (
                    <>
                      <div className="form-control">Wallet</div>
                      {/* <select
                      className="form-control"
                      name="toWallet"
                      value={this.state.toWallet}
                      onChange={this.handleChange}
                    > */}
                      {/* <option value="">{t("Select")}</option> */}
                      {/* <option value="wallet">{t("Wallet")}</option> */}
                      {/* </select> */}
                    </>
                  ) : (
                    <div className="form-control">Spot</div>
                    // <select
                    //   className="form-control"
                    //   name="toWallet"
                    //   value={this.state.toWallet}
                    //   onChange={this.handleChange}
                    // >
                    //   {/* <option value="">{t("Select")}</option> */}
                    //   <option value="transactions">{t("Spot")}</option>
                    //   {/* <option value="wallet">{t("Wallet")}</option> */}
                    // </select>
                  )}
                  <small>
                    {this.state.CoinAmount1} {this.state.coinName1}
                  </small>
                </div>
              </div>
              <div className="form-group">
                <label className="mb-0">{t("Coin")}</label>
                <div className="form-control">
                  {this.state.transferCoinName}
                </div>{" "}
              </div>
              <div className="form-group">
                <label className="mb-0">{t("Amount")}</label>
                <div className="relative">
                  <input
                    step="any"
                    className="form-control pr-5"
                    name="amount"
                    value={this.state.amount}
                    onChange={this.handleChange}
                    placeholder={t("Enter Amount")}
                    type="number"
                  />
                  <button
                    className="absolute_btc btn btn_man fs14"
                    onClick={this.autoAmount}
                  >
                    {" "}
                    {t("Max")}
                  </button>
                </div>
              </div>
              <div className="tc">
                <button
                  className="btn btn_man fs14"
                  onClick={this.handleTransfer}
                  disabled={this.state.disableTransferButton}
                >
                  {t("Transfer")}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          {/* Transfer Modal End */}

          {/* CoinList Html Part Start */}
          <div className="bghwhite">
            <div className="dashbord_manbox zero-balance-outer">
              <div className="container container2">
                <div className="dashbord_in1">
                  <div className="dashbord_in white_box mb-4 ">
                    <div className="row align-items-center">
                      <div className="col-md-3 col-12">
                        <div className=" form-check">
                          <label className="form-check-label">
                            <input
                              name="isGoing"
                              type="checkbox"
                              checked={this.state.isGoing}
                              onChange={this.handleCheckChange}
                            />{" "}
                            {t("Hide zero balances wallet")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-5 col-12">
                        {/* <div className=" form-check">
                          <button onClick={this.handleRefreshClick} className="btn btn_man" disabled={this.state.afxInterval}>
                            <i className={"fa fa-refresh" + (this.state.afxInterval ? " spin" : "")}></i>
                            <label className="form-check-label" style={{ marginLeft: "5px" }}>
                              {t("Refresh")}
                            </label>
                          </button>
                        </div>*/}
                      </div>

                      <div className="col-md-4 col-12">
                        <div className="portfolio-amount d-flex justify-content-end align-items-center">
                          <p className="m-0">{t("Portfolio")} :</p>

                          {/* END */}
                          <div className="amount-inner d-flex">
                            {/* <div className="amount-left">
                              <p className="mb-0">
                                <b>{parseFloat(this.state.inrPortfolio)}</b> INR{" "}
                              </p>
                            </div>{" "} */}
                            <div className="amount-right ml-2">
                              <p className="m-0">
                                <b> {parseFloat(this.state.usdtPortfolio)}</b>{" "}
                                USDT
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(this.state.screenWidth > 766) ?
                      <div className="amount-tabel-main-outer">
                        <div className="in_table tc border_man">
                          <div className="table-responsive">
                            <table className="table mb-0 table_man">
                              <thead>
                                <tr>
                                  <th className="tl">
                                    <b>{t("Coin")}</b>
                                  </th>
                                  <th>
                                    <b>{t("Main wallet") + "(" + t("Total") + ")"}</b>
                                  </th>
                                  <th>
                                    <b>{t("Main wallet") + "(" + t("Withdrawal") + ")"}</b>
                                  </th>
                                  <th>
                                    <b>{t("spot wallet")}</b>
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              {this.showTableHtml()}
                            </table>
                          </div>
                        </div>
                      </div> :
                      <>
                        {this.showTableHtml_M()}
                        {/* <div className="account_box">
                      <div className="d-flex">
                      <img src="https://api.dbiexchange.com/static/currencyImage/currency-1659701880817.png" alt="coin_icon" className="currency_icon"/>
                      <a target="_blank" rel="noreferrer">SOL</a>
                      <span className="aero_btn ml-auto collapsed" data-toggle="collapse" data-target="#caaount"><i className="fa fa-angle-down"></i></span>
                      </div>
                      <small className="d-flex mb-2 mt-1">Main wallet <span className="mr-auto ml-1">0.00</span> 
                      Spot wallet		<span className="ml-1">0.00</span></small>
                      <div id="caaount" className="collapse">
                          <div className="d-flex justify-content-between">
                            <button className="btn btn_man fs14">Deposit</button>
                            <button className="btn btn_man fs14">Withdrawal</button>
                            <button className="btn btn_man fs14">Transfer</button>                      
                          </div>
                        </div>
                      </div>
                      <div className="account_box">
                      <div className="d-flex">
                      <img src="https://api.dbiexchange.com/static/currencyImage/currency-1659701880817.png" alt="coin_icon" className="currency_icon"/>
                      <a target="_blank" rel="noreferrer">SOL</a>
                      <span className="aero_btn ml-auto collapsed" data-toggle="collapse" data-target="#caaount2"><i className="fa fa-angle-down"></i></span>
                      </div>
                      <small className="d-flex mb-2 mt-1">Main wallet <span className="mr-auto ml-1">0.00</span> 
                      Spot wallet		<span className="ml-1">0.00</span></small>
                      <div id="caaount2" className="collapse">
                          <div className="d-flex justify-content-between">
                            <button className="btn btn_man fs14">Deposit</button>
                            <button className="btn btn_man fs14">Withdrawal</button>
                            <button className="btn btn_man fs14">Transfer</button>                      
                          </div>
                        </div>
                      </div> */}
                      </>


                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* CoinList Html Part End */}

          {/* Deposit INR Modal Start */}
          <Modal
            show={this.state.isINRDepositModelOpen}
            onHide={this.closeOpenINRDepositModal}
            className="account-popup-outer"
          >
            <Modal.Header closeButton>
              <Modal.Title> Deposit INR</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>I want to deposit</label>
                <input
                  type="number"
                  // value="0"
                  className="form-control"
                  name="inr_amount"
                  value={this.state.inr_amount}
                  onChange={this.handleInrChange}
                />
                <span style={{ color: "red" }}>
                  {this.state.inrDepositeError}
                </span>
              </div>
              <div className="form-group">
                <label>Upload Image:</label>
                <input
                  className="form-control"
                  type="file"
                  id="file-upload3"
                  name="IdentityProof3"
                  onChange={this.onChangeImage3}
                />
                <span style={{ color: "red" }}>
                  {this.state.transactionImageError}
                </span>
              </div>
              <div className="form-group">
                <label>UTR/TID:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Utr number / Transaction number"
                  name="trNumber"
                  value={this.state.trNumber}
                  onChange={this.handleInrChange}
                />
                <span style={{ color: "red" }}>
                  {this.state.transactionIdError}
                </span>
              </div>
              <div className="choose-amount d-flex justify-content-around">
                <a
                  href="javascript:void(0)"
                  onClick={() => this.changeInrValue("1000")}
                >
                  + 1000 INR
                </a>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.changeInrValue("5000")}
                >
                  + 5000 INR
                </a>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.changeInrValue("10000")}
                >
                  + 10000 INR
                </a>
              </div>
              <div className="min-max-amount">
                <div className="row">
                  <div className="col-md-8">
                    <div className="bank-detail bank-left">
                      <h5>Bank Details</h5>
                      <div className="bank-detail-outer border-right">
                        <p>
                          Bank Name :{" "}
                          {this.state.adminbankdetails
                            ? this.state.adminbankdetails.bankname
                            : ""}{" "}
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.bankname
                                );
                                toast.success("Copied");
                              }}
                            ></i>
                          </a>
                        </p>
                        <p>
                          Account Number :{" "}
                          {this.state.adminbankdetails
                            ? this.state.adminbankdetails.accountnumber
                            : ""}{" "}
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.accountnumber
                                );
                                toast.success("Copied");
                              }}
                            ></i>
                          </a>
                        </p>
                        <p>
                          IFSC Code :{" "}
                          {this.state.adminbankdetails
                            ? this.state.adminbankdetails.ifsccode
                            : ""}{" "}
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.ifsccode
                                );
                                toast.success("Copied");
                              }}
                            ></i>
                          </a>
                        </p>
                        <p>
                          Holder Name :{" "}
                          {this.state.adminbankdetails
                            ? this.state.adminbankdetails.accountholder
                            : ""}{" "}
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.accountholder
                                );
                                toast.success("Copied");
                              }}
                            ></i>
                          </a>
                        </p>
                        <p>
                          Branch Name :{" "}
                          {this.state.adminbankdetails
                            ? this.state.adminbankdetails.branchname
                            : ""}{" "}
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-copy"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.adminbankdetails.branchname
                                );
                                toast.success("Copied");
                              }}
                            ></i>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="bank-detail bank-right-outer">
                      <h5>Upi</h5>
                      <p className="upi-id tc">
                        {this.state.adminbankdetails
                          ? this.state.adminbankdetails.upi_id
                          : ""}{" "}
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              this.state.adminbankdetails.upi_id
                            );
                            toast.success("Copied");
                          }}
                        >
                          <i className="fa fa-copy"></i>
                        </a>
                      </p>
                      <div className="qr-img-outer">
                        {this.state.adminbankdetails.upi_image && (
                          <img
                            src={
                              imgUrl +
                              "/static/bankImage/" +
                              this.state.adminbankdetails.upi_image
                            }
                            alt="banner"
                            className="img-thumbnail"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="deposit-amount-btn">
                <button
                  className="btn btn_man fs14 m0a"
                  style={{ display: this.state.depositeInrBtn }}
                  onClick={this.calculateCheckSum}
                  disabled={this.state.disabledInrDeposit}
                >
                  Deposit Amount
                </button>
              </div>
            </Modal.Body>
          </Modal>

          {/* Deposit INR Modal End */}

          {/* Kyc Modal Start For Kyc */}
          <Modal
            show={this.state.isKycModalOpen}
            onHide={this.closeKycModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <div className="kyc_popop tc">
                <img src="assets/img/v_kyc.png" alt="" />
                <h2
                  style={{ color: "#fff" }}
                  ref={(subtitle) => (subtitle = subtitle)}
                >
                  Your KYC is not done. Kindly complete your KYC.{" "}
                </h2>
                <div>
                  <Link className="btn btn_man mt-4 w100px" to="/kyc">
                    Okay!
                  </Link>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Kyc Modal Start For Kyc */}

          {/* Kyc Modal Start For Whitelist */}
          <Modal
            show={this.state.isWhitelistModalOpen}
            onHide={this.closeWhitelistModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <div className="kyc_popop tc">
                <img src="assets/img/v_kyc.png" />
                <h2 ref={(subtitle) => (subtitle = subtitle)}>
                  Please Add Whitelist Address For Withdrawal.{" "}
                </h2>
                <div>
                  <Link className="btn btn_man mt-4 w100px" to="/Whitelist">
                    Okay!
                  </Link>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* Kyc Modal End For Whitelist*/}

          <Modal
            show={this.state.withdrawalbuttonDisable}
            onHide={this.closeWhitelistModal}
            backdrop="static"
            keyboard={true}
          >
            <Modal.Body>
              <div className="kyc_popop tc">
                <img src="assets/img/v_kyc.png" />
                <h2>
                  The initial deposit
                  It  will be activated after checking for more than 120 hours on a business day.
                </h2>

              </div>
            </Modal.Body>
          </Modal>
          {/* <ToastContainer limit={1} /> */}
        </div>
      </DocumentMeta>
    );
  }
}
export default withTranslation()(account);
