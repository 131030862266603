import React, { useEffect, useState } from "react"
import { withTranslation } from 'react-i18next';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Modal } from "react-bootstrap";

import { baseUrl, imgUrl } from "../../Common/BaseUrl";

const Testimonial = (props) => {
  //console.log(props.tradingData);
   const { t } = useTranslation();
    const [tradingData, setTradingData] = useState([]);
    const [isModalModelOpen, setIsModalModelOpen] = useState(false);
    const [modalData, setModalData] = useState({});
  
    const GetTradingData = () => {
      axios
        .post(baseUrl + "/frontapi/get_web_trading_step", { lang: localStorage.getItem("lang") })
        .then((resp) => {
          resp = resp.data;
          console.log(resp);
          if (resp.status && resp.data) {
           // setTradingData(resp.data);
          }
        });
    };
  
    useEffect(() => {
      if(props.tradingData) {
       // console.log(props.tradingData);
     setTradingData(props.tradingData);
      }
    // GetTradingData();
    }, [props]);
  
    const closeOpenModal = (data = {}, status = false) => {
      setModalData(data);
      setIsModalModelOpen(status);
    };
  
    return (
      <div className="how-main-outer p60 text-center">
        <div className="container">
          <div className="section-heading mb-md-4">
            <h2>{t("Start Trading in Few Steps")}</h2>
          </div>
          <div className="how-outer">
            <div className="row">
              {tradingData.map((val, index) => (
                <div className="col-md-3 col-12 mb-4" key={index}>
                  <div className="how-inner">
                    <div className="img-outer">
                      <img
                        src={`${imgUrl}/static/trading_steps/${val.logo}`}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content-outer">
                      <h3>{val.title.length > 50 ? `${val.title.substring(0, 50)}...` : val.title}</h3>
                      <p>
                        {val.description.length > 135
                          ? `${val.description.substring(0, 135)}...`
                          : val.description} 
                        <span
                          style={{ color: "#f1c40f", cursor: "pointer" }}
                          onClick={() => closeOpenModal(val, true)}
                        >
                          Read More
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Modal
          show={isModalModelOpen}
          onHide={() => closeOpenModal({}, false)}
          className="account-popup-outer"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="wallet-balance form-group row">
              <div className="col-md-12 mb-3">
                <div className="img-outer" style={{ textAlign: "center" }}>
                  <img
                    src={`${imgUrl}/static/trading_steps/${modalData.logo}`}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <span className="mb-0 form-control d-flex">{modalData.description}</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  
  export default Testimonial;