import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import { withTranslation } from 'react-i18next';
import * as myConstList from "../Common/BaseUrl";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import Moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { ProductName } from "../Common/ProductName";
const baseUrl = myConstList.baseUrl;

class SpotSell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
      showData: [],
      selectedCoin: "ETH",
    };
  }

  componentWillMount() {
    if (!localStorage.id) {
      window.location.href = "/login-to-buy-sell-crypto";
    }
    this.spotList();
  }

  spotList = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/MyDeposite", {}, config).then((resp1) => {
      var resp = resp1.data;
      if (resp.status) {
        this.setState({
          allData: resp.data,
          showData: resp.data[0],
          TransactionsStatus: resp.status,
        });
      }
    });
  };

  showTableHtml = (value) => {
    const { t } = this.props;
    if (
      this.state.TransactionsStatus === true &&
      Object.keys(this.state.showData).length > 0
    ) {
      const html = [];
      let balance = 0;
      let WalletBAlnace = 0;
      if (value.walletSum != undefined) {
        WalletBAlnace = value.walletSum[0].transAmount;

        if (value.wallet[value.wallet.length - 1] != undefined) {
          balance = value.wallet[value.wallet.length - 1].coin_amount;
        }
      }
      let mainBalance = 0;
      const filteredTransactions =
        value &&
        value.wallet &&
        value.wallet.filter((e) => e.firstCoinName === this.state.selectedCoin);
      if (filteredTransactions.length > 0) {
        filteredTransactions.forEach((e, i) => {
          if (i == value.wallet.length - 1) {
            mainBalance = balance;
          } else {
            if (i > 0) {
              mainBalance = mainBalance + e.coin_amount;
            } else {
              mainBalance = balance + e.coin_amount;
            }
          }

          if (e.tx_type === "buy_exchange") {
            var Buy = "Buy";
          }
          if (e.tx_type === "sell_exchange") {
            Buy = "Sell";
          }
          if (e.tx_type === "sell_exchange" && e.remark === "adminFees") {
            Buy = "Sell (fees)";
          }
          if (e.tx_type === "buy_exchange" && e.remark === "adminFees") {
            Buy = "Buy (fees)";
          }
          html.push(
            <tr>
              <td
                style={
                  e.tx_type === "sell_exchange" ||
                  e.tx_type === "Withdrawal" ||
                  e.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {i + 1}
              </td>
              <td
                style={
                  e.tx_type === "sell_exchange" ||
                  e.tx_type === "Withdrawal" ||
                  e.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {e.coin_amount}
              </td>
              <td
                style={
                  e.tx_type === "sell_exchange" ||
                  e.tx_type === "Withdrawal" ||
                  e.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {" "}
                {e.tx_type !== "Wallet to transactions"
                  ? e.tx_type === "buy_exchange" ||
                    e.tx_type === "sell_exchange"
                    ? t(Buy)
                    : t(e.tx_type)
                  : t("Wallet to Spot")}
              </td>
              {/* <td
                style={
                  e.tx_type === "sell_exchange" ||
                  e.tx_type === "Withdrawal" ||
                  e.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {WalletBAlnace.toFixed(7)}
              </td> */}
              <td
                style={
                  e.tx_type === "sell_exchange" ||
                  e.tx_type === "Withdrawal" ||
                  e.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {e.firstCoinName}
              </td>
              <td
                style={
                  e.tx_type === "sell_exchange" ||
                  e.tx_type === "Withdrawal" ||
                  e.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {Moment(e.created).format("lll")}
              </td>
              <td
                style={
                  e.tx_type === "sell_exchange" ||
                  e.tx_type === "Withdrawal" ||
                  e.tx_type === "Spot to Wallet"
                    ? {
                        backgroundColor: "rgb(241, 67, 47)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                    : {
                        backgroundColor: "rgb(85, 189, 108)",
                        borderBottom: "1px solid white",
                        color: "white",
                        fontWeight: 600,
                      }
                }
              >
                {e.status === "completed" && t("Completed")}
              </td>
            </tr>
          );
          WalletBAlnace = WalletBAlnace - e.coin_amount;
        });
      } else if (!this.state.selectedCoin) {
        value &&
          value.wallet &&
          value.wallet.forEach((e, i) => {
            if (i == value.wallet.length - 1) {
              mainBalance = balance;
            } else {
              if (i > 0) {
                mainBalance = mainBalance + e.coin_amount;
              } else {
                mainBalance = balance + e.coin_amount;
              }
            }

            if (e.tx_type === "buy_exchange") {
              var Buy = "Buy";
            }
            if (e.tx_type === "sell_exchange") {
              Buy = "Sell";
            }
            if (e.tx_type === "sell_exchange" && e.remark === "adminFees") {
              Buy = "Sell (fees)";
            }
            if (e.tx_type === "buy_exchange" && e.remark === "adminFees") {
              Buy = "Buy (fees)";
            }
            html.push(
              <tr>
                <td
                  style={
                    e.tx_type === "sell_exchange" ||
                    e.tx_type === "Withdrawal" ||
                    e.tx_type === "Spot to Wallet"
                      ? {
                          backgroundColor: "rgb(241, 67, 47)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                      : {
                          backgroundColor: "rgb(85, 189, 108)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                  }
                >
                  {i + 1}
                </td>
                <td
                  style={
                    e.tx_type === "sell_exchange" ||
                    e.tx_type === "Withdrawal" ||
                    e.tx_type === "Spot to Wallet"
                      ? {
                          backgroundColor: "rgb(241, 67, 47)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                      : {
                          backgroundColor: "rgb(85, 189, 108)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                  }
                >
                  {e.coin_amount}
                </td>
                <td
                  style={
                    e.tx_type === "sell_exchange" ||
                    e.tx_type === "Withdrawal" ||
                    e.tx_type === "Spot to Wallet"
                      ? {
                          backgroundColor: "rgb(241, 67, 47)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                      : {
                          backgroundColor: "rgb(85, 189, 108)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                  }
                >
                  {" "}
                  {e.tx_type !== "Wallet to transactions"
                    ? e.tx_type === "buy_exchange" ||
                      e.tx_type === "sell_exchange"
                      ? t(Buy)
                      : t(e.tx_type)
                    : t("Wallet to Spot")}
                </td>
                {/* <td
                  style={
                    e.tx_type === "sell_exchange" ||
                    e.tx_type === "Withdrawal" ||
                    e.tx_type === "Spot to Wallet"
                      ? {
                          backgroundColor: "rgb(241, 67, 47)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                      : {
                          backgroundColor: "rgb(85, 189, 108)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                  }
                >
                  {WalletBAlnace.toFixed(7)}
                </td> */}
                <td
                  style={
                    e.tx_type === "sell_exchange" ||
                    e.tx_type === "Withdrawal" ||
                    e.tx_type === "Spot to Wallet"
                      ? {
                          backgroundColor: "rgb(241, 67, 47)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                      : {
                          backgroundColor: "rgb(85, 189, 108)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                  }
                >
                  {e.firstCoinName}
                </td>
                <td
                  style={
                    e.tx_type === "sell_exchange" ||
                    e.tx_type === "Withdrawal" ||
                    e.tx_type === "Spot to Wallet"
                      ? {
                          backgroundColor: "rgb(241, 67, 47)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                      : {
                          backgroundColor: "rgb(85, 189, 108)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                  }
                >
                  {Moment(e.created).format("lll")}
                </td>
                <td
                  style={
                    e.tx_type === "sell_exchange" ||
                    e.tx_type === "Withdrawal" ||
                    e.tx_type === "Spot to Wallet"
                      ? {
                          backgroundColor: "rgb(241, 67, 47)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                      : {
                          backgroundColor: "rgb(85, 189, 108)",
                          borderBottom: "1px solid white",
                          color: "white",
                          fontWeight: 600,
                        }
                  }
                >
                  {e.status === "completed" && t("Completed")}
                </td>
              </tr>
            );
            WalletBAlnace = WalletBAlnace - e.coin_amount;
          });
      } else {
        html.push(
          <tr>
            <td colSpan={8} className="text-center">
              {t("No Data Found")}
            </td>
          </tr>
        );
      }
      return <tbody className="">{html}</tbody>;
    } else {
      return (
        <tr>
          <td colSpan={8} className="text-center">
          {t("No Data Found")}
          </td>
        </tr>
      );
    }
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ selectedCoin: value });
  };

  render() {
    const { t } = this.props;
    const meta = {
      title: `Spot Transactions | ${ProductName} Exchange`,
      description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName} Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    let { selectedCoin, allData } = this.state;
    return (
      <DocumentMeta {...meta}>
        <div>
          <LoginHeader />
          <div className="">
            <div className="bg_light">
              <div className="new_inpage">
                <div className="container container2">
                  <div className="white_box">
                    <div className="row">
                      <div className="col-md-8 col-lg-9">
                        <ul className="nav nav-tabs2 mb-3 ">
                          <li className="nav-item">
                            <Link to="/transactions"> {t("Main wallet")} </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/depositeHistory" className="active">
                              {" "}
                              {t("Spot")}{" "}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/coinDeposite"> {t("Deposit")} {t("Coin")}  </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/coinFundRequest"> {t("Withdrawal")} </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <select
                          className="form-control"
                          value={this.state.selectedCoin}
                          onChange={this.handleChange}
                        >
                          <option value={""}>{t("Select Crypto")}</option>
                          {allData.length > 0 &&
                            allData[0].coinList.map((item) => {
                              return (
                                <option value={item.short_name}>
                                  {item.short_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="tab-content">
                      <div className="tab-pane active" id="Balance">
                        <div className=" top_balance"></div>
                        <div className="rows">
                          <div className="wallet_box">
                            <div className="table-responsive">
                              <div className="scrool_wallet_box">
                                <table className="table table-striped mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>{t("Sr. No.")}</th>
                                      <th>{t("Amount")}</th>
                                      <th>{t("Transaction Type")}</th>
                                      {/* <th>{t("Total Balance")}</th> */}
                                      <th>{t("Coin")}</th>
                                      <th>{t("Created At")}</th>
                                      <th>{t("Status")}</th>
                                    </tr>
                                  </thead>

                                  {this.showTableHtml(
                                    this.state.showData || []
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane " id="Transactions">
                        <div className="tab-content">
                          <div className="tab-pane active " id="Ledger"></div>
                          <div className="tab-pane" id="Transactions2">
                            <div className="wallet_box">
                              <div className="table-responsive">
                                <table className="table ">
                                  <thead>
                                    <tr>
                                      <th>Created</th>
                                      <th>Deposit</th>
                                      <th>Withdraw</th>
                                      <th className="tr">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* {this.showTableHtml()} */}
                                    <tr>
                                      <td>Nov 20, 2020</td>
                                      <td>200</td>
                                      <td>Description </td>
                                      <td className="tr">0</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div>
          </div> */}
          </div>

          <Footer />
        </div>
      </DocumentMeta>
    );
  }
}

export default withTranslation()(SpotSell);
