import React, { useEffect, useState } from "react";
import { EmailValidation } from "../Validation/EmailValidation";
import axios from "axios";
import { toast } from "react-toastify";
import * as myConstList from "../Common/BaseUrl";
import { MobileApi, checkKycStatus, personalNameApi } from "./Apis";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";

const baseUrl = myConstList.baseUrl;
const Personal = (props) => {
  const { t } = useTranslation();
  //console.log(props);
  const [userdata, setUserData] = useState([]);
  const [length, setLength] = useState(0);
  const [email, setEmail] = useState("");
  const [verification_code, setVerification_code] = useState("");
  const [counter, setCounter] = useState(0);
  const [timerText, setTimerText] = useState("");
  const [textForCode, setTextForCode] = useState("Get Code");
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [codeVerifyStatus, setCodeVerifyStatus] = useState(false);
  const [codeBtnDisable, setCodeBtnDisable] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [nameDisable, setNameDisable] = useState(false);
  
  const [selectedFiledType, setSelectedFiledType] = useState("email");
  const [countryCode, setCountryCode] = useState("");
  const [disableMobile, setDisableMobile] = useState(false);
  const [mobileLength, setMobileLength] = useState(15);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [namefError, setfNameError] = useState("");
  const [namelError, setlNameError] = useState("");


  const user = props.userinfo && props.userinfo[0];

  const isKycComplete =
    user?.selfie_status === "1" && user?.passport_status === "1";

  useEffect(() => {
 //  console.log("dsfhvjsdfhsdgfsdf", props.userinfo);
    if (props.userinfo.length > 0) {
      setLength(props.userinfo.length);
      setUserData(props.userinfo);
      setNameDisable(props.userinfo[0].personal_info_status ? true : false);
      
      // console.log(
      //   "props.userinfo[0].pi_status",
      //   typeof props.userinfo[0].pi_status,
      //   props.userinfo[0].pi_status == "2"
      // );
      if (props.userinfo[0].pi_status == "2"){ 
        setCodeBtnDisable(true);
      }
      if(props.userinfo[0].mobile_no != null) {
        console.log("trueeeee");
        setDisableMobile(true)
      }
      setEmail(props.userinfo[0].email);
      setCountryCode(
        props.userinfo[0].country_code ? props.userinfo[0].country_code : ""
      );
      setMobile(props.userinfo[0].mobile_no);
      setFirstname(props.userinfo[0].firstName);
      setLastname(props.userinfo[0].lastName);
    }
  }, [props]);
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    //console.log("propsorpsor");
    if (name == "email") {
      value = value.toLowerCase();
      setEmail(value);
      const emailvalidation = EmailValidation(name, value);
      setEmailError(emailvalidation);
      if (textForCode == "Resend Code") {
        setTextForCode("Get Code");
        setVerification_code("");
        // this.setState({textForCode:"Get Code"})
        // console.log("calling for error")
        // this.setState({VerificationCode:""})
      }
    }
    if (name == "verification_code") {
    //  console.log(value.length);

      if (value.length <= 6) {
        setVerification_code(value);
      }
      if (!value) {
        setCodeError("This field is required");
      } else {
        setCodeError("");
      }
    }
    if (name == "mobile") {
     // console.log("insidemobiler", name, value);
      setMobile(value);
      if (value === "") {
        setMobileError("This field is required");
        return false;
      }
      if (!(value.length >= 5 && value.length <= 15)) {
        setMobileError("Invalid mobile number");
        return false;
      }
      // const error = MobileValidation(name, value)
      setMobileError("");
    }
  };
  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      setCounter(counter);
      setCodeBtnDisable(true);
      setTimerText("Resend In");
      setDisableMobile(true);
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        setTextForCode("Resend Code");
        setCodeBtnDisable(false);
        setTimerText("");
        setDisableMobile(false);
      }
    }, 1000);
  };
  const getCode = async () => {
    console.log("rmail ",email);
    if(email) {
    if (emailError) {
      return false;
    }
    const emailvalidation = EmailValidation("email", email);
    if (emailvalidation) {
      setEmailError(emailvalidation);
      return false;
    }

    const config = {
      headers: {
        lang:localStorage.getItem("lang"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let data = {
      email: email,
      page: "kyc",
    };
    await axios.post(baseUrl + "/frontapi/getcode", data, config).then((resp) => {
      let response = resp.data;
      //console.log(resp);
      if (response.status) {
        // setVerification_code(response.code)
        startCountdown(30);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    });
  }else {
    setEmailError("This field is required");
  }
  };

  const getCodeMobile = async () => {
  //  console.log("mobile");
    if (mobileError) {
      return false;
    }

    const regex = /^[1-9][0-9]{0,14}$/;

    if (!regex.test(countryCode.toString())) {
      // setCountryCode("+");
      setMobileError("Please enter a valid country code");
      return;
    }

    if (!mobile) {
      setMobileError("This field is required");
      return;
    }

    if (mobile.length < 5 || mobile.length > 15) {
      setMobileError(
        "Mobile number should be atleast 5 and not more than 15 digits"
      );
      return;
    }
    setCodeBtnDisable(true)

    const config = {
      headers: {
        lang:localStorage.getItem("lang"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let data = {
      mobile: mobile,
      page: "kyc",
      selectedFiledType: selectedFiledType,
      countryCode: countryCode,
    };
    await axios.post(baseUrl + "/frontapi/getcode", data, config).then((resp) => {
     // console.log(resp);
      setCodeBtnDisable(false)
      let response = resp.data;
      if (response.status) {
        startCountdown(30);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    });
  };

  const handleSubmit = async (e) => {
    if (!email && !verification_code) {
      setEmailError("This field is required");
      setCodeError("This field is required");
      return false;
    }
    if (!email) {
      setEmailError("This field is required");
      return false;
    }
    if (!verification_code) {
      setCodeError("This field is required");
      return false;
    }
    if (emailError || codeError) {
      return false;
    }
    let data = {
      page: "kyc",
      email: email,
      mobile: mobile,
      verification_code: verification_code,
    };
    const config = {
      headers: {
        lang:localStorage.getItem("lang"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setTimeout(() => {
      setBtnDisable(true);
    }, 50);
    await axios
      .post(baseUrl + "/frontapi/codeVerify", data, config)
      .then((resp) => {
        setBtnDisable(false);
        let response = resp.data;
        if (response.status) {
          toast.success(response.message);
          props.resendResult(true);
          setCodeVerifyStatus(true);
        } else {
          toast.error(response.message);
        }
      });
  };

  const handleSubmitName = async () => {

    if (!firstname) {
      setfNameError("This field is required");
      return false;
    }
    if (!lastname) {
      setlNameError("This field is required");
      return false;
    }

    let data = {
      fname: firstname,
      lname: lastname
    };
    const config = {
      headers: {
        lang:localStorage.getItem("lang"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let result = await personalNameApi(data, config);
    if (result.status) {
      toast.success(result.message);
      props.resendResult(true);
    } else {
      toast.error(result.message);
    }



  }
  const handleMobileSubmit = async () => {
  //  console.log("handleMobileSubmit ", firstname);    
    if (!firstname) {
      setfNameError("This field is required");
      return false;
    }
    if (!lastname) {
      setlNameError("This field is required");
      return false;
    }

    if(userdata.length && !userdata[0].personal_info_status) {
      toast.error(t("Please submit your name"));
      return false;
    }
    if (!mobile) {
      setMobileError("This field is required");
      return false;
    }
    if (!(mobile.length >= 7 && mobile.length <= 15)) {
      setMobileError("Invalid mobile number");
      return false;
    }
    if (!verification_code) {
      setCodeError("This field is required");
      return false;
    }

    if (mobileError) {
      return false;
    }
    let data = {
      first_name: firstname,
      last_name: lastname,
      mobile: mobile,
      code: verification_code,
      countryCode: countryCode,
    };
    const config = {
      headers: {
        lang:localStorage.getItem("lang"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let result = await MobileApi(data, config);
    if (result.status) {
      toast.success(result.message);
      props.resendResult(true);
    } else {
      toast.error(result.message);
    }
  };

  const handleOnChange = (value, country) => {
    // You can access the selected country and formatted phone number here
  //  console.log("Selected Country:", country);
  //  console.log("Formatted Phone Number:", value);
    if (!value) {
      setSelectedFiledType("email");
    } else {
      setSelectedFiledType("mobile");
    }

    let adjustedMobile = value.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    setMobile(adjustedMobile);
    setTextForCode("Get Code");
    setCountryCode(country.dialCode);
    setMobileError("");
    setEmailError("");
  };

  const handleChangeMobile = (event) => {
    const { name, value } = event.target;
   // console.log(name, value)

    setEmailError("");
    // setEmail("");
    setSelectedFiledType("mobile");
    if (name === "phone") {
     // console.log(value.length);
      if (value.length <= mobileLength) {
        setMobile(value);
      }

      const regex = /^0[0-9].*$/;
      // return regex.test(phoneNumber);
     // console.log(regex.test(value));

      if (regex.test(value)) {
        setMobileError("Please enter valid mobile number");
        return;
      }

      if (!countryCode) {
        setMobileError("Country code is required");
        return;
      }

      if (!value) {
        setMobileError("This field is required");
        return;
      }

      if (value.length < 5 || value.length > 15) {
        setMobileError(
          "Mobile number should be atleast 5 and not more than 15 digits"
        );
        return;
      }

      // setSelectedFiledType("mobile"); // Otherwise, stay on mobile input

      setEmailError("");
      setMobileError("");

      // if (!value) {
      //   setSelectedFiledType("email");
      // } else {
      //   setSelectedFiledType("mobile");
      // }

      if (textForCode == "Resend Code") {
        setTextForCode("Get Code");
      }

      // setMobile(value);
      // setCountryCode("+" + country.dialCode);
      // setMobileError("");
    }

    if (name === "countryCode") {
    
      setMobile("");

     // console.log(value.length);
      if(value.length < 5){
        setCountryCode(value);
      }
      
      if (!value || value.length < 2) {
       // setCountryCode("");
        setMobileError("Country code is required");
        return;
      }
      

      const regex1 =/^[1-9][0-9]{0,14}$/;
     // /^0[0-9].*$/;
      

      if (!regex1.test(value.toString())) {
        setMobileError("1Please enter valid country code");
        return;
      }


      if (value.length <= 4) {
        if (value == "+91") {
          setMobileLength(10);
        } else {
          setMobileLength(15);
        }
        
      }

      const regex = /^\+\d{1,4}$/;

      // if (!regex.test(value)) {
      //   // setCountryCode("+");
      //   setMobileError("Please enter a valid country code");
      //   return;
      // }

      setMobileError("");
    }
  };

  const handleChangeName = (event) => {
    const { name, value } = event.target;
    if (name === "firstname") {
      setfNameError("")
      setFirstname(value)
    }
    if (name === "lastname") {
      setlNameError("")
      setLastname(value)
    }
  }

  const handleCheckStatus = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        lang:localStorage.getItem("lang"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let result = await checkKycStatus({}, config);
    if (result.status) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
  };

  const handleWheel = (event) => {
  //  console.log(" evtn ==== ", event);
    if (document.activeElement === event.target) {
      event.preventDefault(); // Prevent scroll behavior
    }
  };

  return (
    <>
      <div className="personal-outer">
        <div className="row align-items-top">
          <div className="col-md-3 col-12">
            <div className="white_box heading-main ">
              <h6
                className={
                  props.status.length > 0 && props.status[0].personal == "2"
                    ? "position-relative complete"
                    : "position-relative "
                }
              >
                {t("Verify Contact")}
              </h6>

              <h6
                className={
                  props.status.length > 0 && props.status[0].passport == "2"
                    ? "position-relative complete"
                    : "position-relative"
                }
              >
                {t("Identity Proof")}
              </h6>
              <h6
                className={
                  props.status.length > 0 && props.status[0].utility_status == "2"
                    ? "position-relative complete"
                    : "position-relative"
                }
              >
                {t("Utility Verification")}
              </h6>
              <h6
                className={
                  props.status.length > 0 && props.status[0].selfie == "2"
                    ? "position-relative complete"
                    : "position-relative"
                }
              >
                {t("Face Verification")}
              </h6>
              {/* {isKycComplete && (
                <button
                  className="btn btn_man mt-2"
                  onClick={handleCheckStatus}
                >
                  {t("Check KYC Status")}
                </button>
              )} */}
            </div>
          </div>
          <div className="col-md-9 col-12">
            <div className="white_box">
              {t("Fill in your personal information the way it is stated in the proof of identity document you submit")}

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="form-group col-5">
                  <label>{t("First Name")}</label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    placeholder={t("enter")+" "+t("First Name")}
                    name="firstname"
                    autoFocus
                    value={firstname}
                    disabled={nameDisable}
                    onChange={handleChangeName}

                  />
                  {namefError && (
                    <div className="text-danger">{t(namefError)}</div>
                  )}
                </div>
                <div className="form-group col-5">
                  <label>{t("Last Name")}</label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    placeholder={t("enter")+" "+t("Last Name")}
                    name="lastname"
                    autoFocus
                    value={lastname}
                    disabled={nameDisable}
                    onChange={handleChangeName}

                  />
                  {namelError && (
                    <div className="text-danger">{t(namelError)}</div>
                  )}
                </div>

                {(!nameDisable) ? <div className="form-group col-2">

                  <div className="submit-btn">
                    <button
                      className="btn btn_man fs12 mt-3"
                      type="button"
                      onClick={handleSubmitName}
                    >
                      {t("submit")}
                    </button>
                  </div>
                </div> : ""}

              </div>
              {/* <h6 className="position-relative">
                {length > 0 && t(userdata[0].mobile_no) && t("Email Address")}
              </h6> */}
              <p>

              </p>

              <div className="fs12">
                {length > 0 && (
                  <form>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>{t("Email")}</label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          placeholder="Enter Email Address"
                          minLength="2"
                          maxlength="100"
                          value={email}
                          maxLength="64"
                          disabled={
                            length > 0 && userdata[0].email
                              ? true
                              : false 
                          }
                          onChange={handleChange}
                        />
                        {emailError && (
                          <div className="text-danger">{emailError}</div>
                        )}
                        {length > 0 && !userdata[0].email && (
                          <div className="submit-btn">
                            <button
                              className="btn btn_man fs12 mt-3"
                              type="button"
                              onClick={handleSubmit}
                            >
                              {t("submit")}
                            </button>
                          </div>
                        )}
                      </div>
                      {length > 0 && !userdata[0].email && (
                        <div className="form-group col-md-6">
                          <label>{t("verification_code")}</label>
                          <div className="input-group-prepend">
                            <input
                              type="text"
                              className="form-control"
                              name="verification_code"
                              placeholder={t("Enter Verification Code")}
                              minLength="2"
                              maxlength="20"
                              value={verification_code}
                             
                              onChange={handleChange}
                            />
                            <div className="input-group-prepend">
                              <button
                                className="btn btn_man"
                                id="btnGroupAddon"
                                type="button"
                                onClick={getCode}
                                disabled={codeBtnDisable}
                              >
                                {timerText}
                                {"  "}
                                {counter == 0 ? t(textForCode) : counter}
                              </button>
                            </div>
                          </div>
                          {codeError && (
                            <div className="text-danger">{codeError}</div>
                          )}
                        </div>
                      )}
                    </div>
                  </form>
                )}

                {length > 0 && (
                  <>
                    <div className="row">

                      <div className="form-group kyc-right col-md-6 m-0">
                        <label>{t("Mobile Number")}</label>
                       
                          <div className="row">

                            <div className="form-group col-3">
                            <span style={{"position": "absolute", "top": "50%", "left": "20px", "transform": "translateY(-50%)", "fontSize": "16px; color: #6c757d"}}>+</span>
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                placeholder={t("code")}
                                name="countryCode"
                                autoFocus
                                value={countryCode}
                               style={{"paddingLeft":"15px"}}
                                onWheel={handleWheel}
                                onChange={(e)=>handleChangeMobile(e)}
                               disabled={ disableMobile
                                ? disableMobile
                                : counter == 0
                                  ? false
                                  : true}
                              />
                            </div>
                          
                            <div className="form-group col-9 pl-0">
                              <input
                                className="form-control"
                                type="number"
                                autoComplete="off"
                                placeholder={t("Enter Mobile No")}
                                name="phone"
                                autoFocus
                                value={mobile}
                                // disabled={codeBtnDisable}
                                onChange={(e)=>handleChangeMobile(e)}
                                disabled={
                                  disableMobile
                                    ? disableMobile
                                    : counter == 0
                                      ? false
                                      : true
                                }
                              />
                            </div>
                          </div>
                       
                        {// 
                        //   <div className="row">
                        //     <div className="form-group col-3">
                        //       <input
                        //         className="form-control"
                        //         type="text"
                        //         autoComplete="off"
                        //         placeholder="Country Code"
                        //         name="countryCode"
                        //         autoFocus
                        //         value={countryCode}
                        //         disabled={codeBtnDisable}
                        //         onChange={handleChangeMobile}
                        //       // disabled={counter == 0 ? false : true}
                        //       />
                        //     </div>

                        //     <div className="form-group col-9 pl-0">
                        //       <input
                        //         className="form-control"
                        //         type="number"
                        //         autoComplete="off"
                        //         placeholder="Enter Mobile No."
                        //         name="phone"
                        //         autoFocus
                        //         value={mobile}
                        //         onChange={handleChangeMobile}
                        //         disabled={codeBtnDisable ? codeBtnDisable : counter == 0 ? false : true
                        //         }
                        //       />
                        //     </div>
                        //   </div>
                        // )
                        }
                        {mobileError && (
                          <div className="text-danger">{t(mobileError)}</div>
                        )}
                      </div>
                      {length > 0 && !userdata[0].mobile_no && (
                        <div className="form-group col-md-6 m-0">
                          <label>{t("verification_code")}</label>
                          <div className="input-group-prepend">
                            <input
                              type="text"
                              className="form-control"
                              name="verification_code"
                              placeholder={t("Enter Verification Code")}
                              minLength="2"
                              maxlength="20"
                              value={verification_code}
                              onChange={handleChange}
                            />
                            <div className="input-group-prepend">
                              <button
                                className="btn btn_man"
                                id="btnGroupAddon"
                                type="button"
                                onClick={getCodeMobile}
                                disabled={codeBtnDisable}
                              >
                                {t(timerText)}
                                {"  "}
                                {counter == 0 ? t(textForCode) : counter}
                              </button>
                            </div>
                          </div>
                          {codeError && (
                            <div className="text-danger">{t(codeError)}</div>
                          )}
                        </div>
                      )}
                      {length > 0 && !userdata[0].mobile_no && (
                        <div className="col-md-6 mb-md-1 mt-1">
                          <button
                            className="btn btn_man fs12"
                            type="button"
                            onClick={handleMobileSubmit}
                          >
                            {t("submit")}
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};
export default Personal;