import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { contextType } from 'react-modal';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import * as myConstList from "../Common/BaseUrl";
import SelfieClick from '../SettingsFile/SelfieClick';
import { imageBase64, ImageValidation } from '../Validation/ImageValidation';
import { faceMatchApi, SelfieApi } from './Apis';
import Rejectreason from './Rejectreason';
const baseUrl = myConstList.baseUrl;
const imgUrl = myConstList.imgUrl;

const Face = (props) => {
  const { t } = useTranslation();
  const [image, setImage] = useState("/assets/img/photo_with_board.png")
  const [selfie, setSelfie] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const [selfieError, setSelfieError] = useState("")
  const [useruploadSelfieImage, setUseruploadSelfieImage] = useState("")
  const [btnDisable, setBtnDisable] = useState(false)
  const [selfieStatus, setSelfieStatus] = useState("0")
  const [imageType, setImageType] = useState("upload")
  const [rejectionReason, setRejectionReason] = useState("")
  const [selfieImageError, setSelfieImageError] = useState("")
  const [BtnDisable4, setBtnDisable4] = useState(false)
  const [selfieClicedButtonByUser, setselfieClicedButtonByUser] = useState("")
  const [IdentityPic1, setIdentityPic1] = useState("")
  const [selfieImagesDisabled, setselfieImagesDisabled] = useState(false)
  const [invalidImage1, setinvalidImage1] = useState("")
  const [selfiie, setselfiie] = useState("")
  // const [faDisable, setfaDisable] = useState(false)
  // const [textSelfieButton, settextSelfieButton] = useState("Save")
  // const [disabledSelfie, setdisabledSelfie] = useState(false)
  // const [userUploadImage1, setuserUploadImage1] = useState("")
  const [selfiopen, setselfiopen] = useState(false)
  const [selfibtn, setselfibtn] = useState(false)
  const [state, setState] = useState({
    selfieClicedButtonByUser: "",
    IdentityPic1: "",
    userUploadImage1: "",
    selfieImageError: "",
    reUploadSelfie: 0,
  })

  useEffect(() => {
    if (props.userinfo.length > 0) {
      setCountryCode(props.userinfo[0].country_code)
      setSelfieStatus(props.userinfo[0].selfie_status)
      props.userinfo[0].selfie_image && setImage(imgUrl + "/static/upload/selfie/" + props.userinfo[0].selfie_image)
      setRejectionReason(props.userinfo[0].d4_rejectResion)
      // let dataParse = JSON.parse(props.userinfo[0].i_image3)
      // console.log("facedataworkupdate", dataParse)
      // setPanCardData(dataParse)
    }
  }, [props])

  const handleChange = (e) => {
    let name = e.target.name
    if (name == "selfie") {
      //  console.log("useeffectinsideface", e.target.files[0])
      let error = ImageValidation("Selfie", e.target.files[0])
      setSelfieError(error)
      setSelfie(e.target.files[0])
      setImageType("upload")
      e.target.files[0] ? setUseruploadSelfieImage(URL.createObjectURL(e.target.files[0])) :
        setUseruploadSelfieImage("")
    }
  }
  // const handleSelfieChange = (e) => {
  //     setImageType("selfie")
  //     setSelfie(e)
  //     setUseruploadSelfieImage(e)

  // }
  const handleSubmit = async () => {
    if (!selfie) {
      setSelfieError("This field is required")
      return false
    }
    if (selfieError) {
      return false
    }
    const config = {
      headers: {
        lang: localStorage.getItem("lang"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let url = ""
    // if(imageType == "selfie"){
    //     var formdata = {
    //         IdentityProof1:selfie
    //     }
    //     url = "cameraSelfie"
    // }
    // if(imageType == "upload"){
    var formdata = new FormData();
    formdata.append("IdentityProof1", selfie);
    // }
    setTimeout(() => {
      setBtnDisable(true)
    }, 50);
    // let api = await SelfieApi(formdata, url, config)

    // if (api.status) {
    let faceMatch = await SelfieApi(formdata, config)
    // console.log("facematchhuakyadost", faceMatch)
    setBtnDisable(false)
    props.resendResult(true)
    if (faceMatch.status) {
      toast.success(faceMatch.message)
    }
    else {
      toast.error(faceMatch.message)
    }

    // }
  }
  const handleSelfieChange = (e) => {
    // e.preventDefault()
    // console.log("hanslesefiedata", e);
    setState({
      selfieClicedButtonByUser: e,
      IdentityPic1: "",
      userUploadImage1: "",
      selfieImageError: "",
      reUploadSelfie: 0,
    });
    setselfiopen(!selfiopen)
    setImage(e)
    setUseruploadSelfieImage(e)
    setselfiie(e)
    setselfibtn(true)
    // const reader = new FileReader();
    // const file = e.target.files[0];

    // reader.onloadend = () => {
    //     setUseruploadSelfieImage(reader.result)

    // };

    // if (file) {
    //   reader.readAsDataURL(file);
    // } else {
    //     setUseruploadSelfieImage("")

    // }
  };
  function IdentitySubmit1(event) {
    event.preventDefault();

    if (!state.selfieClicedButtonByUser) {
      toast.error("Please select/upload image first");
      state.selfieImageError = "Please upload selfie image first";
      return false;
    }

    if (selfieImageError) {
      setSelfieImageError(selfieImageError)

      return false;
    }

    var IdentityPic1 = state.IdentityPic1;
    setBtnDisable4(true)

    const formData = new FormData();
    var selfieData;
    var selfieUrl;

    if (IdentityPic1) {
      formData.append("IdentityProof1", state.IdentityPic1);
      selfieUrl = "/frontapi/selfie";
    } else {
      selfieData = {
        IdentityProof1: state.selfieClicedButtonByUser,
      };
      selfieUrl = "/frontapi/cameraSelfie";
    }

    const config = {
      headers: {
        // "content-type": "multipart/form-data",
        lang: localStorage.getItem("lang"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    setselfieImagesDisabled(true)
    setTimeout(() => (setselfieImagesDisabled(false)), 1000);

    axios
      .post(baseUrl + selfieUrl, IdentityPic1 ? formData : selfieData, config)
      .then((resp) => {
        var resp1 = resp.data;

        setinvalidImage1(resp1.message)
        setBtnDisable4(false)
        if (resp1.status === true) {
          props.resendResult(true)
          //   GetUserData(); // Assuming GetUserData is a global function
          toast.success("Image uploaded successfully");
          window.location.reload()
        } else {
          toast.error(resp1.message);
        }
      });
  }
  const SelfieStatusHtml = () => {
    if (selfieStatus === "1") {
      return (
        <div className="alert alert-success  alert_man">
          <i className="fa fa-check-circle mr-2"></i>Waiting for admin to verify
        </div>
      );
    }
    if (selfieStatus === "3") {
      return (
        <div className="alert alert-danger  alert_man">
          <i className="fa-regular fa-circle-xmark"></i>Rejected <br />
          {/* {this.statselfie_reason
                ? "Reason -" + this.state.selfie_reason
                : ""} */}
        </div>
      );
    }
    if (selfieStatus === "2") {
      return (
        <div className="alert alert-success alert_man">
          <i className="fa fa-check-circle mr-2"></i>Selife Verified{" "}
        </div>
      );
    }
  };
  const handleSelfiopen = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator &&
        navigator.mediaDevices
          .getUserMedia({ video: { width: 300 } })
          .then((stream) => {
            setselfiopen(!selfiopen);
            setselfibtn(!selfibtn);
            setSelfieError("");
          })
          .catch((err) => {
            console.error("error:", err);
            setSelfieError("No camera detected");
            return;
          });
    } else {
      setSelfieError("No camera detected");
    }
    return;
  }
  // console.log("seldbtn",selfibtn,selfiopen)
  return (
    <>
      <div className='face-outer'>
        <div className='row'>
          <div className='col-md-3 col-12'>
          </div>

          <div className='col-md-9 col-12'>

            <div className="white_box">
              <h6 className="position-relative">
                {t("Face Verification")}
              </h6>
              {/* <img  src={
                                                    useruploadSelfieImage !== ""
                                                        ? useruploadSelfieImage
                                                        : image
                                                }
                                                className="img-fluid22"
                                                alt="Identity"/> */}
              <div className="row">
                <div className='col-md-6 col-12'>
                  <b className="mt-0">{t("Requirements")}</b>
                  <ul className="dit_ul mb-4">
                    <li>
                      {t("The photo/scan must be clear, in color and uncompressed.")}
                    </li>
                    <li>
                      {t("The photo/scan must be not edited and not cropped.")}
                    </li>
                    <li>
                      {t("The document must be valid at least 3 months after the verification submission date.")}
                    </li>
                    {/* <li>
                                            The full name and date of birth you provided in
                                            the "Personal information" section{" "}
                                        </li> */}
                    <li>
                      {t("Should exactly match the ones in your document.")}
                    </li>

                  </ul>
                </div>
                <div className="form-group col-md-6 col-12">
                  {!selfiopen ? <>
                    <div
                      id="file-upload-form"
                      className="uploader mt-3"
                    >
                      <input
                        type="file"
                        id="selfie"
                        name="selfie"
                        accept="image/*"
                        onChange={handleChange}
                        disabled={selfieStatus !== "0" && selfieStatus !== "3" ? true : false}
                      />
                      <label for="selfie" id="file-drag">
                        {/* console.log("selfieclickbyuser", useruploadSelfieImage) */}
                        <img
                          src={
                            useruploadSelfieImage !== ""
                              ? useruploadSelfieImage
                              : image
                          }
                          className="img-fluid22"
                          alt="Identity"
                        />
                        <div id="start">
                          <span className="fasi"></span>
                          <div>{t("Upload Face Image")}</div>
                          <div id="notimage" className="hidden">
                            {t("Please select an image")}
                          </div>
                        </div>

                        <div id="response" className="hidden">
                          <div id="messages"></div>
                          <progress
                            className="progress"
                            id="file-progress"
                            value="0"
                          >
                            <span>0</span>%
                          </progress>
                        </div>
                      </label>
                    </div>
                    <p className="mt-0">
                      *{t("only jpeg, jpg & png files supported")}
                    </p>
                  </> : <><SelfieClick
                    fun={handleSelfieChange}
                    selfie_status={selfieStatus}
                    selfiie={selfiie}
                  />
                    {/* <button
                              className="btn btn_man fs12 w100px"
                              onClick={IdentitySubmit1}
                              disabled={
                                faDisable === true
                                  ? faDisable
                                  : BtnDisable4
                              }
                            >
                              {" "}
                              {textSelfieButton}
                            </button> */}
                  </>}

                  {selfieError && (
                    <div className="text-danger">
                      <p>{t(selfieError)}</p>
                    </div>
                  )}

                  {selfieStatus == 1 || selfieStatus == 2 ? "" : <button className='btn btn-success' onClick={handleSelfiopen}>{!selfiopen ? t("Selfie") : t("Face")}</button>}

                  <Rejectreason reason={rejectionReason} status={selfieStatus} />

                  {/* {
                                        selfieError &&
                                        <div className='text-danger'>{selfieError}</div>
                                    } */}
                </div>
              </div>
              {selfieStatus !== "2" && <button
                className="btn btn_man w100px"
                onClick={selfibtn ? IdentitySubmit1 : handleSubmit}
                disabled={btnDisable || selfieStatus !== "0" && selfieStatus !== "3"}
              >
                {btnDisable || selfieStatus !== "0" && selfieStatus !== "3" ? t("Submitted") : t("submit")}
              </button>}
              {/* <div className=''>
                            <button
                                className="btn btn_man"
                                onClick={()=>{
                                    setImageType("selfie")
                                }}
                                disabled={btnDisable}
                            >
                               By Clicking Selfie
                            </button>
                               {imageType == "selfie" && <SelfieClick
                                fun={handleSelfieChange}
                                disabled={selfieStatus !== "0" ? true:false}                            
                                />}
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Face