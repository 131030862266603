import React, { useEffect, useRef, useState } from "react";
import ExchangeKycHeader from "../Common/ExchangeHeader";
import TradingViewChart from "./TradingView";
import { Link, useParams } from "react-router-dom";
import Ticker from "./Ticker";
import TradingViewWidget, { Themes, BarStyles } from "react-tradingview-widget";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl,aheader,aheader_form } from "../Common/BaseUrl";
import Moment from "moment";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import $ from "jquery";
import axios from "axios";
import MarketTrade from "./MarketTrade";
import { connect } from "react-redux";
import { Modal, closeButton } from "react-bootstrap";
import { ProductName } from "../Common/ProductName";

const ExchnageFunc = (props) => {
  const wsUrl = "wss://stream.arthafx.io/";
  // const wsUrl = "wss://streamafx.technoloaderitservices.com/";
  const clientRef = useRef(null);
  const binanceWebsocket = useRef(null);
  const params = useParams();
  const [firstCoin, setFirstCoin] = useState("");
  const [isActive, setIsActive] = useState("N");
  const [secondCoin, setSecondCoin] = useState("");
  const [setUrl, setSetUrl] = useState("");
  const [exchange_tab, setExchange_tab] = useState([]);
  const [coinListData, setCoinListData] = useState([]);
  const [exchange, setExchange] = useState("");
  const [topBarStatus, setTopBarStatus] = useState(false);
  const [topBarApiStatus, setTopBarApiStatus] = useState(false);
  const [topBarApiData, setTopBarApiData] = useState([]);
  const [buyAmount, setBuyAmount] = useState("");
  const [setActiveTab, setSetActiveTab] = useState(params.secondCoin);
  const [buy_order_total, setBuy_order_total] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [cryptoListStatus, setCryptoListStatus] = useState(false);
  const [cryptoList, setCryptoList] = useState([]);
  const [coinListStatus, setCoinListStatus] = useState(false);
  const [mySpotBalance, setMySpotBalance] = useState([]);
  const [firstCoinNew, setFirstCoinNew] = useState("");
  const [secondCoinBalance, setSecondCoinBalance] = useState(0);
  const [firstCoinBalance, setFirstCoinBalance] = useState(0);
  const [limit, setLimit] = useState(true);
  const [orderMethod, setOrderMethod] = useState("limit");
  const [market, setMarket] = useState(false);
  const [stopLimit, setStopLimit] = useState(false);
  const [stopPriceBuy, setStopPriceBuy] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [currentPrice, setCurrentPrice] = useState("");
  const [buyPrice, setBuyPrice] = useState("");
  const [type, setType] = useState("number");
  const [order_id, setOrder_id] = useState("");
  const [buy_commission, setBuy_commission] = useState("");
  const [bPBtn, setBPBtn] = useState(0);
  const [feeAmyt, setFeeAmyt] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [sell_order_total, setSell_order_total] = useState("");
  const [sellFeeAmyt, setSellFeeAmyt] = useState("");
  const [disabledBuuButtn, setDisabledBuuButtn] = useState(false);
  const [isOrderEdit, setIsOrderEdit] = useState(false);
  const [isFeeEnable, setIsFeeEnable] = useState("0");
  const [isFeeApply, setIsFeeApply] = useState("N");
  const [sell_commission, setSell_commission] = useState("");
  const [firstCoinId, setFirstCoinId] = useState("");
  const [secondCoinId, setSecondCoinId] = useState("");
  const [buy_order_total_write, setBuy_order_total_write] = useState("");
  const [firstCoinBalanc, setFirstCoinBalanc] = useState("");
  const [stopPriceSell, setStopPriceSell] = useState("");
  const [sell_order_id, setSell_order_id] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [sPBtn, setSPBtn] = useState(0);
  const [disabledSellButtn, setDisabledSellButtn] = useState(false);
  const [activeSellOrder, setActiveSellOrder] = useState(false);
  const [activeSellOrderData, setActiveSellOrderData] = useState([]);
  const [activeBuyOrder, setActiveBuyOrder] = useState(false);
  const [activeBuyOrderData, setActiveBuyOrderData] = useState([]);
  const [marketTrade, setMarketTrade] = useState(false);
  const [marketTradeData, setMarketTradeData] = useState([]);
  const [myActiveBuyOrder, setMyActiveBuyOrder] = useState(false);
  const [myActiveBuyOrderData, setMyActiveBuyOrderData] = useState([]);
  const [updateAndDeleteButton, setUpdateAndDeleteButton] = useState(false);
  const [myCompletedBuyOrder, setMyCompletedBuyOrder] = useState(false);
  const [myCompletedBuyOrderData, setMyCompletedBuyOrderData] = useState([]);
  const [myActiveSellOrder, setMyActiveSellOrder] = useState(false);
  const [myActiveSellOrderData, setMyActiveSellOrderData] = useState([]);
  const [myCompletedSellOrder, setMyCompletedSellOrder] = useState(false);
  const [myCompletedSellOrderData, setMyCompletedSellOrderData] = useState([]);
  const [InExtype, setInExtype] = useState("exclude");
  const [refresh, setRefresh] = useState(false);
  const [isEditModelOpen, setIsEditModelOpen] = useState(false);
  const [isEditModelOpenSell, setIsEditModelOpenSell] = useState(false);
  const [editPrice, setEditPrice] = useState("");
  const [editAmount, setEditAmount] = useState("");
  const [themeRefresh, setThemeRefresh] = useState(false);
  const [fav_first_coin, setfav_first_coin] = useState("");
  const [fav_second_coin, setfav_second_coin] = useState("");
  const [buyPercentage, setBuyPercentage] = useState("");
  const [portfolioData, setPortfolioData] = useState("");
  const [livPrice, setLivPrice] = useState("0:00");
  const token = localStorage.getItem("token");
  const streams = ["@ticker", "@depth20", "@trade"];
  const tradesCount = 10;

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const closeEditModel = () => {
    setIsEditModelOpen(false);
    setIsEditModelOpenSell(false);
    setTimeout(() => {
      setDisabledBuuButtn(false);
      setDisabledSellButtn(false);
    }, 1500);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/cancle-back", {}, aheader).then((res) => {
      const resp = res.data;
      if (resp.status === true) {
        console.log("order removed and reverted");
      }
    });
  };

  const onEditOrder = (e) => {
    if (secondCoinBalance == 0 || secondCoinBalance == null) {
      setInExtype("include");
    }
    const { name, value } = e.target;
    if (name === "editPrice") {
      const numberValue = parseFloat(value);
      if (!isNaN(numberValue)) {
        // Value is a valid number
        if (numberValue === Math.floor(numberValue)) {
          if (value.length < 9) {
            setBuyPrice(numberValue);
          }
        } else {
          if (value.toString().split(".")[1].length < 9) {
            setBuyPrice(numberValue);
          }
        }
      }
    }
    if (name === "editAmount") {
      setInExtype("exclude");
      setBuyAmount(value);
    }
  };

  const onEditOrderSell = (e) => {
    if (firstCoinBalance == 0 || firstCoinBalance == null) {
      setInExtype("include");
    }
    const { name, value } = e.target;
    if (name === "editPrice") {
      setSellPrice(value);
    }
    if (name === "editAmount") {
      setSellAmount(value);
    }
  };

  useEffect(() => {
    axios
      .get(baseUrl + "/frontapi/crypto-data-by-tab")
      .then((res) => {
        setExchange_tab(res.data);
        // setSetActiveTab(res.data[0].short_name);
        // setSetUrl(res.data[0].short_name);
      })
      .catch();
  }, []);

  useEffect(() => {
    if (Object.keys(params).length === 0) {
      axios
        .get(baseUrl + "/frontapi/get-open-pair")
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            const pairName = `/${res.data.data[0].first_name}/${res.data.data[0].second_name}`;
            window.location.href = `/exchange${pairName}`;
          }
        })
        .catch((err) => { });
    }
    startDataLoad();
    favourite();
  }, [params.firstCoin, params.secondCoin]);

  let favourite = () => {
    const Url = window.location.pathname.split("/");

    let sendCoinData = {
      firstCoin: Url[2],
      secondCoin: Url[3],
    };

    axios
      .post(baseUrl + "/frontapi/checkpair", sendCoinData, aheader)
      .then((respData) => {
        var apiResp = respData.data;
        var apiRespData = apiResp.data;
        if (apiRespData.pairId) {
          let sendCoinPair = {
            pair_id: apiRespData.pairId,
          };
          axios
            .post(baseUrl + "/frontapi/checkpairexchange", sendCoinPair,aheader)
            .then((respData) => {
              var apiExchangeResp = respData.data;
              if (apiExchangeResp.success === true) {
                setExchange(apiExchangeResp.data.exchange);
                localStorage.setItem("exType", apiExchangeResp.data.exchange);
              } else {
                localStorage.setItem("exType", "notExchange");
                setExchange("");
              }
            });
          if (localStorage.getItem("token")) {
            const config = {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            };

            axios
              .post(baseUrl + "/frontapi/check-favcoin", sendCoinPair, aheader)
              .then((respData) => {
                var resp = respData.data;
                setIsActive(resp.data);
              });
          }
        }
      });
  };

  const _connectSocketStreams = (streams) => {
    let newArr = [];
    // Close the previous WebSocket connection
    if (binanceWebsocket.current) {
      binanceWebsocket.current.close();
    }

    props.dispatch({
      type: "SET_TRADES",
      data: [],
    });
    props.dispatch({
      type: "SET_DEPTH",
      data: [],
    });
    props.dispatch({
      type: "SET_TICKER",
      data: [],
    });
    setLivPrice("0:00");
    try {
      streams = streams.join("/");
      // Create a new WebSocket connection
      binanceWebsocket.current = new WebSocket(
        `wss://stream.binance.com:9443/stream?streams=${streams}`
      );
      binanceWebsocket.current.onmessage = async (evt) => {
        const eventData = JSON.parse(evt.data);
        if (eventData.stream.endsWith("@ticker")) {
          const obj = eventData.data;
          setLivPrice(obj.c);
          props.dispatch({
            type: "SET_TICKER",
            data: obj,
          });
          setTopBarStatus(true);
        }

        if (eventData.stream.endsWith("@trade")) {
          const mainValue = eventData.data;
          const data = await secondConstructor(mainValue);
          newArr.push(data);
          if (newArr.length > 10) {
            newArr.shift();
          }
          const binanceTrades = { binanceTrades: newArr };
          const trades = binanceTrades.binanceTrades;
          props.dispatch({
            type: "SET_TRADES",
            data: trades,
          });
        }

        if (eventData.stream.endsWith("@depth20")) {
          props.dispatch({
            type: "SET_DEPTH",
            data: eventData.data,
          });
        }
      };

      binanceWebsocket.current.onerror = (evt) => {
        console.error(evt);
      };
    } catch (error) {
      console.log("err2", error);
    }
  };

  const secondConstructor = async (obj) => {
    return {
      cost: obj.p,
      amount: obj.q,
      timestamp: obj.T,
    };
  };

  // useEffect(() => {
  //   var theme = localStorage.getItem("theme");
  //   if (Object.keys(params).length === 0) {
  //   } else {
  //     if (exchange !== "binance") {
  //       var pairName = params.firstCoin + params.secondCoin;
  //       var widget = (window.tvWidget = new window.TradingView.widget({
  //         fullscreen: false,
  //         symbol: pairName,
  //         interval: "1D",
  //         container: "tv_chart_container",
  //         height: 300,
  //         width: "100%",

  //         //	BEWARE: no trailing slash is expected in feed URL
  //         datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
  //           baseUrl + "/frontapi/"
  //         ),
  //         library_path: "/charting_library/",
  //         locale: "en",

  //         disabled_features: ["use_localstorage_for_settings"],
  //         enabled_features: ["study_templates"],
  //         charts_storage_url: "https://saveload.tradingview.com",
  //         charts_storage_api_version: "1.1",
  //         client_id: "tradingview.com",
  //         user_id: "public_user_id",
  //         theme: theme != "true" ? "light" : "dark",
  //         overrides: {
  //           "mainSeriesProperties.style": 8,
  //         },
  //       }));
  //     }
  //   }
  // }, [params.firstCoin, params.secondCoin, themeRefresh, exchange]);

  useEffect(() => {
    console.log("ppp ",params);
    if(params.firstCoin != undefined) {
    const firstCoinName = params.firstCoin;
    const secondCoinName = params.secondCoin;
    console.log(firstCoinName," ppp ",secondCoinName);
    const pair = firstCoinName + secondCoinName;
    console.log("ppp=> ",pair);
    const symbol = pair.toLowerCase();
    if (exchange === "binance") {
      _connectSocketStreams(streams.map((i) => `${symbol}${i}`));
    }
    const connectSocketStream = () => {
      if (!clientRef.current) {
        clientRef.current = new W3CWebSocket(wsUrl);

        clientRef.current.onopen = () => {
          console.log("WebSocket connection established.");
          subscribeToStreams(params.firstCoin, params.secondCoin);
          const token = localStorage.getItem("token");
          if (token) {
            subscribeToUserStreams(token, params.firstCoin, params.secondCoin);
          }
        };

        clientRef.current.onclose = (event) => {
          if (window.location.pathname.includes("/exchange")) {
            console.log("WebSocket connection closed.");
            clientRef.current = null;
            setTimeout(() => {
              connectSocketStream();
            }, 5000);
          }
        };
      }
    };

    if (!clientRef.current) {
      connectSocketStream();
    }
    return () => {
      if (
        clientRef.current &&
        !window.location.pathname.includes("/exchange")
      ) {
        console.log("WebSocket connection will be closed on unmount.");
        clientRef.current.close();
      }
    };
  }
  }, [params, exchange]);

  useEffect(() => {
    // if (exchange !== "binance") {
    clientRef.current.onmessage = (messageData) => {
      const dataFromServer = JSON.parse(messageData.data);
      if (dataFromServer.method === "SUBSCRIBE") {
        const { paramsB, data, serverFirstCoin, serverSecondCoin } =
          dataFromServer;
        // if (Array.isArray(paramsB)) {
        //   if (
        //     paramsB.includes(
        //       `marketDepth_${params.firstCoin}_${params.secondCoin}`
        //     )
        //   ) {
        //     const peningOrders = data;
        //     const activeBuyOrderData = peningOrders.buyOrderData;
        //     const activeSellOrderData = peningOrders.sellOrderData;
        //     if (activeBuyOrderData.status) {
        //       setActiveBuyOrder(activeBuyOrderData.status);
        //       setActiveBuyOrderData(activeBuyOrderData.data);
        //     } else {
        //       setActiveBuyOrder(myActiveBuyOrderData.status);
        //       setActiveBuyOrderData([]);
        //     }
        //     if (activeSellOrderData.status) {
        //       setActiveSellOrder(activeSellOrderData.status);
        //       setActiveSellOrderData(activeSellOrderData.data);
        //     } else {
        //       setActiveSellOrder(myActiveSellOrderData.status);
        //       setActiveSellOrderData([]);
        //     }
        //     // setActiveBuyOrder(data.buyOrderData.status);
        //     // setActiveBuyOrderData(data.buyOrderData.data);
        //     // setActiveSellOrder(data.sellOrderData.status);
        //     // setActiveSellOrderData(data.sellOrderData.data);
        //   }
        //   if (paramsB.includes("pendingOrders")) {
        //     const myPeningOrders = data;
        //     const myActiveBuyOrderData = myPeningOrders.buyOrderData;
        //     const myActiveSellOrderData = myPeningOrders.sellOrderData;
        //     console.log("myActiveBuyOrderData.data", myActiveBuyOrderData.data);
        //     if (myActiveBuyOrderData.status) {
        //       setMyActiveBuyOrder(myActiveBuyOrderData.status);
        //       setMyActiveBuyOrderData(myActiveBuyOrderData.data);
        //     } else {
        //       setMyActiveBuyOrder(myActiveBuyOrderData.status);
        //       setMyActiveBuyOrderData([]);
        //     }
        //     if (myActiveSellOrderData.status) {
        //       setMyActiveSellOrder(myActiveSellOrderData.status);
        //       setMyActiveSellOrderData(myActiveSellOrderData.data);
        //     } else {
        //       setMyActiveSellOrder(myActiveSellOrderData.status);
        //       setMyActiveSellOrderData([]);
        //     }
        //   }
        //   if (paramsB.includes("completeOrders")) {
        //     setMyCompletedBuyOrder(data.buyOrderData.status);
        //     setMyCompletedBuyOrderData(data.buyOrderData.data);
        //     setMyCompletedSellOrder(data.sellOrderData.status);
        //     setMyCompletedSellOrderData(data.sellOrderData.data);
        //   }
        //   if (
        //     paramsB.includes(
        //       `orderBook_${params.firstCoin}_${params.secondCoin}`
        //     )
        //   ) {
        //     setMarketTrade(true);
        //     setMarketTradeData(data.data);
        //   }
        //   if (
        //     paramsB.includes(
        //       `topBar_${params.firstCoin}_${params.secondCoin}`
        //     ) &&
        //     data.status
        //   ) {
        //     setTopBarApiData(data.data);
        //     // setTopBarApiStatus(true);
        //     setTopBarStatus(true);
        //   }
        //   if (paramsB.includes("coinPairList")) {
        //     setCoinListData(data.data);
        //     setCoinListStatus(data.status);
        //   }
        //   if (paramsB.includes("userBalanceByPair")) {
        //     setFirstCoinBalance(data.firstCoinBalance);
        //     console.log("data.secondCoinBalance", data.secondCoinBalance);
        //     setSecondCoinBalance(data.secondCoinBalance);
        //   }
        //   if (paramsB.includes("mySpotBalance")) {
        //     setMySpotBalance(data);
        //   }
        // }
        // console.log(
        //   "serverResu",
        //   serverFirstCoin === params.firstCoin &&
        //     serverSecondCoin === params.secondCoin
        // );
        if (paramsB.includes("allData")) {
          if (
            serverFirstCoin === params.firstCoin &&
            serverSecondCoin === params.secondCoin
          ) {
            const realTimePortfolio = data.userRealTimePortfolio;
            const myPeningOrders = data.userPendingOrders;
            const myActiveBuyOrderData = myPeningOrders.buyOrderData;
            const myActiveSellOrderData = myPeningOrders.sellOrderData;
            const myCompletedOrders = data.userCompleteOrders;
            const myCompleteBuyOrderData = myCompletedOrders.buyOrderData;
            const myCompleteSellOrderData = myCompletedOrders.sellOrderData;
            const myUserBalance = data.userBalanceByPair;
            const topBarData = data.topBarData;
            const coinListData = data.coinPairList;
            const userSpotBal = data.mySpotBalance;
            const orderBook = data.orderBookData;
            const peningOrders = data.marketDepthData;
            const activeBuyOrderData = peningOrders.buyOrderData;
            const activeSellOrderData = peningOrders.sellOrderData;
            if (myActiveBuyOrderData.status) {
              setMyActiveBuyOrder(myActiveBuyOrderData.status);
              setMyActiveBuyOrderData(myActiveBuyOrderData.data);
            } else {
              setMyActiveBuyOrder(myActiveBuyOrderData.status);
              setMyActiveBuyOrderData([]);
            }
            if (myActiveSellOrderData.status) {
              setMyActiveSellOrder(myActiveSellOrderData.status);
              setMyActiveSellOrderData(myActiveSellOrderData.data);
            } else {
              setMyActiveSellOrder(myActiveSellOrderData.status);
              setMyActiveSellOrderData([]);
            }
            if (activeBuyOrderData.status) {
              setActiveBuyOrder(activeBuyOrderData.status);
              setActiveBuyOrderData(activeBuyOrderData.data);
            } else {
              setActiveBuyOrder(myActiveBuyOrderData.status);
              setActiveBuyOrderData([]);
            }
            if (activeSellOrderData.status) {
              setActiveSellOrder(activeSellOrderData.status);
              setActiveSellOrderData(activeSellOrderData.data);
            } else {
              setActiveSellOrder(myActiveSellOrderData.status);
              setActiveSellOrderData([]);
            }
            setMyCompletedBuyOrder(myCompleteBuyOrderData.status);
            setMyCompletedBuyOrderData(myCompleteBuyOrderData.data);
            setMyCompletedSellOrder(myCompleteSellOrderData.status);
            setMyCompletedSellOrderData(myCompleteSellOrderData.data);
            setTopBarApiData(topBarData.data);
            setTopBarStatus(topBarData.status);
            setFirstCoinBalance(myUserBalance.firstCoinBalance);
            setSecondCoinBalance(myUserBalance.secondCoinBalance);
            setCoinListData(coinListData.data);
            setCoinListStatus(coinListData.status);
            setMySpotBalance(userSpotBal);
            setMarketTrade(orderBook.status);
            setMarketTradeData(orderBook.data);
            setPortfolioData(realTimePortfolio);
          }
        }
      }
    };
    // }
  }, [params]);

  const subscribeToStreams = (firstCoin, secondCoin) => {
    const subscribeMessage = {
      method: "SUBSCRIBE",
      params: [
        "marketDepth",
        "orderBook",
        "topBar",
        "coinPairList",
        // ... add other streams ...
      ],
      firstCoin: firstCoin,
      secondCoin: secondCoin,
      token: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : null,
    };
    clientRef.current.send(JSON.stringify(subscribeMessage));
  };

  const subscribeToUserStreams = (token, firstCoin, secondCoin) => {
    const subscribePendingOrders = {
      firstCoin: firstCoin,
      secondCoin: secondCoin,
      token,
      method: "SUBSCRIBE",
      params: ["pendingOrders"],
    };
    const subscribeCompleteOrders = {
      firstCoin: firstCoin,
      secondCoin: secondCoin,
      token,
      method: "SUBSCRIBE",
      params: ["completedOrders"],
    };
    const subscribeUserSpotBalance = {
      firstCoin: firstCoin,
      secondCoin: secondCoin,
      token,
      method: "SUBSCRIBE",
      params: ["mySpotBalance"],
    };
    const subscribeUserBalanceByPair = {
      token,
      method: "SUBSCRIBE",
      params: ["userBalanceByPair"],
      firstCoin: firstCoin,
      secondCoin: secondCoin,
    };
    const subscribeCoinPair = {
      token,
      method: "SUBSCRIBE",
      params: ["coinPairList"],
      firstCoin: firstCoin,
      secondCoin: secondCoin,
    };
    clientRef.current.send(JSON.stringify(subscribePendingOrders));
    clientRef.current.send(JSON.stringify(subscribeUserBalanceByPair));
    clientRef.current.send(JSON.stringify(subscribeCompleteOrders));
    clientRef.current.send(JSON.stringify(subscribeUserSpotBalance));
    clientRef.current.send(JSON.stringify(subscribeCoinPair));
  };

  const startDataLoad = () => {
    setFirstCoin(params.firstCoin);
    setSecondCoin(params.secondCoin);
    setFirstCoinBalanc(0);
    setSetUrl(params.secondCoin);
    setSecondCoinBalance(0);
    getPairDetails();
    getCoinPairList();
  };

  const getCoinPairList = () => {
    const userData = {
      userId: "0",
    };
    axios.post(baseUrl + "/frontapi/coin_pair_list", userData,aheader).then((res) => {
      const resp = res.data;
    });
  };

  const getPairDetails = () => {
    let sendData = {
      firstCoin: params.firstCoin,
      secondCoin: params.secondCoin,
    };
    axios.post(baseUrl + "/frontapi/checkpair", sendData, aheader).then((respData) => {
      var apiResp = respData.data;
      if (apiResp.success === false) {
      }
      var apiRespData = apiResp.data;
      setFirstCoinId(apiRespData.firstCoinId);
      setSecondCoinId(apiRespData.secondCoinId);
      setBuyPrice(apiRespData.currentPairPrice);
      setCurrentPrice(apiRespData.currentPairPrice);
      setSellPrice(apiRespData.currentPairPrice);
      setBuy_commission(apiRespData.commission);
      setSell_commission(apiRespData.sell_commission);
      setfav_first_coin(apiRespData.firstCoinId);
      setfav_second_coin(apiRespData.secondCoinId);
      //   this.setState({
      //     firstCoinId: apiRespData.firstCoinId,
      //     secondCoinId: apiRespData.secondCoinId,
      //     currentPrice: apiRespData.currentPairPrice,
      //     buyPrice: apiRespData.currentPairPrice,
      //     sellPrice: apiRespData.currentPairPrice,
      //     buy_commission: apiRespData.commission,
      //     sell_commission: apiRespData.sell_commission,
      //     fav_first_coin: apiRespData.firstCoinId,
      //     fav_second_coin: apiRespData.secondCoinId,
      //   });
      getUserDetails();
    });
  };

  const getUserDetails = () => {
    const sessionId = localStorage.getItem("x-session-id");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        // "X-Session-ID": sessionId,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, aheader_form).then((resp) => {
      var resp = resp.data;
      var userId = 0;
      var userName = "Guest";
      if (resp.status === true) {
        userId = resp.data[0].id;
        // this.setState({
        //   userId: resp.data[0].id,
        //   userName: resp.data[0].email,
        // });
      } else {
        if (
          resp.key === "logout" &&
          localStorage.getItem("token") !== "null" &&
          localStorage.getItem("token") !== null
        ) {
          // toast.error(resp.message);
          localStorage.clear();
          setTimeout(
            () => (window.location.href = "/login-to-buy-sell-crypto"),
            2000
          );
        }
        // this.setState({ userId: 0, userName: userName });
      }
    });
  };

  const dataOnClick = (firstCoin, secondCoin) => {
    if (exchange === "binance") {
      if (binanceWebsocket.current) {
        binanceWebsocket.current.close();
      }
      let data = firstCoin + secondCoin;
      let symbol = data.toLowerCase();
      _connectSocketStreams(streams.map((i) => `${symbol}${i}`));
    }

    if (params.firstCoin !== firstCoin) {
      const subscribeMessage = {
        method: "UNSUBSCRIBE",
        params: [
          "marketDepth",
          "orderBook",
          "topBar",
          "coinPairList",
          // ... add other streams ...
        ],
        firstCoin: params.firstCoin,
        secondCoin: params.secondCoin,
        token: localStorage.getItem("token")
          ? localStorage.getItem("token")
          : null,
      };
      clientRef.current.send(JSON.stringify(subscribeMessage));
      setRefresh(!refresh);
      // setTimeout(() => {

      subscribeToStreams(firstCoin, secondCoin);
      // }, 1000);
      // const token = localStorage.getItem("token");
      // if (token) {
      //   subscribeToUserStreams(token, firstCoin, secondCoin);
      // }
    }
  };

  const changeTheme = () => {
    setThemeRefresh(!themeRefresh);
  };

  const favListApi = (v) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let sendData = {
      firstCoin: fav_first_coin,
      secondCoin: fav_second_coin,
    };
    axios
      .post(baseUrl + "/frontapi/addFavCoin", sendData, aheader)
      .then((respData) => {
        var apiResp = respData.data;
        linkPairClick();
        if (apiResp.status === true) {
          toast.success(apiResp.message);
          getPairDetails();
          if (setActiveTab === "fav") {
            activeCurrency("fav");
          }
        } else {
          toast.error(apiResp.message);
          // setState({ coin: 1 });
          getPairDetails();
        }
      });
  };
  const linkPairClick = () => {
    const Url = window.location.pathname.split("/");

    let sendCoinData = {
      firstCoin: Url[2],
      secondCoin: Url[3],
    };

    axios
      .post(baseUrl + "/frontapi/checkpair", sendCoinData, aheader)
      .then((respData) => {
        var apiResp = respData.data;

        if (apiResp.success === false) {
          //this.props.history.push('/')
        }

        var apiRespData = apiResp.data;

        if (apiRespData.pairId) {
          let sendCoinPair = {
            pair_id: apiRespData.pairId,
          };
          axios
            .post(baseUrl + "/frontapi/checkpairexchange", sendCoinPair, aheader)
            .then((respData) => {
              var apiExchangeResp = respData.data;
              if (apiExchangeResp) {
                // this.setState({
                //   exchange: apiExchangeResp.data.exchange,
                // });
              }
            });
            if (localStorage.getItem("token")) {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };

          axios
            .post(baseUrl + "/frontapi/check-favcoin", sendCoinPair, aheader)
            .then((respData) => {
              var resp = respData.data;
              setIsActive(resp.data);
            });
          }
        }
      });
  };
  const topBarHtml = () => {
    if (topBarStatus !== false) {
      if (exchange === "binance") {
        return (
          <ul>
            <Ticker {...props.ticker} />
          </ul>
        );
      } else {
        const topBarData = topBarApiData;

        var collectHtml = (
          <ul>
            <li>
              <h4 className="ng-binding">Last Price</h4>
              <strong className="ng-binding">
                {secondCoin === "INR" ? (
                  <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
                ) : (
                  <span></span>
                )}
                {topBarData.currentPrice > 1
                  ? topBarData.currentPrice.toLocaleString()
                  : topBarData.currentPrice}
              </strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
            <li>
              <h4 className="ng-binding">24h-Change</h4>
              {(topBarData.oneDayMaxPrice === null &&
                topBarData.oneDayMinPrice === null) ||
                topBarData.oneDayMaxPrice === topBarData.oneDayMinPrice ? (
                <>
                  <strong className="changeRate ng-binding ng-scope green">
                    {"0.00"}
                  </strong>
                </>
              ) : (
                <>
                  {topBarData.changePriceAmount == null ? (
                    <strong className="changeRate ng-binding ng-scope green">
                      {topBarData.changePriceAmount}
                    </strong>
                  ) : (
                    <strong className="changeRate ng-binding ng-scope green">
                      {topBarData.changePriceAmount.toFixed(2)}
                    </strong>
                  )}
                </>
              )}
              {(topBarData.oneDayMaxPrice === null &&
                topBarData.oneDayMinPrice === null) ||
                topBarData.oneDayMaxPrice === topBarData.oneDayMinPrice ? (
                <>
                  <strong className="changeRate ng-binding ng-scope green">
                    {" "}
                    {0}%
                  </strong>
                </>
              ) : (
                <>
                  {topBarData.changePricePercent == null ||
                    topBarData.changePricePercent.toFixed(2) == null ? (
                    <strong className="changeRate ng-binding ng-scope green">
                      {topBarData.changePricePercent}%
                    </strong>
                  ) : (
                    <strong>
                      {" "}
                      ({topBarData.changePricePercent.toFixed(2)}%)
                    </strong>
                  )}
                </>
              )}
            </li>
            {/* <li>
              <h4 className="ng-binding">24h-High</h4>
              <strong className="ng-binding">
                {topBarData.oneDayMaxPrice == null
                  ? topBarData.currentPrice > 1
                    ? topBarData.currentPrice.toLocaleString()
                    : topBarData.currentPrice
                  : topBarData.oneDayMaxPrice}
              </strong>
            </li> */}
            <li>
              <h4 className="ng-binding">24h-High</h4>
              <strong className="ng-binding">
                {topBarData.oneDayMaxPrice == null
                  ? topBarData.currentPrice > 1
                    ? topBarData.currentPrice.toLocaleString()
                    : topBarData.currentPrice
                  : topBarData.oneDayMaxPrice}
              </strong>
            </li>
            <li>
              <h4 className="ng-binding">24h-Low</h4>
              <strong className="ng-binding">
                {topBarData.oneDayMinPrice == null
                  ? topBarData.currentPrice > 1
                    ? topBarData.currentPrice.toLocaleString()
                    : topBarData.currentPrice
                  : topBarData.oneDayMinPrice}
              </strong>
            </li>
            <li>
              <h4 className="ng-binding">24h Volume</h4>
              <strong className="ng-binding">
                {topBarData.oneDayVolume == null
                  ? "0.00"
                  : topBarData.oneDayVolume}
              </strong>
            </li>
          </ul>
        );
      }

      return <ul>{collectHtml}</ul>;
    } else {
      collectHtml = (
        <ul>
          <li>
            <h4 className="ng-binding">Last Price</h4>
            <strong className="ng-binding">
              {secondCoin === "INR" ? (
                <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
              ) : (
                <span></span>
              )}
              {0.0}
            </strong>
            <strong className="transMoney ng-binding ng-scope"></strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-Change</h4>
            <strong className="changeRate ng-binding ng-scope green">
              0.00
            </strong>{" "}
            <strong className="changeRate ng-binding ng-scope green">
              (0.00)%
            </strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-High</h4>
            <strong className="ng-binding">0.00</strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-Low</h4>
            <strong className="ng-binding">0.00</strong>
          </li>
          <li>
            <h4 className="ng-binding">24h Volume</h4>
            <strong className="ng-binding">0.00</strong>
          </li>
        </ul>
      );
      return <ul>{collectHtml}</ul>;
    }
  };

  const activeCurrency = (value) => {
    localStorage.setItem("setUrl", value);
    // setSecondCoin(value);
    setSetUrl(value);
    setBuyAmount(0);
    setSellAmount(0);
    setSell_order_total(0);
    setBuy_order_total(0);
    setSetActiveTab(value);
    // this.setState({
    //   setActiveTab: value,
    //   secondCoin: value,
    //   setUrl: value,
    //   secondCoin: this.props.match.params.secondCoin,
    //   buyAmount: 0,
    //   buy_order_total_write: 0,
    //   buy_order_total: 0,
    // });
  };

  const onSearch = (event) => {
    setSearchedValue(event.target.value);
  };

  const EmptySearch = () => {
    setSearchedValue("");
  };

  const coinListHtml = (coinListData) => {
    var listData = [];
    if (coinListData.length === 0 && cryptoListStatus === true) {
      listData = cryptoList;
    }
    if (coinListStatus === true && coinListData.length > 0) {
      listData = coinListData;
    }
    const filteredItems = listData.filter((item) =>
      item.first_coin_name.includes(searchedValue.toUpperCase())
    );
    const disending = filteredItems?.sort((a, b) =>
      a.first_coin_name > b.first_coin_name ? 1 : -1
    );

    return disending.map((item, index) => {
      var percentShow =
        ((parseFloat(item.current_price) -
          parseFloat(item.oneday_before_price)) *
          100) /
        item.oneday_before_price;
      percentShow = percentShow.toFixed(2);
      var active_currency = false;
      var showActive =
        firstCoin === item.first_coin_name &&
        secondCoin === item.second_coin_name;
      active_currency = "currency_" + item.second_coin_name;
      let checkClass = "ng-scope show_tab " + active_currency;
      if (showActive) {
        checkClass = "active " + "ng-scope " + "show_tab " + active_currency;
      }
      checkClass = "ng-scope " + "show_tab ";
      if (setActiveTab == "fav" && item.activeStatus == "YES") {
        return (
          <tr
            key={index}
            className={showActive ? "ng-scope active" : "ng-scope"}
          >
            {exchange == "binance" ? (
              <a
                href={
                  "/exchange/" +
                  item.first_coin_name +
                  "/" +
                  item.second_coin_name
                }
                onClick={() =>
                  dataOnClick(item.first_coin_name, item.second_coin_name)
                }
              >
                <td className="r-market-pair ng-binding fw600">
                  {!item.icon ? (
                    <img
                      className="currency_icon"
                      src={baseUrl + "/static/currencyImage/" + item.icon}
                      alt=""
                    />
                  ) : (
                    <img
                      className="currency_icon"
                      src="/assets/img/btc.png"
                      alt=""
                    />
                  )}
                  {item.first_coin_name}
                  <small>/{item.second_coin_name}</small>
                </td>
                <td className="r-market-price ng-binding fw600">
                  {item.current_price}
                </td>
                <td className="r-market-rate ng-scope tr">
                  <span
                    className="ng-binding ng-scope green"
                    style={
                      percentShow < 0 ? { color: "red" } : { color: "green" }
                    }
                  >
                    {percentShow ? percentShow : 0}%
                  </span>
                </td>
              </a>
            ) : (
              <Link
                to={
                  "/exchange/" +
                  item.first_coin_name +
                  "/" +
                  item.second_coin_name
                }
                onClick={() =>
                  dataOnClick(
                    item.first_coin_name,
                    item.second_coin_name,
                    item.coin_first_id,
                    item.coin_second_id
                  )
                }
              >
                <td className="r-market-pair ng-binding fw600">
                  {item.icon ? (
                    <img
                      className="currency_icon"
                      src={baseUrl + "/static/currencyImage/" + item.icon}
                      alt=""
                    />
                  ) : (
                    <img
                      className="currency_icon"
                      src="/assets/img/btc.png"
                      alt=""
                    />
                  )}
                  {item.first_coin_name}
                  <small>/{item.second_coin_name}</small>
                </td>
                <td className="r-market-price ng-binding fw600">
                  {item.current_price}
                </td>
                <td className="r-market-rate ng-scope tr">
                  <span
                    className="ng-binding ng-scope green"
                    style={
                      percentShow < 0 ? { color: "red" } : { color: "green" }
                    }
                  >
                    {percentShow ? percentShow : 0}%
                  </span>
                </td>
              </Link>
            )}
          </tr>
        );
      }
      if (item.second_coin_name == setActiveTab) {
        return (
          <tr
            key={index}
            className={showActive ? "ng-scope active" : "ng-scope"}
          >
            {exchange == "binance" ? (
              <a
                href={
                  "/exchange/" +
                  item.first_coin_name +
                  "/" +
                  item.second_coin_name
                }
                onClick={() =>
                  dataOnClick(
                    item.first_coin_name,
                    item.second_coin_name,
                    item.coin_first_id,
                    item.coin_second_id
                  )
                }
              >
                <td className="r-market-pair ng-binding fw600">
                  {item.icon ? (
                    <img
                      className="currency_icon"
                      src={baseUrl + "/static/currencyImage/" + item.icon}
                      alt=""
                    />
                  ) : (
                    <img
                      className="currency_icon"
                      src="/assets/img/btc.png"
                      alt=""
                    />
                  )}
                  {item.first_coin_name}
                  <small>/{item.second_coin_name}</small>
                </td>
                <td className="r-market-price ng-binding fw600">
                  {item.current_price}
                </td>
                <td className="r-market-rate ng-scope tr">
                  <span
                    className="ng-binding ng-scope green"
                    style={
                      percentShow < 0
                        ? { color: "#ff505d" }
                        : { color: "#00bc8b" }
                    }
                  >
                    {percentShow ? percentShow : 0}%
                  </span>
                </td>
              </a>
            ) : (
              <Link
                to={
                  "/exchange/" +
                  item.first_coin_name +
                  "/" +
                  item.second_coin_name
                }
                onClick={() =>
                  dataOnClick(
                    item.first_coin_name,
                    item.second_coin_name,
                    item.coin_first_id,
                    item.coin_second_id
                  )
                }
              >
                <td className="r-market-pair ng-binding fw600">
                  {item.icon ? (
                    <img
                      className="currency_icon"
                      src={baseUrl + "/static/currencyImage/" + item.icon}
                      alt=""
                    />
                  ) : (
                    <img
                      className="currency_icon"
                      src="/assets/img/btc.png"
                      alt=""
                    />
                  )}
                  {item.first_coin_name}
                  <small>/{item.second_coin_name}</small>
                </td>
                <td className="r-market-price ng-binding fw600">
                  {item.current_price ? item.current_price : 0}
                </td>
                <td className="r-market-rate ng-scope tr">
                  <span
                    className="ng-binding ng-scope green"
                    style={
                      percentShow < 0
                        ? { color: "#ff505d" }
                        : { color: "#00bc8b" }
                    }
                  >
                    {isNaN(percentShow) ? "0.00" : percentShow}%
                  </span>
                </td>
              </Link>
            )}
          </tr>
        );
      }
    });
  };

  const showSpotBalance = () => {
    if (mySpotBalance.length > 0) {
      const html = [];
      mySpotBalance.length > 0 &&
        mySpotBalance.map(function (value, i) {
          if (
            value.spot[0].coin_amount === null ||
            value.spot[0].coin_amount === "null"
          ) {
            var spotBalace = 0;
          } else {
            spotBalace = value.spot[0].coin_amount;
          }
          if (spotBalace > 0) {
            html.push(
              <tr key={i}>
                <td>{value.name}</td>
                <td>{spotBalace}</td>
              </tr>
            );
          }
        });
      return <tbody className="main">{html}</tbody>;
    }
  };

  const openTradingChart = (symbol) => {
    let theme;
    if (localStorage.getItem("theme") === "true") {
      theme = Themes.DARK;
    } else {
      theme = Themes.LIGHT;
    }
    return (
      <TradingViewWidget
        symbol={"BINANCE:" + symbol}
        theme={theme}
        locale="en"
        autosize={true}
        details={false}
        BarStyles={BarStyles.HEIKIN_ASHI}
      />
    );
  };

  const removeTrailingZeros = (decimalNumber) => {
    // Convert the decimal number to a string
    let numberStr = decimalNumber.toString();

    // Use a regular expression to remove trailing zeros
    numberStr = numberStr.replace(/\.?0*$/, "");

    // Convert the modified string back to a number
    const result = parseFloat(numberStr).toFixed(8);

    return result;
  };
  const removeTrailingZerosT = (decimalNumber) => {
    // Convert the decimal number to a string
    let numberStr = decimalNumber.toString();

    // Use a regular expression to remove trailing zeros
    numberStr = numberStr.replace(/\.?0*$/, "");

    // Convert the modified string back to a number
    const result = parseFloat(numberStr);

    return result;
  };

  const setStopChanges = (e, data) => {
    e.preventDefault();
    if (data === "stop") {
      setStopLimit(true);
      setDisabledButton(false);
      setMarket(false);
      setLimit(false);
      setBuyAmount("");
      setBuy_order_total("");
      setFeeAmyt("");
      setSellAmount("");
      setSell_order_total("");
      setSellFeeAmyt("");
    }
    if (data === "market") {
      if (isOrderEdit === true) {
        if (buyAmount > 0) {
          if (
            buy_commission !== null &&
            isFeeApply !== "Y" &&
            isFeeEnable !== "1"
          ) {
            var commission = (buyAmount * buy_commission) / 100;
            var buy_value = parseFloat(buyAmount) + parseFloat(commission);
          } else {
            buy_value = buyAmount;
          }
          const buyOrderTotalWithFee =
            parseFloat(buy_value) * parseFloat(currentPrice);
          var price = currentPrice * buyAmount;
          setBuy_order_total(price);
          setFeeAmyt(buyOrderTotalWithFee);
        }
        if (sellAmount > 0) {
          let commission =
            sellAmount *
            (100 /
              (100 +
                (isFeeApply !== "Y" && isFeeEnable !== "1"
                  ? sell_commission
                  : "")));
          let sell_value = commission;
          let sellOrderTotalWithFee =
            parseFloat(sell_value) * parseFloat(currentPrice);
          var sellPrice = currentPrice * sellAmount;
          setSell_order_total(sellPrice);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      } else {
        setBuyAmount("");
        setBuy_order_total("");
        setSellAmount("");
        setSell_order_total("");
        setFeeAmyt("");
        setSellFeeAmyt("");
      }
      setDisabledButton(true);
      setStopLimit(false);
      setMarket(true);
      setLimit(false);
    }
    if (data === "limit") {
      if (isOrderEdit === true) {
        if (
          buy_commission !== null &&
          isFeeApply !== "Y" &&
          isFeeEnable !== "1"
        ) {
          commission = (buyAmount * buy_commission) / 100;
          buy_value = parseFloat(buyAmount) + parseFloat(commission);
        } else {
          buy_value = buyAmount;
        }
        if (buyAmount > 0) {
          let buyOrderTotalWithFee =
            parseFloat(buy_value) * parseFloat(buyPrice);
          price = buyPrice * buyAmount;
          setBuy_order_total(price);
          setFeeAmyt(buyOrderTotalWithFee);
        }
        if (sellAmount > 0) {
          let commission =
            sellAmount *
            (100 /
              (100 +
                (isFeeApply !== "Y" && isFeeEnable !== "1"
                  ? sell_commission
                  : "")));
          let sell_value = commission;
          let sellOrderTotalWithFee =
            parseFloat(sell_value) * parseFloat(sellPrice);
          sellPrice = sellPrice * sellAmount;
          setSell_order_total(sellPrice);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      } else {
        setBuyAmount("");
        setBuy_order_total("");
        setSellAmount("");
        setSell_order_total("");
        setFeeAmyt("");
        setSellFeeAmyt("");
      }
      setDisabledButton(false);
      setStopLimit(false);
      setLimit(true);
      setMarket(false);
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleBuySubmit = (event) => {
    console.log("======> hs");
    // event.preventDefault();

    if (buyPrice < 0) {
      toast.error("Please enter valid amount");
      return false;
    }
    if (buyAmount < 0) {
      toast.error("Please enter valid amount");
      return false;
    }

    if (buyPrice <= 0) {
      toast.error("Please enter valid price");
      return false;
    }

    var price = "";
    if (limit === true && market === false) {
      price = buyPrice;
    } else if (market === true && limit === false) {
      price = currentPrice;
    }

    if (secondCoinBalance == 0 || secondCoinBalance <= 0) {
      return false;
    }
    let submitData;
    if (InExtype === "include") {
      submitData = {
        orderType: "buy",
        per_price: price,
        volume: buyAmount,
        firstCoinId: firstCoinId,
        secondCoinId: secondCoinId,
        orderId: order_id,
        feeAmyt: feeAmyt,
        orderMethod: orderMethod,
        type: InExtype,
        buy_commission: buy_commission,
        percentAmt: buyPercentage,
      };
    } else {
      submitData = {
        orderType: "buy",
        per_price: price,
        volume: buyAmount,
        firstCoinId: firstCoinId,
        secondCoinId: secondCoinId,
        orderId: order_id,
        feeAmyt: feeAmyt,
        orderMethod: orderMethod,
        type: InExtype,
        buy_commission: buy_commission,
      };
    }
    setDisabledBuuButtn(true);

    let endPoint;
    if (orderMethod == "limit") {
      endPoint = "/frontapi/create_order";
    } else if (orderMethod === "market") {
      endPoint = "/frontapi/create_order_market";
    }

    setTimeout(() => setDisabledBuuButtn(false), 500);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + endPoint, submitData, aheader).then((resp) => {
      const apiResp = resp.data;
      // subscribeToUserStreams(token, params.firstCoin, params.secondCoin);
      if (apiResp.success === false) {
        toast.error(apiResp.message);
        setBuyAmount(0);
        setBuy_order_total(0);
        setOrder_id("");
        setFeeAmyt(0);
        setBPBtn(0);
        if (apiResp.data == 1) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        // setDisabledBuuButtn(false);
        closeEditModel();
        // setTimeout(() => setDisabledBuuButtn(false), 500);
      } else {
        setIsEditModelOpen(false);
        toast.success(apiResp.message);
        setBuyAmount(0);
        setBuy_order_total(0);
        setOrder_id("");
        setFeeAmyt(0);
        setBPBtn(0);
        // setDisabledBuuButtn(false);
        // setTimeout(() => setDisabledBuuButtn(false), 500);
        // $(".my_color_buy").text("Buy");
      }
    });
  };

  const debouncedHandleBuySubmit = debounce(handleBuySubmit, 500);

  const handleBuyChange = (event) => {
    var ID = event.target.id;
    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });
    let { name, value } = event.target;
    setInExtype("exclude");
    var buyOrderTotal = "";
    if (name === "buy_order_total" && buyPrice > 0) {
      if (buy_commission !== null) {
        var commission = (value * buy_commission) / 100;
        var buy_value = parseFloat(value) + parseFloat(commission);
      } else {
        buy_value = value;
      }
    } else {
      if (buy_commission !== null) {
        commission = (value * buy_commission) / 100;
        buy_value = parseFloat(value) + parseFloat(commission);
      } else {
        buy_value = value;
      }
    }
    if (buyAmount == 0) {
      buyOrderTotal = (
        parseFloat(buy_value) * parseFloat(buy_order_total_write)
      ).toFixed(8);
      setBuy_order_total(buyOrderTotal);
    }
    if (name === "buyPrice") {
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length < 9) {
          setBuyPrice(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setBuyPrice(value);
        }
      }
    }
    if (name === "buyPrice" && buyAmount > 0) {
      let buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(buyAmount);
      let buy_price;
      if (number === Math.floor(number)) {
        if (value.length < 9) {
          setBuyPrice(value);
          buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
            8
          );
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setBuyPrice(value);
          buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
            8
          );
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
        }
      }
    } else if (name === "buyAmount" && buyPrice > 0) {
      let buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(buyPrice);
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length < 9) {
          buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(8);
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
          setBuyAmount(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(8);
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
          setBuyAmount(value);
        }
      }
    } else if (name === "buy_order_total" && buyPrice > 0) {
      let buyOrderTotal = (parseFloat(value) / parseFloat(buyPrice)).toFixed(8);
      let buyOrderTotalWithFee = parseFloat(buy_value);
      setBuy_order_total_write(buyOrderTotal);
      setBuyAmount(buyOrderTotal);
      setFeeAmyt(buyOrderTotalWithFee);
    }
  };

  const handleBuyPercent = (percentAmt) => {
    setBuyPercentage(percentAmt);
    var userBalance = secondCoinBalance;
    var percentAmts =
      (parseFloat(percentAmt).toFixed(8) * parseFloat(userBalance).toFixed(8)) /
      100;
    let Amount = percentAmts * (100 / (100 + buy_commission));
    let subTotal = Number(Amount).toFixed(8) / Number(buyPrice);
    setFeeAmyt(parseFloat(percentAmts).toFixed(8));
    setBuy_order_total(Amount.toFixed(8));
    setBuyAmount(subTotal.toFixed(8));
    setBPBtn(percentAmt);

    console.log(
      "percentAmts=>",
      percentAmts,
      "Amount=>",
      Amount,
      "subTotal=>",
      subTotal
    );

    // if (percentAmt == 100) {
    setInExtype("include");
    // } else {
    //   setInExtype("exclude");
    // }
  };

  const AuthAction = () => {
    if (token) {
      return (
        <button
          className="btn w100  my_color_buy"
          type="submit"
          disabled={disabledBuuButtn}
        >
          Buy
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border2 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">Sign Up</Link> or{" "}
            <Link to="/login-to-buy-sell-crypto">Login</Link>
          </div>
        </div>
      );
    }
  };

  const buyBoxHtml = () => {
    return (
      <div className="buy-btc-outer buy-sell-outer border1">
        <div className="orderforms-hd">
          <div>
            <label className="ng-binding">
              Buy {firstCoinNew != "" ? firstCoinNew : firstCoin}
            </label>
            <span className="f-fr ng-binding">
              {secondCoin} Balance:{" "}
              {secondCoinBalance
                ? Number.isInteger(secondCoinBalance)
                  ? secondCoinBalance
                  : removeTrailingZeros(
                    parseFloat(secondCoinBalance).toFixed(8)
                  )
                : 0}
            </span>
          </div>
        </div>
        {/* <ul className="type-list-outer d-flex justify-content-between">
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "limit");
                setOrderMethod("limit");
              }}
              href="#!"
              className={limit === true ? "active" : ""}
            >
              Limit
            </a>
          </li>
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "market");
                setOrderMethod("market");
              }}
              href="#!"
              className={market === true ? "active" : ""}
            >
              Market
            </a>
          </li>
          {/* <li>
              <a
                onClick={() => this.setStopChanges("stop")}
                href="javascript:voin(0)"
                className={stopLimit == true ? "active" : ""}
              >
                Stop Limit
              </a>
            </li> *
        </ul> */}
        <form
          name="buyForm"
          onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submission
            debouncedHandleBuySubmit(); // Call the debounced submit handler
          }}
          autoComplete="off"
          className="ng-pristine ng-invalid ng-invalid-required"
        >
          <div className="orderforms-inputs p-2">
            {stopLimit === true && (
              <div className="field f-cb stop-limit">
                <label className="ng-binding">Stop Limit: </label>
                <div className="iptwrap leftBig limit">
                  <input
                    type="number"
                    step="any"
                    value={stopPriceBuy}
                    onChange={handleBuyChange}
                    name="stopPriceBuy"
                    className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                  />

                  <span className="unit ng-binding">{secondCoin}</span>
                </div>
                <div className="cls"></div>
              </div>
            )}
            <div className="field f-cb">
              <label className="ng-binding">Price: </label>
              <div className="iptwrap leftBig">
                {orderMethod === "market" ? (
                  <>
                    <input
                      type="text"
                      step="any"
                      id="pric"
                      value="Market"
                      onChange={handleBuyChange}
                      name="buyPrice"
                      className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                      disabled={disabledButton}
                      onKeyPress={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="order_id"
                      value={order_id}
                      name="order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                ) : (
                  <>
                    <input
                      type={type}
                      step="any"
                      id="price_buy"
                      value={disabledButton === true ? currentPrice : buyPrice}
                      onChange={handleBuyChange}
                      name="buyPrice"
                      className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                      disabled={disabledButton}
                      onKeyPress={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="order_id"
                      value={order_id}
                      name="order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                )}

                {/*<span className="jiantou"><i className="fa fa-angle-up"></i><i className="fa fa-angle-down" aria-hidden="true" role="button" tabindex="0"></i></span>*/}
              </div>
              <div className="cls"></div>
            </div>
            <div className="field f-cb">
              <label className="ng-binding">Amount: </label>
              <div className="iptwrap">
                <input
                  type="number"
                  step="any"
                  name="buyAmount"
                  onChange={handleBuyChange}
                  value={buyAmount}
                  id="buyAmount"
                  aria-invalid="true"
                  onKeyPress={handleKeyPress}
                />
                <span className="unit ng-binding">{firstCoin}</span>
              </div>
              <div className="cls"></div>
            </div>
            <div className="field percent f-cb">
              <div className="field4">
                <span
                  className={`col ${bPBtn == 25 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(25)}
                >
                  25%
                </span>
                <span
                  className={`col ${bPBtn == 50 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(50)}
                >
                  50%
                </span>
                <span
                  className={`col ${bPBtn == 75 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(75)}
                >
                  75%
                </span>
                <span
                  className={`col ${bPBtn == 100 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(100)}
                >
                  100%
                </span>
              </div>
            </div>
            <div className="field f-cb">
              {orderMethod === "market" ? (
                ""
              ) : (
                <>
                  <label className="ng-binding">Total: </label>
                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      id="total_buy"
                      onChange={handleBuyChange}
                      name="buy_order_total"
                      value={buy_order_total}
                      disabled
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                    />
                    <span className="unit ng-binding">{secondCoin}</span>
                  </div>
                </>
              )}
              <div className="cls"></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  Trade Fee :{" "}
                  {buy_commission == null
                    ? 0
                    : Number.isInteger(buy_commission)
                      ? buy_commission
                      : removeTrailingZerosT(
                        parseFloat(buy_commission).toFixed(4)
                      )}
                  {buy_commission != null && <span>%</span>}
                </p>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  Net Amount : {feeAmyt ? parseFloat(feeAmyt).toFixed(8) : 0}
                </p>
              </div>
            </div>
            {AuthAction()}
          </div>
        </form>
      </div>
    );
  };

  const handleSellSubmit = (event) => {
    event.preventDefault();

    if (sellPrice < 0) {
      toast.error("Please enter valid amount");
      return false;
    }

    if (sellAmount < 0) {
      toast.error("Please enter valid amount");
      return false;
    }

    if (sellPrice <= 0) {
      toast.error("Please enter valid price");
      return false;
    }

    var price = "";
    if (limit === true) {
      price = sellPrice;
    }
    if (market === true) {
      price = currentPrice;
    }

    if (firstCoinBalance == 0 || firstCoinBalance <= 0) {
      return false;
    }

    let submitData = {
      orderType: "sell",
      per_price: price,
      volume: sellAmount,
      firstCoinId: firstCoinId,
      secondCoinId: secondCoinId,
      sellOrderId: sell_order_id,
      sellFeeAmyt: sellFeeAmyt,
      orderMethod: orderMethod,
      buy_commission: sell_commission,
    };
    setDisabledSellButtn(true);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/create_order", submitData, aheader)
      .then((resp) => {
        var apiResp = resp.data;
        // subscribeToUserStreams(token, params.firstCoin, params.secondCoin);
        if (apiResp.success === false) {
          closeEditModel();
          toast.error(apiResp.message);
          setBuyAmount(0);
          setBuy_order_total(0);
          setBuy_order_total_write(0);
          setOrder_id("");
          setSell_order_id("");
          setSellFeeAmyt(0);
          setSPBtn(0);
          if (apiResp.data == 1) {
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
          setDisabledSellButtn(false);
          // setTimeout(() => setDisabledSellButtn(false), 500);
        } else {
          setIsEditModelOpenSell(false);
          toast.success(apiResp.message);
          setSellAmount(0);
          setSell_order_total(0);
          setOrder_id("");
          setSell_order_id("");
          setSellFeeAmyt("");
          setSPBtn(0);
          // $(".my_color_sell").text("Sell");
          setDisabledSellButtn(false);
          // setTimeout(() => setDisabledSellButtn(false), 500);
        }
      });
  };

  const handleSellChange = (event) => {
    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });
    let { name, value } = event.target;
    setInExtype("exclude");
    var sellOrderTotal = "";
    if (name === "sell_order_total" && sellPrice > 0) {
      let commission = value * (100 / (100 + sell_commission));
      var sell_value = parseFloat(commission);
    } else {
      let commission = value * (100 / (100 + sell_commission));
      sell_value = commission;
    }
    if (name === "sellPrice") {
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length < 9) {
          setSellPrice(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setSellPrice(value);
        }
      }
    }
    if (name === "sellPrice" && sellAmount > 0) {
      let sellOrderTotalWithFee =
        parseFloat(sell_value) * parseFloat(sellAmount);
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length < 9) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellAmount)).toFixed(
            8
          );
          setSellPrice(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellAmount)).toFixed(
            8
          );
          setSellPrice(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      }
    } else if (name === "sellAmount" && sellPrice > 0) {
      let sellOrderTotalWithFee =
        parseFloat(sell_value) * parseFloat(sellPrice);
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length < 9) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellPrice)).toFixed(
            8
          );
          setSellAmount(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellPrice)).toFixed(
            8
          );
          setSellAmount(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      }
    } else if (name === "sell_order_total" && sellPrice > 0) {
      let sellOrderTotalWithFee = parseFloat(sell_value);
      let sellOrderTotal = (parseFloat(value) / parseFloat(sellPrice)).toFixed(
        8
      );
      setSellAmount(sellOrderTotal);
      setSellFeeAmyt(sellOrderTotalWithFee);
    }
  };

  const handleSellPercent = (percentAmt) => {
    var userBalance = firstCoinBalance;
    var percentAmts = (percentAmt * userBalance) / 100;
    percentAmts = percentAmts.toFixed(8);
    let finalAmt = parseFloat(percentAmts) * parseFloat(sellPrice);
    let Amount = percentAmts * (100 / (100 + sell_commission));
    let subTotal = Number(Amount) * Number(sellPrice);
    setSellFeeAmyt(subTotal.toFixed(8));
    setSell_order_total(finalAmt.toFixed(8));
    setSellAmount(parseFloat(percentAmts).toFixed(8));
    setSPBtn(percentAmt);
  };

  const AuthActionSell = () => {
    if (token) {
      return (
        <button
          className="btn w100  my_color_sell"
          disabled={disabledSellButtn}
        >
          Sell
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border3 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">Sign Up</Link> or{" "}
            <Link to="/login-to-buy-sell-crypto">Login</Link>
          </div>
        </div>
      );
    }
  };

  const sellBoxHtml = () => {
    return (
      <div className="sell-btc-outer buy-sell-outer border1">
        <div className="orderforms-hd">
          <div>
            <label className="ng-binding">
              Sell {firstCoinNew != "" ? firstCoinNew : firstCoin}
            </label>
            <span className="f-fr ng-binding">
              {firstCoin} Balance:{" "}
              {firstCoinBalance
                ? Number.isInteger(firstCoinBalance)
                  ? firstCoinBalance
                  : removeTrailingZeros(parseFloat(firstCoinBalance).toFixed(8))
                : 0}{" "}
            </span>
          </div>
        </div>
        {/* <ul className="type-list-outer d-flex justify-content-between">
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "limit");
                setOrderMethod("limit");
              }}
              href="#!"
              className={limit === true ? "active" : ""}
            >
              Limit
            </a>
          </li>
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "market");
                setOrderMethod("market");
              }}
              href="#!"
              className={market === true ? "active" : ""}
            >
              Market
            </a>
          </li>
          {/* <li>
              <a
                onClick={() => this.setStopChanges("stop")}
                href="javascript:voin(0)"
                className={stopLimit == true ? "active" : ""}
              >
                Stop Limit
              </a>
            </li> *
        </ul> */}
        <form
          name="buyForm"
          onSubmit={handleSellSubmit}
          autoComplete="off"
          className="ng-pristine ng-invalid ng-invalid-required"
        >
          <div className="orderforms-inputs p-2">
            {stopLimit === true && (
              <div className="field f-cb stop-limit">
                <label className="ng-binding">Stop Limit: </label>
                <div className="iptwrap leftBig limit">
                  <input
                    type="number"
                    step="any"
                    value={stopPriceSell}
                    onChange={handleSellChange}
                    name="stopPriceSell"
                    className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                  />

                  <span className="unit ng-binding">{secondCoin}</span>
                </div>
                <div className="cls"></div>
              </div>
            )}
            <div className="field f-cb">
              <label className="ng-binding">Price: </label>
              <div className="iptwrap leftBig">
                {orderMethod === "market" ? (
                  <>
                    <input
                      type="text"
                      step="any"
                      id="price"
                      value="Market"
                      name="sellPrice"
                      onChange={handleSellChange}
                      disabled={disabledButton}
                      onKeyPress={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="sell_order_id"
                      value={sell_order_id}
                      name="sell_order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                ) : (
                  <>
                    <input
                      type={type}
                      step="any"
                      id="price_sell"
                      value={disabledButton === true ? currentPrice : sellPrice}
                      name="sellPrice"
                      onChange={handleSellChange}
                      disabled={disabledButton}
                      onKeyPress={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="sell_order_id"
                      value={sell_order_id}
                      name="sell_order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                )}
              </div>
              <div className="cls"></div>
            </div>
            <div className="field f-cb">
              <label className="ng-binding">Amount: </label>
              <div className="iptwrap">
                <input
                  type="number"
                  step="any"
                  name="sellAmount"
                  id="amount_sell"
                  onChange={handleSellChange}
                  value={
                    // amount_sell == 1
                    sellAmount
                    // : sell_order_total_write
                  }
                  onKeyPress={handleKeyPress}
                />

                <span className="unit ng-binding">{firstCoin}</span>
              </div>
              <div className="cls"></div>
            </div>
            <div className="field percent f-cb">
              <div className="field4">
                <span
                  className={`col ${sPBtn == 25 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(25)}
                >
                  25%
                </span>
                <span
                  className={`col ${sPBtn == 50 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(50)}
                >
                  50%
                </span>
                <span
                  className={`col ${sPBtn == 75 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(75)}
                >
                  75%
                </span>
                <span
                  className={`col ${sPBtn == 100 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(100)}
                >
                  100%
                </span>
              </div>
            </div>
            <div className="field f-cb">
              {orderMethod === "market" ? (
                ""
              ) : (
                <>
                  <label className="ng-binding">Total: </label>
                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      id="total_sell"
                      name="sell_order_total"
                      value={sell_order_total}
                      onChange={handleSellChange}
                      disabled
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                    />
                    <span className="unit ng-binding">{secondCoin}</span>
                  </div>
                </>
              )}

              <div className="cls"></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  Trade Fee :{" "}
                  {sell_commission == null
                    ? 0
                    : Number.isInteger(sell_commission)
                      ? sell_commission
                      : removeTrailingZerosT(
                        parseFloat(sell_commission).toFixed(4)
                      )}{" "}
                  {sell_commission != null && <span>%</span>}
                </p>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  Net Amount :{" "}
                  {sellFeeAmyt ? parseFloat(sellFeeAmyt).toFixed(8) : 0}
                </p>
              </div>
            </div>
            {AuthActionSell()}
          </div>
        </form>
      </div>
    );
  };

  const handlePriceChangeChange = (value) => {
    setBuyPrice(value);
    setSellPrice(value);
  };

  const handlePriceChangeChangeBinance = (value) => {
    setBuyPrice(parseFloat(value.replace(/,/g, "")));
    setSellPrice(parseFloat(value.replace(/,/g, "")));
  };

  const activeSellOrderHtml = () => {
    var collectHtml = [];
    if (exchange === "binance") {
      if (props.depth && props.depth.asks) {
        const sortedAsks = props.depth.asks.sort((a, b) => b[0] - a[0]);
        let asksToShow = sortedAsks.slice(10);

        const tableRows = asksToShow
          .slice(0, 10)
          .map((activeSellOrderSingle, index) => {
            const tradePrice = parseFloat(activeSellOrderSingle[0]);
            const decimalPlaces = tradePrice < 1 ? 8 : 3;
            const formattedPrice = tradePrice.toLocaleString("en-US", {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            });

            return (
              <tr
                className="ng-scope"
                key={index}
                onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
                style={{ cursor: "pointer" }}
              >
                <td className="f-left ng-binding magenta crypt-down">
                  {formattedPrice}
                </td>
                <td className="f-left ng-binding ">
                  {activeSellOrderSingle[1]}
                </td>
                <td className="f-left ng-binding text-right">
                  {activeSellOrderSingle &&
                    (
                      activeSellOrderSingle[0] * activeSellOrderSingle[1]
                    ).toFixed(8)}
                </td>
              </tr>
            );
          });

        return <tbody>{tableRows}</tbody>;
      }
    } else {
      if (activeSellOrder) {
        collectHtml = [];
        activeSellOrderData.map((item, i) => {
          collectHtml.push(
            <tr
              className="ng-scope"
              key={i}
              onClick={() => handlePriceChangeChange(item.per_price)}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding magenta crypt-down">
                {item.per_price}
              </td>
              <td className="f-left ng-binding ">
                {parseFloat(item.sell_spend_amount).toFixed(8)}
              </td>
              <td className="f-left ng-binding text-right">
                {(item.per_price * item.sell_spend_amount).toFixed(8)}
              </td>
            </tr>
            // </a>
          );
        });
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                No Data Found
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const sideLastHtml = () => {
    if (topBarStatus !== false) {
      if (exchange === "binance") {
        const tradePrice = parseFloat(livPrice); // Get the last price from the item object
        const decimalPlaces = tradePrice < 1 ? 8 : 2; // Determine the number of decimal places based on the value of the number
        const formattedPrice = tradePrice.toLocaleString("en-US", {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        });
        return (
          <ul>
            <li>
              <strong className="ng-binding mm">{formattedPrice}</strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
          </ul>
        );
      } else {
        var topBarData = topBarApiData;

        if (topBarApiStatus === true && topBarStatus === false) {
          topBarData = topBarApiData;
        }
        if (topBarStatus === true) {
          topBarData = topBarData;
        }
        var collectHtml = (
          <ul>
            <li>
              <strong className="ng-binding mm">
                {secondCoin === "INR" ? (
                  <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
                ) : (
                  <span></span>
                )}
                {topBarData.currentPrice}
              </strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
          </ul>
        );
      }

      return <ul>{collectHtml}</ul>;
    } else {
      collectHtml = (
        <ul>
          <li>
            <strong className="ng-binding">
              {secondCoin === "INR" ? (
                <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
              ) : (
                <span></span>
              )}
              {0.0}
            </strong>
            <strong className="transMoney ng-binding ng-scope"></strong>
          </li>
        </ul>
      );
      return <ul>{collectHtml}</ul>;
    }
  };

  const activeBuyOrderHtml = () => {
    var collectHtml = [];
    if (exchange === "binance") {
      if (props.depth && props.depth.bids) {
        const tableRows = props.depth.bids
          .slice(0, 10)
          .map((activeBuyOrderSingle, index) => {
            const tradePrice = parseFloat(activeBuyOrderSingle[0]);
            const decimalPlaces = tradePrice < 1 ? 8 : 3;
            const formattedPrice = tradePrice.toLocaleString("en-US", {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            });

            return (
              <tr
                className="ng-scope"
                key={index}
                onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
                style={{ cursor: "pointer" }}
              >
                <td className="f-left ng-binding green crypt-up">
                  {formattedPrice}
                </td>
                <td className="f-left ng-binding ">
                  {activeBuyOrderSingle[1]}
                </td>
                <td className="f-left ng-binding text-right">
                  {activeBuyOrderSingle &&
                    (activeBuyOrderSingle[0] * activeBuyOrderSingle[1]).toFixed(
                      8
                    )}
                </td>
              </tr>
            );
          });

        return <tbody>{tableRows}</tbody>;
      }
    } else {
      if (activeBuyOrder) {
        collectHtml = [];
        activeBuyOrderData.map((item, i) => {
          collectHtml.push(
            <tr
              className="ng-scope"
              key={i}
              onClick={() => handlePriceChangeChange(item.per_price)}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding green crypt-up">
                {item.per_price}
              </td>
              <td className="f-left ng-binding ">
                {item.buy_get_amount.toFixed(8)}
              </td>
              <td className="f-left ng-binding text-right">
                {(item.per_price * item.buy_get_amount).toFixed(8)}
              </td>
            </tr>
          );
        });
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                No Data Found
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const marketTradeHtml = () => {
    if (exchange === "binance") {
      if (props.trades) {
        return (
          <tbody>
            <MarketTrade
              trades={props.trades}
              record={[]}
              currentPair={params.firstCoin + "/" + params.secondCoin}
            />
          </tbody>
        );
      }
    } else {
      if (marketTrade) {
        var collectHtml = [];
        var classThis = this;
        for (var i = 0; i < marketTradeData.length; i++) {
          var singleData = marketTradeData[i];
          var setColor = singleData.extype === "buy" ? "green" : "magenta";
          var getTime = Moment(singleData.created_at).format("lll");
          collectHtml.push(
            <tr className="ng-scope">
              <td className={"f-left ng-binding " + setColor}>
                {singleData.get_per_price}
              </td>
              <td className="f-left ng-binding">{singleData.get_amount}</td>
              <td className="f-left ng-binding">
                {singleData.firstCoin + "/" + singleData.secondCoin}
              </td>
              <td className="f-left ng-binding">{getTime}</td>
            </tr>
          );
        }
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                No data avaliable
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const updateBuyOrder = (orderId, orderType) => {
    if (orderId === undefined || orderId < 0) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType === undefined) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType !== "buy" && orderType !== "sell") {
      toast.error("Invalid Order Type");
      return false;
    }

    let submitData = {
      order_type: orderType,
      order_id: orderId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setUpdateAndDeleteButton(true);

    axios
      .post(baseUrl + "/frontapi/get_order", submitData, aheader)
      .then((resp) => {
        var apiResp = resp.data;
        setTimeout(() => {
          setUpdateAndDeleteButton(false);
        }, 2000);

        if (secondCoinBalance == 0 || secondCoinBalance == null) {
          setInExtype("include");
        }

        if (apiResp.success === false) {
          toast.error(apiResp.message);
        } else {
          var orderData = apiResp.data[0];
          setIsEditModelOpen(true);
          setDisabledBuuButtn(true);
          setBuyPrice(parseFloat(orderData.per_price));
          setBuyAmount(orderData.total_buy_get_amount);
          setOrder_id(orderData.id);
          setFirstCoinNew(orderData.name1);
          setFeeAmyt(orderData.net_amount);
          if (orderData.total_buy_get_amount == 1) {
            setEditAmount(orderData.total_buy_get_amount);
          } else {
            setBuyAmount(orderData.total_buy_get_amount);
          }
          toast.success(apiResp.message);
        }
      });
  };

  const deleteOrder = (orderId, orderType) => {
    if (orderId === undefined || orderId < 0) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType === undefined) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType !== "buy" && orderType !== "sell") {
      toast.error("Invalid Order Type");
      return false;
    }

    let submitData = {
      order_type: orderType,
      order_id: orderId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    setUpdateAndDeleteButton(true);
    axios
      .post(baseUrl + "/frontapi/delete_order", submitData, aheader)
      .then((resp) => {
        var apiResp = resp.data;
        setTimeout(() => {
          setUpdateAndDeleteButton(false);
        }, 1000);
        if (apiResp.success === false) {
          toast.error(apiResp.message);
        } else {
          toast.success(apiResp.message);
        }
      });
  };

  const myActiveBuyOrderHtml = () => {
    if (myActiveBuyOrder) {
      var collectHtml = [];

      myActiveBuyOrderData.forEach((e, i) => {
        const parsedDate = Moment(e.created_at);
        var getTime = parsedDate.format("lll");
        collectHtml.push(
          <tr className="ng-scope" key={i}>
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">
              {e.per_price.toFixed(8)}
            </td>
            <td className="f-left ng-binding">{e.buy_get_amount.toFixed(8)}</td>
            <td className="f-left ng-binding">
              {(e.per_price * e.buy_get_amount).toFixed(8)}
            </td>
            <td className="f-left ng-binding green">{getTime}</td>
            <td className="f-left ng-binding">
              <button
                className="btn  mr-2"
                disabled={updateAndDeleteButton}
                onClick={() => updateBuyOrder(e.buy_id, "buy")}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn "
                disabled={updateAndDeleteButton}
                onClick={() => deleteOrder(e.buy_id, "buy")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        );
      });

      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              No Data found
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myCompletedBuyOrderHtml = () => {
    if (myCompletedBuyOrder) {
      var collectHtml = [];
      for (var i = 0; i < 6; i++) {
        var myCompletedBuyOrderSingle = myCompletedBuyOrderData[i];
        if (myCompletedBuyOrderSingle) {
          var getTime = Moment(myCompletedBuyOrderSingle.created_at).format(
            "lll"
          );
          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.per_price.toFixed(8)}
              </td>
              <td className="f-left ng-binding">
                {myCompletedBuyOrderSingle.total_buy_get_amount.toFixed(8)}
              </td>
              <td className="f-left ng-binding">
                {(
                  myCompletedBuyOrderSingle.per_price *
                  myCompletedBuyOrderSingle.total_buy_get_amount
                ).toFixed(8)}
              </td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={5} style={{ textAlign: "center" }}>
              No Data Found
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const updateSellOrder = (orderId, orderType) => {
    if (orderId === undefined || orderId < 0) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType === undefined) {
      toast.error("Invalid Order");
      return false;
    }

    if (orderType !== "buy" && orderType !== "sell") {
      toast.error("Invalid Order Type");
      return false;
    }

    let submitData = {
      order_type: orderType,
      order_id: orderId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/get_order", submitData, aheader)
      .then((resp) => {
        var apiResp = resp.data;
        if (firstCoinBalance == 0 || firstCoinBalance == null) {
          setInExtype("include");
        }
        if (apiResp.success === false) {
          toast.error(apiResp.message);
        } else {
          var orderData = apiResp.data[0];
          // setEditPrice(orderData.per_price);
          // setEditAmount(orderData.total_sell_spend_amount);
          setIsEditModelOpenSell(true);
          setDisabledSellButtn(true);
          setSellPrice(orderData.per_price);
          setSellAmount(orderData.total_sell_spend_amount);
          // setSell_order_total(orderData.total_sell_get_amount);
          setSell_order_id(orderData.id);
          setFirstCoinNew(orderData.name1);
          setSellFeeAmyt(orderData.net_amount);

          if (orderData.total_sell_spend_amount == 1) {
            // setEditAmount(orderData.total_sell_spend_amount);
            setSellAmount(orderData.total_sell_spend_amount);
          }
          // $(".my_color_sell").text("Update");
          toast.success(apiResp.message);
        }
      });
  };

  const myActiveSellOrderHtml = () => {
    if (myActiveSellOrder) {
      var collectHtml = [];
      myActiveSellOrderData.forEach((e, i) => {
        var getTime = Moment(e.created_at).format("lll");
        collectHtml.push(
          <tr className="ng-scope" key={i}>
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">
              {e.per_price.toFixed(8)}
            </td>
            <td className="f-left ng-binding">
              {e.sell_spend_amount.toFixed(8)}
            </td>
            <td className="f-left ng-binding">
              {(e.per_price * e.sell_spend_amount).toFixed(4)}
            </td>
            <td className="f-left ng-binding green">{getTime}</td>
            <td className="f-left ng-binding">
              <button
                className="btn  mr-2"
                onClick={() => updateSellOrder(e.sell_id, "sell")}
                disabled={updateAndDeleteButton}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn "
                onClick={() => deleteOrder(e.sell_id, "sell")}
                disabled={updateAndDeleteButton}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        );
      });
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              No Data Found
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myCompletedSellOrderHtml = () => {
    if (myCompletedSellOrder) {
      var collectHtml = [];
      for (var i = 0; i < 6; i++) {
        var myCompletedSellOrderSingle = myCompletedSellOrderData[i];
        if (myCompletedSellOrderSingle) {
          var getTime = Moment(myCompletedSellOrderSingle.created_at).format(
            "lll"
          );

          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.per_price.toFixed(8)}
              </td>
              <td className="f-left ng-binding">
                {myCompletedSellOrderSingle.total_sell_spend_amount.toFixed(8)}
              </td>
              <td className="f-left ng-binding">
                {(
                  myCompletedSellOrderSingle.per_price *
                  myCompletedSellOrderSingle.total_sell_spend_amount
                ).toFixed(8)}
              </td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              No Data Found
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const showHighChartHtml = (symbol) => {
    if (exchange !== "binance") {
      return <div id="tv_chart_container"></div>;
    } else {
      return (
        <TradingViewWidget
          symbol={"BINANCE:" + symbol}
          theme={Themes.DARK}
          locale="en"
          autosize={true}
          details={false}
          BarStyles={BarStyles.HEIKIN_ASHI}
        />
      );
    }
  };

  return (
    <div>
      <ExchangeKycHeader
        graphColor={changeTheme}
        portfolioData={portfolioData}
      />{" "}
      <div className="bghwhite">
        <div className="dashbord_manbox exchange-main-outer">
          <div className="container-fluid">
            <div className="dashbord_in1">
              <div className="dashbord_in dashbord_in_top fs12">
                <div className="kline-para kline-para-basic d-flex align-items-center">
                  <div className="box-kline-para-basic-left">
                    <span className="productSymbol has-info ng-scope">
                      <strong className="ng-binding">
                        {token && (
                          <svg
                            viewBox="0 0 38 35"
                            width="20px"
                            height="20px"
                            onClick={favListApi}
                            style={{ cursor: "pointer" }}
                          >
                            <g id="Home" />
                            <g id="Print" />
                            <g id="Mail" />
                            <g id="Camera" />
                            <g id="Video" />
                            <g id="Film" />
                            <g id="Message" />
                            <g id="Telephone" />
                            <g id="User" />
                            <g id="File" />
                            <g id="Folder" />
                            <g id="Map" />
                            <g id="Download" />
                            <g id="Upload" />
                            <g id="Video_Recorder" />
                            <g id="Schedule" />
                            <g id="Cart" />
                            <g id="Setting" />
                            <g id="Search" />
                            <g id="Pencils" />
                            <g id="Group" />
                            <g id="Record" />
                            <g id="Headphone" />
                            <g id="Music_Player" />
                            <g id="Sound_On" />
                            <g id="Sound_Off" />
                            <g id="Lock" />
                            <g id="Lock_open" />
                            <g id="Love" />
                            <g id="Favorite">
                              <path
                                d="M30.9,10.6C30.8,10.2,30.4,10,30,10h0h-9l-4.1-8.4C16.7,1.2,16.4,1,16,1v0c0,0,0,0,0,0   c-0.4,0-0.7,0.2-0.9,0.6L11,10H2c-0.4,0-0.8,0.2-0.9,0.6c-0.2,0.4-0.1,0.8,0.2,1.1l6.6,7.6L5,29.7c-0.1,0.4,0,0.8,0.3,1   s0.7,0.3,1.1,0.1l9.6-4.6l9.6,4.6C25.7,31,25.8,31,26,31h0h0h0c0.5,0,1-0.4,1-1c0-0.2,0-0.3-0.1-0.5l-2.8-10.3l6.6-7.6   C31,11.4,31.1,10.9,30.9,10.6z"
                                fill="transparent"
                                style={
                                  isActive === "N"
                                    ? { stroke: "#e1ae39" }
                                    : { stroke: "#e1ae39", fill: "#e1ae39" }
                                }
                              />
                            </g>
                            <g id="Film_1_" />
                            <g id="Music" />
                            <g id="Puzzle" />
                            <g id="Turn_Off" />
                            <g id="Book" />
                            <g id="Save" />
                            <g id="Reload" />
                            <g id="Trash" />
                            <g id="Tag" />
                            <g id="Link" />
                            <g id="Like" />
                            <g id="Bad" />
                            <g id="Gallery" />
                            <g id="Add" />
                            <g id="Close" />
                            <g id="Forward" />
                            <g id="Back" />
                            <g id="Buy" />
                            <g id="Mac" />
                            <g id="Laptop" />
                          </svg>
                        )}
                        {firstCoin}
                      </strong>
                      <font className="ng-binding"> / {secondCoin}</font>
                    </span>
                  </div>
                  <div className="box-kline-para-basic-right">
                    {topBarHtml()}
                    <div className="cls"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 padding-right exchange_left mb-3">
                    <div className="order-history-outer ne_box col-non-padding col-min-padding-right">
                      <div className="panel panel-default">
                        <div className="panel-heading tab-box">
                          <ul className="exchange_tab">
                            {token && (
                              <li
                                className={
                                  setUrl === "fav"
                                    ? "active tabQuoteAsset"
                                    : "tabQuoteAsset"
                                }
                                onClick={() => activeCurrency("fav")}
                              >
                                <i className="fa fa-star"></i>
                              </li>
                            )}
                            {exchange_tab.map((list) => {
                              return (
                                <li
                                  className={
                                    setUrl == list.short_name
                                      ? "active tabQuoteAsset"
                                      : "tabQuoteAsset"
                                  }
                                  onClick={() =>
                                    activeCurrency(list.short_name)
                                  }
                                >
                                  {list.short_name}
                                </li>
                              );
                            })}
                          </ul>
                          <div className="clearfix"></div>
                        </div>
                        <div className="panel-body p-0">
                          <div className="searchFilter f-cb">
                            <div className="search-box search-box-new">
                              <input
                                type="text"
                                id="search-int"
                                placeholder=""
                                className="ng-pristine ng-untouched ng-valid ng-empty"
                                onChange={onSearch}
                                value={searchedValue}
                              />
                              {searchedValue !== "" ? (
                                <i
                                  onClick={EmptySearch}
                                  className="las la-times-circle"
                                ></i>
                              ) : (
                                <i className="las la-search"></i>
                              )}
                            </div>
                          </div>
                        </div>
                        <table className="table mb-0 currency_table1">
                          <thead>
                            <tr>
                              <th
                                className="f-left r-market-pair ng-binding"
                                ng-click="sortByKey('baseAsset')"
                              >
                                Pair
                                <span className="ng-scope">
                                  <i className="fa fa-caret-up"></i>
                                </span>
                              </th>
                              <th className="f-left r-market-price ng-binding">
                                Price
                              </th>

                              <th className="r-market-rate ng-binding ng-scope tr">
                                Change
                              </th>
                            </tr>
                          </thead>
                        </table>
                        <div className="table-overflow">
                          <table className="table table-hover currency_table">
                            {coinListHtml(coinListData)}
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="ne_box p-0 ">
                      <h6 className="h6_head pl-3 mt-3">Spot Balance</h6>
                      <div className="balance-outer">
                        <table className="w-100">{showSpotBalance()}</table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 exchange_right">
                    <div className="ne_box">
                      {exchange !== "binance" ? (
                        <TradingViewChart
                          params={params}
                          graphColor={themeRefresh}
                        />
                      ) : (
                        <div className="binance-chart-container">
                          {openTradingChart(firstCoin + secondCoin)}
                        </div>
                      )}
                      {/* <div className="binance-chart-container">
                        {showHighChartHtml(
                          params.firstCoin + params.secondCoin
                        )}
                      </div> */}
                      {/* {exchange !== "binance" ? (
                        showHighChartHtml()
                      ) : (
                        <div className="binance-chart-container">
                          {openTradingChart(
                            params.firstCoin + params.secondCoin
                          )}
                        </div>
                      )} */}
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-6 mb-3">
                        <div className="ne_box h_100">{buyBoxHtml()}</div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="ne_box h_100">{sellBoxHtml()}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="ne_box h_100">
                      <h6 className="">Market Depth</h6>
                      <div className=" bs_border">
                        <table className="table mb-0 ">
                          <thead>
                            <tr>
                              <th>Price</th>
                              <th>
                                Qty.
                                {/*({firstCoin})*/}
                              </th>
                              <th className="text-right">
                                Total({secondCoin})
                              </th>
                            </tr>
                          </thead>
                          {activeSellOrderHtml()}
                        </table>
                      </div>
                      <h6 className="text-center t2t m-0"> {sideLastHtml()}</h6>
                      <div className="bs_border">
                        <table className="table mb-0 ">
                          <thead>
                            <tr>
                              <th>Price</th>
                              <th>Qty.</th>
                              <th className="text-right">
                                Total({secondCoin})
                              </th>
                            </tr>
                          </thead>
                          {activeBuyOrderHtml()}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="color-background orderform-main">
                  <div className="row">
                    <div className="col-md-4  mb-3">
                      <div className="ne_box h_100">
                        <div className="market-trade">
                          <div className="tradehistory tradehistory-new ">
                            <div
                              className="item-con tradeHistory-base"
                              id="tradeHistory"
                            >
                              <h6 className="h6_head">Order History</h6>
                              <div className="scrollStyle ng-scope">
                                <table className="table mb-0">
                                  <thead>
                                    <tr>
                                      <th>Price</th>
                                      <th>Amount</th>
                                      <th>Pair</th>
                                      <th>Date & Time</th>
                                    </tr>
                                  </thead>
                                  {marketTradeHtml()}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  mb-3">
                      <div className="ne_box h_100">
                        <div className="table_hadding flex-wrap">
                          <h6 className="h6_head">Buy Orders</h6>
                          <div className="status-outer">
                            <ul className="nav nav-tabs2" role="tablist">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#pending"
                                  role="tab"
                                >
                                  Pending
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#complete"
                                  role="tab"
                                >
                                  Completed
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="buy-order-outer tab-content">
                          <div
                            className="tab-pane active"
                            id="pending"
                            role="tabpanel"
                          >
                            <div className="userRecords-main obo_exchange">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th className="f-left ng-binding">Pair</th>
                                    <th className="f-left ng-binding">Price</th>
                                    <th className="f-left ng-binding">
                                      Amount
                                    </th>
                                    <th className="f-left ng-binding">Total</th>
                                    <th className="f-left ng-binding">
                                      Date & Time
                                    </th>
                                    <th className="f-center cancels">
                                      <span className="ng-binding p-0">
                                        Action
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                {myActiveBuyOrderHtml()}
                              </table>
                            </div>
                          </div>
                          <div
                            className="tab-pane"
                            id="complete"
                            role="tabpanel"
                          >
                            <div className="userRecords-main">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th>Pair</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Total</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                {myCompletedBuyOrderHtml()}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  mb-3">
                      <div className="ne_box h_100">
                        <div className="table_hadding flex-wrap">
                          <h6 className="h6_head">Sell Orders</h6>
                          <div className="status-outer">
                            <ul className="nav nav-tabs2" role="tablist">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#pending-sell"
                                  role="tab"
                                >
                                  Pending
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#complete-sell"
                                  role="tab"
                                >
                                  Completed
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="sell-order-outer tab-content">
                          <div
                            className="tab-pane active"
                            id="pending-sell"
                            role="tabpanel"
                          >
                            <div className="userRecords-main obo_exchange">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th className="f-left ng-binding">Pair</th>
                                    <th className="f-left ng-binding">Price</th>
                                    <th className="f-left ng-binding">
                                      Amount
                                    </th>
                                    <th className="f-left ng-binding">Total</th>
                                    <th className="f-left ng-binding">
                                      Date & Time
                                    </th>
                                    <th className="f-center cancels">
                                      <span className="ng-binding p-0">
                                        Action
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                {myActiveSellOrderHtml()}
                              </table>
                            </div>
                          </div>
                          <div
                            className="tab-pane"
                            id="complete-sell"
                            role="tabpanel"
                          >
                            <div className="userRecords-main">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th>Pair</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Total</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                {myCompletedSellOrderHtml()}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal
                      className="modify_order"
                      show={isEditModelOpen}
                      onHide={closeEditModel}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title> Modify Order</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <input
                          type="number"
                          className="form-control  mb-3"
                          name="editPrice"
                          value={buyPrice}
                          onChange={onEditOrder}
                        />
                        <input
                          type="number"
                          className="form-control  mb-3"
                          name="editAmount"
                          value={buyAmount}
                          onChange={onEditOrder}
                        />
                        <button
                          onClick={handleBuySubmit}
                          className="btn w100 my_color_buy"
                        >
                          Update
                        </button>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      className="modify_order"
                      show={isEditModelOpenSell}
                      onHide={closeEditModel}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title> Modify Order</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <input
                          type="number"
                          name="editPrice"
                          className="form-control mb-3"
                          value={sellPrice}
                          onChange={onEditOrderSell}
                        />
                        <input
                          type="number"
                          name="editAmount"
                          className="form-control  mb-3"
                          value={sellAmount}
                          onChange={onEditOrderSell}
                        />
                        <button
                          onClick={handleSellSubmit}
                          className="btn w100 my_color_sell"
                        >
                          Update
                        </button>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        <div className="copy-right">
          <p>
            © 2023 {ProductName}. All Rights Reserved{" "}
            <small>Build version 1.0.6</small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => state)(ExchnageFunc);
