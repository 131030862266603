import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeftTab from "../Common/LeftTab";

const baseUrl = myConstList.baseUrl;

export class security extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      authentication_with: null,
    };

    this.SwitchChange = this.SwitchChange.bind(this);

    this.getUserDetails();
    this.LoginActivity();
  }

  componentDidMount() {
    this.SwitchApi();
  }

  getUserDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;

      this.setState({
        authentication_with: resp.data[0].authentication_with,
        TwoFA: resp.data[0].authentication_with,
      });
    });
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, errMsg: "" });
  };

  handleOnBlurOldPassword(event) {
    var password = event.target.value;
    if (!password) {
      toast.dismiss();
      toast.error("Old Password required");
    }
  }

  handleOnBlurNewPassword(event) {
    var Npassword = event.target.value;
    if (!Npassword) {
      toast.dismiss();
      toast.error("New Password required");
    }
    if (Npassword.length < 8) {
      toast.dismiss();
      toast.error("Enter Minimum 8 Character");
      return false;
    }
  }

  handleOnBlurCPassword(event) {
    var CPassword = event.target.value;
    if (!CPassword) {
      toast.dismiss();
      toast.error("Re-enter Your New Password");
      return false;
    }
    if (CPassword.length < 8) {
      toast.dismiss();
      toast.error("Enter Minimum 8 Character");
      return false;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const config = {
      headers: {
        lang: localStorage.getItem("lang"),
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      NewPassword: this.state.NewPassword,
      OldPassword: this.state.OldPassword,
      CPassword: this.state.CPassword,
    };

    axios
      .post(baseUrl + "/frontapi/changePassword", LoginData, config)
      .then((resp) => {
        var response = resp.data;

        this.setState({ NewPassword: "", OldPassword: "", CPassword: "" });
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.messagecu);

          return false;
        } else {
          toast.dismiss();
          toast.error(response.messagecu);
          return false;
        }
      });
  };

  LoginActivity = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    if (!config) {
      window.location.href = "/login-to-buy-sell-crypto";
    }

    axios.post(baseUrl + "/frontapi/login-list", {}, config).then((resp) => {
      var respNew = resp.data;
      if (respNew.status === true) {
        this.setState({
          tabledata: respNew.findData,
          respStatus: respNew.status,
        });
      }
    });
  };

  showTableHtml = () => {
    if (this.state.respStatus === true) {
      const classThis = this;
      const html = [];
      var snno = 0;
      this.state.tabledata.map(function (value, i) {
        snno++;
        html.push(
          <tr>
            <td> {snno} </td>
            <td> {moment(value.created_at).format("lll")} </td>
            <td>{value.event}</td>
            <td>{value.device}</td>
            <td>{value.browser}</td>
            <td>{value.osName}</td>
            <td>{value.ip}</td>
            <td>
              {value.cityName}, {value.countryName}
            </td>
          </tr>
        );
      });
      return html;
    }
  };

  render() {
    return (
      <div className="">
        <div className="bghwhite">
          <div className="new_inpage">
            <div className="container container2">
              <div className="in_page">
              <LeftTab />
                <h5>Change Password</h5>
                <form onSubmit={this.handleSubmit}>
                  <div className="white_box">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Old password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="OldPassword"
                          value={this.state.OldPassword}
                          onChange={this.handleChange}
                          onBlur={this.handleOnBlurOldPassword}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>New password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="NewPassword"
                          value={this.state.NewPassword}
                          onChange={this.handleChange}
                          onBlur={this.handleOnBlurNewPassword}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Confirm new password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="CPassword"
                          value={this.state.CPassword}
                          onChange={this.handleChange}
                          onBlur={this.handleOnBlurCPassword}
                        />
                      </div>
                      <div className="form-group col-md-6 mt-1">
                        <button
                          className="btn btn_man mt-4 fs14"
                          disabled={
                            !this.state.OldPassword ||
                            !this.state.NewPassword ||
                            !this.state.CPassword
                          }
                        >
                          Change password
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <h5>Your recent activity</h5>
                <div className="white_box fs12 ">
                  <div className="scrool_wallet_box">
                    <table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th> Sr No</th>
                          <th>Date (UTC) </th>
                          <th>Event</th>
                          <th>Device</th>
                          <th>Browser</th>
                          <th>Operation System</th>
                          <th>IP</th>
                          <th>Address</th>
                        </tr>
                      </thead>

                      <tbody>{this.showTableHtml()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default security;
