import axios from "axios";
//import jwt from 'jsonwebtoken';

import { baseUrl, socketUrl, aheader } from "../../src/components/Common/BaseUrl";

const API_URL = "http://localhost:8080/api/auth/";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const getUserDetail = () => {
  const config = {
    headers: {

      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  axios.post(baseUrl + "/frontapi/UserData", {}, aheader).then((res) => {
    var resp = res.data;
    if (resp.status === true && resp.data.length == 1) {
      localStorage.setItem("userData", JSON.stringify(resp.data[0]));
      return resp.data[0];
    }
  }).catch((e) => {
    console.log(e);
  })
}

const logout = () => {
  try {

    console.log("= logout ==== ");

    var theme = localStorage.getItem("theme");
    var lang = localStorage.getItem("lang");
    var homeShow = localStorage.getItem("homepopshow");
    var token = localStorage.getItem("token");

    localStorage.removeItem("user");
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
    localStorage.removeItem("homepopshow");
    localStorage.removeItem("lang");
    sessionStorage.removeItem("verifyLoginPin");


    sessionStorage.setItem("popshow", true);
    if (theme && theme != null) {
      localStorage.setItem("theme", theme);
    }
    if (lang && lang != null) {
      localStorage.setItem("lang", lang);
    }
    if (homeShow && homeShow != null) {
      localStorage.setItem("homepopshow", homeShow);
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    console.log("config = ", config);
    axios.post(baseUrl + "/frontapi/logout", {}, aheader).then((res) => {
      var resp = res.data;
      return resp

    }).catch((e) => {
      console.log(e);
      return e
    })
    return
  } catch (e) {
    console.log(e);
    return
  }


};

const newToken = () => {
  const mainObj = {
    loginUserId: "public",

  };
  // const token = jwt.sign({ mainObj }, configFile.secret, { expiresIn: 86400, });

  const token = ""
  //jwt.sign({ mainObj }, 'YdKKdviKKdlvppjciv767skmlkk3dl',  { expiresIn: 60 });
  return token
}

export default {
  register,
  login,
  logout,
  getUserDetail,
  newToken
};
