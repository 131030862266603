import React, { useEffect, useState } from "react";
import * as myConstList from "../../Common/BaseUrl";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { PasswordValidation } from "../../Validation/PasswordValidation";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { EmailValidation } from "../../Validation/EmailValidation";
import { ProductName } from "../../Common/ProductName";
import MainHeader from "../../Common/MainHeader";
import DocumentMeta from "react-document-meta";

const baseUrl = myConstList.baseUrl;
export default function ForgotPin() {
    const history = useHistory();
    const { t } = useTranslation();
    const [codeMatchedStatus, setCodeMatchedStatus] = useState(false);
    const [timerText, setTimerText] = useState("");
    const [counter, setCounter] = useState("");
    const [textForCode, setTextForCode] = useState("get_code");
    const [type, setType] = useState("password");
    const [type1, setType1] = useState("password");
    const [buttonDisableVerification, setButtonDisableVerification] =
        useState(true);
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordIssue, setPasswordIssue] = useState("");
    const [cPasswordIssue, setCPasswordIssue] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [btnDisable, setBtnDisable] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationCodeError, setVerificationCodeError] = useState("");
    const [countryCode, setCountryCode] = useState("+");
    const [selectedFiledType, setSelectedFiledType] = useState("email");
    const [automateCountryCode, setAutomateCountryCode] = useState("ae");
    const [inputMobile, setInputMobile] = useState("");

    const getData = async () => {
        const response = await axios.get("https://ipapi.co/json/");
        let data = response.data;
        setAutomateCountryCode(
            data.country_code ? data.country_code.toLowerCase() : "ae"
        );
        localStorage.setItem("ip", data.ip ? data.ip : "");
    };

    useEffect(() => {
        var token1 = localStorage.getItem("token");
        if (token1 === null) {
            return history.push("/login-to-buy-sell-crypto");
        }
        if (!localStorage.getItem("theme")) {
            localStorage.setItem("theme", true);
        }
        var theme = localStorage.getItem("theme");
        if (theme == "true" || theme == "false") {
            theme = JSON.parse(theme);
            handleChange(theme);
        }
       // getData();
    }, []);

    const handleChange2 = (event) => {
        var value = event.target.value;
        if (textForCode == "resend_code") {
            setTextForCode("get_code");
        }
        if (!event.target.value) {
            setMobile("");
            setMobileError("");
            setEmailError("");
            setEmail("");
            return false;
        }
        if (
            !isNaN(parseFloat(event.target.value)) &&
            !isNaN(event.target.value - 0) == true
        ) {
            var phoneno = /^([0|\+[0-9]{1,10})?([0-9]{7})$/;

            if (value.match(phoneno)) {
                setMobileError("");
                setEmailError("");
            } else if (/^0*$/.test(value)) {
                setMobileError("Please enter valid mobile number");
                setEmailError("");
            } else {
                setMobileError("Please enter valid mobile number");
                setEmailError("");
            }
            setMobile(event.target.value);
            setEmail("");
        } else {
            var Email = event.target.value;
            if (
                new RegExp(/^[A-Za-z0-9][a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
                    Email
                )
            ) {
                setEmailError("");
                setMobileError("");
            } else {
                setEmailError("Please enter a valid email address");
                setMobileError("");
            }
            setEmail(event.target.value);
            setMobile("");
        }
    };

    const handleChangePasswordSubmit = (params) => {

        // if (passwordIssue || cPasswordIssue) {
        //     return false;
        // }
        // if (!password) {
        //     setPasswordIssue("This field is required");
        //     return false;
        // }
        // if (!cPassword) {
        //     setCPasswordIssue("This field is required");
        //     return false;
        // }


        console.log(params);
        if (email) {
            params.email = email;
        }
        if (mobile) {
            params.mobile = mobile;
        }

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        axios.post(baseUrl + "/frontapi/userloginpincreate", params, config).then((res) => {
            const resp = res.data;
            if (resp.status === true) {
                toast.dismiss();
                toast.success(t(resp.message));
                // setCPassword("");
                // setPassword("");
                //setTimeout(function () {
                localStorage.clear();
                window.location = "/";
                //}, 2000);
            }
            if (resp.status === false) {
                toast.dismiss();
                toast.error(t(resp.message));
            }
        });
    };
    const handleMobileSubmit = (e) => {
        e.preventDefault();
        if (!mobile && !email) {
            setMobileError("This field is required");
            return false;
        }
        if (!verificationCode) {
            setVerificationCodeError("This field is required");
            return false;
        }
        if (mobileError || emailError) {
            return false;
        }
        let data;
        if (selectedFiledType === "mobile") {
            data = {
                mobile: mobile,
                page: "forgot",
                code: verificationCode,
                selectedFiledType: selectedFiledType,
                countryCode: countryCode,
            };
        }
        if (selectedFiledType === "email") {
            data = {
                email: email,
                page: "forgot",
                code: verificationCode,
                selectedFiledType: selectedFiledType,
            };
            if (
                !new RegExp(/^[a-z0-9][a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
                    email
                )
            ) {
                setEmailError("Please enter a valid email address");
                return false;
            }
        }
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        axios.post(baseUrl + "/frontapi/userLoginPinVerifyCode", data, config).then((res) => {
            const resp = res.data;
            if (resp.status) {
                toast.success(t(resp.message));
            } else {
                toast.error(t(resp.message));
            }
            setCodeMatchedStatus(resp.status);
        });
    };
    const startCountdown = (seconds) => {
        let counter = seconds;
        const interval = setInterval(() => {
            setCounter(counter);
            setTimerText(t("resend") + " " + t("in"));
            setBtnDisable(true);
            counter--;
            if (counter < 0) {
                clearInterval(interval);
                setTextForCode("resend_code");
                setBtnDisable(false);
                setTimerText("");
            }
        }, 1000);
    };

    const handlecodechange = async (e) => {
        let { value } = e.target;
        if (value.length > 6) {
            value = value.toString().substring(0, 6)
        }
        setVerificationCode(value);
        if (!value) {
            setVerificationCodeError("This field is required");
        } else {
            setVerificationCodeError("");
        }
    };
    const getCode = async () => {
        if (!email && !mobile) {
            setEmailError("This field is required");
            return false;
        }
        let registerData = {
            countryCode: countryCode,
            page: "forgot",
            selectedFiledType: selectedFiledType,
        };

        if (selectedFiledType === "email") {
            registerData.email = email;
            if (
                !new RegExp(
                    /^[a-zA-Z0-9][a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
                ).test(email)
            ) {
                setEmailError("Please enter a valid email address");

                return false;
            }
        }
        if (selectedFiledType === "mobile") {
            registerData.mobile = mobile;

            const regex = /^\+\d{1,4}$/;

            if (!regex.test(countryCode)) {
                setMobileError("Please enter a valid country code");
                return;
            }

            const isValidLength = mobile.length >= 5 && mobile.length <= 15;
            if (!isValidLength) {
                setMobileError("Please enter a valid mobile number");
                return false;
            } else {
                setMobileError("");
            }
        }

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        await axios
            .post(baseUrl + "/frontapi/userLoginPinForget", registerData, config)
            .then((res) => {
                const resp = res.data;
                setVerificationCodeError("");

                if (resp.status === true) {
                    toast.success(t(resp.message));
                    startCountdown(30);
                    setButtonDisableVerification(false);
                    return false;
                }
                if (resp.status === false) {
                    setButtonDisableVerification(true);
                    toast.error(t(resp.message));
                    return false;
                }
            });
    };
    const handleChange101 = (event) => {
        let { name, value } = event.target;
        console.log(name + " " + value);
        // Password //
        let valid = true;
        if (name === "Password") {
            if (value.length < 7) {
                setPassword(value);
                var passwordError = "";
                if (value.length < 6) {
                    passwordError = "Pin must be 6 digit"
                    valid = false;
                    
                }
                //PasswordValidation(name, value);
                setPasswordIssue(passwordError);
                return
                // if (passwordError == "mismatched") {
                //     setPasswordIssue("");
                //     setCPasswordIssue("Password mis-matched");
                // }
                // if (passwordError == "") {
                //     setPasswordIssue("");
                //     setCPasswordIssue("");
                // }
            }
        }

        if (name == "CPassword") {
            if (value.length < 7) {
                setCPassword(value);
                var CpasswordError = ""
                if (value.length < 6) {
                    CpasswordError = "Confirm Pin must be 6 digit";
                    valid = false;
                    setCPasswordIssue(CpasswordError);
                    return
                } else if (value != password) {
                    CpasswordError = "New password and confirm password not matched"
                    valid = false;
                    setCPasswordIssue(CpasswordError);
                    return
                } else {
                    console.log(valid);
                    if (valid && password != "" && cPassword != "") {
                        let params = {
                            pin: password,
                            // confirm_pin: value,
                        };
                        handleChangePasswordSubmit(params)
                    }
                }
                //PasswordValidation(name, value);
                //  setCPasswordIssue(CpasswordError);

                // if (passwordError == "") {
                //     setCPasswordIssue("");
                // }
            }
        }
    };

    const handleChangeMobile = (event) => {
        const { name, value } = event.target;

        setEmailError("");
        setEmail("");
        if (name === "phone") {
            setMobile(value);

            if (!countryCode) {
                setMobileError("Country code is required");
                return;
            }

            if (!value) {
                setMobileError("This field is required");
                return;
            }

            if (value.length < 5 || value.length > 15) {
                setMobileError("Mobile number should be between 5 to 15 characters");
                return;
            }

            // setSelectedFiledType("mobile"); // Otherwise, stay on mobile input

            setEmailError("");
            setMobileError("");

            // if (!value) {
            //   setSelectedFiledType("email");
            // } else {
            //   setSelectedFiledType("mobile");
            // }

            if (textForCode == "resend_code") {
                setTextForCode("get_code");
            }

        }

        if (name === "countryCode") {
            if (!value || value.length < 2) {
                setCountryCode("+");
                setMobileError("Country code is required");
                return;
            }

            setCountryCode(value);

            const regex = /^\+\d{1,4}$/;

            if (!regex.test(value)) {
                // setCountryCode("+");
                setMobileError("Please enter a valid country code");
                return;
            }

            setMobileError("");
        }
    };

    const handleOnChange = (value, country) => {
        console.log("Selected Country:", country);
        console.log("Formatted Phone Number:", value);
        if (textForCode === "resend_code") {
            setTextForCode("get_code");
        }
        const effectiveValue = value.replace(/[\s\+]/g, "");
        const isEffectivelyEmpty = effectiveValue === country.dialCode;
        setEmailError("");
        if (isEffectivelyEmpty) {
            setSelectedFiledType("email");
            setCountryCode("");
            setMobile("");
            setEmailError("This filed is required");
        } else {
            setSelectedFiledType("mobile");
            setEmailError("");
        }

        let adjustedMobile = value.startsWith(country.dialCode)
            ? value.replace(country.dialCode, "")
            : value;

        setMobile(adjustedMobile);
        setCountryCode("+" + country.dialCode);
        setMobileError("");
        setEmail("");
    };

    const handleOnBlur = (e) => {
        const { name, value } = e.target;
        if (name === "Email") {
            if (!value) {
                setEmailError("This field is required");
            }
        } else {
            if (!value) {
                setMobileError("This field is required");
            }
        }
    };

    const handleChangeEmail = (e) => {
        const { name, value } = e.target;
        if (name === "Email") {
            if (textForCode === "resend_code") {
                setTextForCode("get_code");
            }
            const isEmail = /\S+@\S+\.\S+/.test(value);
            const isMobile = /^\d+$/.test(value);
            if (isMobile) {
                setSelectedFiledType("mobile");
                setInputMobile(value);
                setMobile(value);
                setEmailError("");
                return;
            } else if (isEmail) {
                setSelectedFiledType("email");
            }
            setEmail(value);
            const emailvalidation = EmailValidation(name, value);
            setEmailError(emailvalidation);
            setMobileError("");
        }
    };

    const meta = {
        title: "Low Cost Crypto Exchange App| Bitcoin Exchange",
        description: `From involving a large number of coins to executing voluminous orders, this platform takes care of everything. ${ProductName} Live Does The Buying And Selling Of Crypto Coin.`,
        canonical: `https://${ProductName}.com/login-to-buy-sell-crypto`,
        meta: {
            charset: "utf-8",
            name: {
                keywords:
                    "Crypto Exchange, Crypto Buying Selling, Buy Sell Bitcoin, BTC Exchange",
            },
        },
    };

    const keyPress = (e) => {
        if (verificationCode.length > 5) {
            if (e.key != "Backspace" && (!e.ctrlKey && e.keyCode != 86)) {
                e.preventDefault()
            }
        }
    }

    const handleChange = (checked) => {
        let tags = document.getElementsByTagName("body");

        if (checked === true) {
            tags[0].classList.add("dark-theme");
            //this.setState({ checked: false });
        } else {
            tags[0].classList.remove("dark-theme");
            // this.setState({ checked: true });
        }
        localStorage.setItem("theme", checked);
    };

    return (
        <>

            <DocumentMeta {...meta}>
                <div className="register-main-outer position-relative ">
                    <span className="main-bg fadeInLeft animated"></span>
                    <div className="container">
                        <div className="register-inner">
                            <div className="row">
                                <div className="col-md-5 col-12 p-0 m-auto">
                                    <div className="register-right fadeInRight animated ">
                                        <div className="heading-link-outer">
                                            <div className="heading">
                                                <h4 className="">{t("forgot Pin")}</h4>
                                            </div>
                                        </div>
                                        <div className="register-form">
                                            {!codeMatchedStatus ? (
                                                <form onSubmit={handleMobileSubmit}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-group position-relative">
                                                                {selectedFiledType === "email" ? (
                                                                    <>
                                                                        <label className="form-label">{t("Email")}</label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder={t("Enter Email Address")}
                                                                            name="Email"
                                                                            value={email}
                                                                            maxLength="64"
                                                                            onChange={handleChangeEmail}
                                                                            disabled={counter == 0 ? false : true}
                                                                            //onPaste={(e) => e.preventDefault()}
                                                                            autoFocus
                                                                        />
                                                                        {emailError && (
                                                                            <div style={{ color: "red" }} name="email">
                                                                                {t(emailError)}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <label className="form-label">
                                                                            {t("Mobile Number")}
                                                                        </label>

                                                                        <div className="row">
                                                                            <div className="col-3">
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    placeholder="Country Code"
                                                                                    name="countryCode"
                                                                                    autoFocus
                                                                                    value={countryCode}
                                                                                    onChange={handleChangeMobile}
                                                                                // disabled={counter == 0 ? false : true}
                                                                                />
                                                                            </div>
                                                                            <div className="col-9 pl-0">
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="number"
                                                                                    autoComplete="off"
                                                                                    placeholder="Enter Mobile No."
                                                                                    name="phone"
                                                                                    autoFocus
                                                                                    value={mobile}
                                                                                    onChange={handleChangeMobile}
                                                                                    disabled={counter == 0 ? false : true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {mobileError && (
                                                                            <div style={{ color: "red" }} name="mobile">
                                                                                {t(mobileError)}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                    // <PhoneInput
                                                                    //   key={"phoneInput"}
                                                                    //   country={automateCountryCode}
                                                                    //   onChange={handleOnChange}
                                                                    //   className="form-control country-input"
                                                                    //   placeholder="Email/Mobile"
                                                                    //   enableSearch={true}
                                                                    //   name="phone"
                                                                    //   inputProps={{
                                                                    //     autoFocus: true,
                                                                    //   }}
                                                                    //   disabled={counter == 0 ? false : true}
                                                                    //   countryCodeEditable={false}
                                                                    //   value={inputMobile}
                                                                    // />
                                                                )}


                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="form-label">
                                                                    {t("verification_code")}
                                                                </label>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder={t("Enter Verification Code")}
                                                                        name="VerificationCode"
                                                                        onChange={handlecodechange}
                                                                        disabled={
                                                                            codeMatchedStatus ||
                                                                            buttonDisableVerification
                                                                        }
                                                                        value={verificationCode}
                                                                        aria-label="Input group example"
                                                                        aria-describedby="btnGroupAddon"
                                                                        onKeyDown={(e) => { this.keyPress(e) }}
                                                                    />

                                                                    <div className="input-group-prepend">
                                                                        <button
                                                                            className="btn btn_man"
                                                                            id="btnGroupAddon"
                                                                            type="button"
                                                                            onClick={getCode}
                                                                            disabled={codeMatchedStatus || btnDisable}
                                                                        >
                                                                            {(timerText)}
                                                                            {"  "}
                                                                            {counter == 0 ? t(textForCode) : counter}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    {t(verificationCodeError)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {!codeMatchedStatus && (
                                                            <div className="col-md-6 col-12">
                                                                <div className="form-group">
                                                                    <button
                                                                        className="btn btn_man"
                                                                        type="submit"
                                                                        onClick={handleMobileSubmit}
                                                                    >
                                                                        {t("submit")}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </form>
                                            ) : (
                                                <form>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="form-label">
                                                                    {t("New Pin")}
                                                                </label>
                                                                <div className="eye_pass">
                                                                    <input
                                                                        className="form-control"
                                                                        type={type}
                                                                        name="Password"
                                                                        id="Password"
                                                                        placeholder={t("Enter New Pin")}
                                                                        value={password}
                                                                        onChange={handleChange101}
                                                                    />
                                                                    <span
                                                                        className="password__show eye1"
                                                                        onClick={() => {
                                                                            setType(
                                                                                type == "text" ? "password" : "text"
                                                                            );
                                                                        }}
                                                                    >
                                                                        {type === "text" ? (
                                                                            <i className="las la-eye"></i>
                                                                        ) : (
                                                                            <i className="las la-low-vision"></i>
                                                                        )}
                                                                    </span>
                                                                    {passwordIssue && (
                                                                        <div style={{ color: "red" }}>
                                                                            {t(passwordIssue)}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="form-label">
                                                                {t("Confirm Pin")}
                                                            </label>
                                                            <div className="form-group eye_pass">
                                                                <input
                                                                    className="form-control"
                                                                    type={type1}
                                                                    placeholder={t("Enter Confirm Pin")}
                                                                    //   onBlur={handleBlurChange}
                                                                    name="CPassword"
                                                                    onChange={handleChange101}
                                                                    value={cPassword}
                                                                />
                                                                <span
                                                                    className="password__show eye1"
                                                                    onClick={() => {
                                                                        setType1(
                                                                            type1 == "text" ? "password" : "text"
                                                                        );
                                                                    }}
                                                                >
                                                                    {type1 === "text" ? (
                                                                        <i className="las la-eye"></i>
                                                                    ) : (
                                                                        <i className="las la-low-vision"></i>
                                                                    )}
                                                                </span>

                                                                {cPasswordIssue && (
                                                                    <div style={{ color: "red" }}>
                                                                        {t(cPasswordIssue)}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <button className="btn w100 btn_man">Submit</button>
                                                    </div>*/}
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer limit={1} pauseOnHover={false} autoClose={4000} />
                    </div>
                </div>
            </DocumentMeta>
        </>
    );
}