import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { baseUrl, aheader } from "../Common/BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Nav from "../Common/Nav";
import Footer from "../Common/Footer";

const ApprovePayment = () => {
  const { id } = useParams();
  const history = useHistory();
  useEffect(() => {
    approveAmount();
  }, [id]);

  const approveAmount = () => {
    const data = {
      id: id,
    };
    axios
      .post(baseUrl + "/frontapi/approve-c2c-payment", data, aheader)
      .then((res) => {
        const resp = res.data;
        if (resp.status === true) {
          toast.success(resp.message);
          setTimeout(() => {
            history.push("/");
          }, 2000);
        } else {
          toast.error(resp.message);
        }
      })
      .catch();
  };
  return (
    <div className="">
      <Nav />
      <div className="new_inpage ">
        <div className="container">
          <div className="vr_img">
            <img src="assets/img/check.gif" alt="check" className="m-auto" />
          </div>
        </div>
      </div>

      <ToastContainer />
      <Footer />
    </div>
  );
};

export default ApprovePayment;
