import * as axios from 'axios';
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import authService from "../../services/auth.service";

import { baseUrl, aheader, aheader_form } from "../Common/BaseUrl"

// const history = useHistory();

const post = async (url = '', data, token = false) => {

    if (token) {
        token = 'Bearer ' + token;
    }

    let response = axios.post(url, data, aheader)

    return response;

    // axios.post(url, data, { headers: { Authorization: token } }).then((response) => {
    //     console.log("respost ", response);
    //     return response;

    // }).catch((e) => {
    //     console.log("respost error", e);
    //     toast.error(e.response.data.message);
    //     if (e.response.status == 401) {
    //         setTimeout(() => {
    //         authService.logout();
    //         window.location = "/";
    //     }, 1000);
    //         // history.push("/");
    //     }

    // })


}
const put = async (url = '', data, token = false) => {
    if (token) {
        token = 'Bearer ' + token;
    }
    let response = await axios.put(url, data, aheader);
    return response.data;
}
const get = async (url = '', token = false) => {
    if (token) {
        token = 'Bearer ' + token;
    }
    let response = await axios.get(url);
    return response.data;
}
const deleteData = async (url = '', data, token = false) => {
    if (token) {
        token = 'Bearer ' + token;
    }
    let response = await axios.delete(url, data, aheader);
    return response.data;
}
export {
    post,
    get,
    deleteData,
    put
}