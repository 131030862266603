import React from "react";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import { formatDecimal } from "../../utils/decimalFormatter";

function BuyOrder({
  myCompletedBuyOrder,
  myCompletedBuyOrderData,
  myActiveBuyOrder,
  myActiveBuyOrderData,
  updateAndDeleteButton,
  updateBuyOrder,
  deleteOrder,
}) {
  const { t } = useTranslation();
  const removeTrailingZerosOrder = (number) => {
    //console.log("NUM=>",number);
    const stringNumber = parseFloat(number).toFixed(8);
    const formattedNumber = parseFloat(stringNumber).toString();
    return formattedNumber;
  };

  const myCompletedBuyOrderHtml = () => {
    if (myCompletedBuyOrder) {
      var collectHtml = [];
      for (var i = 0; i < 10; i++) {
        var myCompletedBuyOrderSingle = myCompletedBuyOrderData[i];
        if (myCompletedBuyOrderSingle) {
          var getTime = Moment(myCompletedBuyOrderSingle.created_at).format(
            "lll"
          );
          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {formatDecimal(myCompletedBuyOrderSingle.per_price)}
              </td>
              <td className="f-left ng-binding">
                {removeTrailingZerosOrder(
                  myCompletedBuyOrderSingle.total_buy_get_amount
                )}
              </td>
              <td className="f-left ng-binding">
                {removeTrailingZerosOrder(
                  myCompletedBuyOrderSingle.per_price *
                    myCompletedBuyOrderSingle.total_buy_get_amount
                )}
              </td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={5} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myActiveBuyOrderHtml = () => {
    if (myActiveBuyOrder) {
      var collectHtml = [];

      myActiveBuyOrderData.forEach((e, i) => {
       // const parsedDate = ;
        var getTime = Moment(e.created_at).format("lll");
        collectHtml.push(
          <tr className="ng-scope" key={i}>
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">
              {formatDecimal(e.per_price)}
            </td>
            <td className="f-left ng-binding">{formatDecimal(e.buy_get_amount)}</td>
            <td className="f-left ng-binding">
              {formatDecimal(e.per_price * e.buy_get_amount)}
            </td>
            <td className="f-left ng-binding green">{getTime}</td>
            <td className="f-left ng-binding">
              {!e.binance_order_id && (
                <button
                  className="btn  mr-2"
                  disabled={updateAndDeleteButton}
                  onClick={() => updateBuyOrder(e.buy_id, "buy")}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}
              <button
                className="btn "
                disabled={updateAndDeleteButton}
                onClick={() => deleteOrder(e.buy_id, "buy")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        );
      });

      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  return (
    <>
      <div className="tab-pane active" id="Buytab" role="tabpanel">
        <div className="table_hadding flex-wrap table_hadding2">
          <h6 className="h6_head">{t("Buy Orders")}</h6>
          <div className="status-outer">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#pending"
                  role="tab"
                >
                  {t("Pending")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#complete"
                  role="tab"
                >
                  {t("Completed")}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="buy-order-outer tab-content">
          <div className="tab-pane active" id="pending" role="tabpanel">
            <div className="userRecords-main obo_exchange">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th className="f-left ng-binding">{t("Pair")}</th>
                    <th className="f-left ng-binding">{t("Price")}</th>
                    <th className="f-left ng-binding">{t("Amount")}</th>
                    <th className="f-left ng-binding">{t("Total")}</th>
                    <th className="f-left ng-binding">{t("Date & Time")}</th>
                    <th className="f-center cancels">
                      <span className="ng-binding p-0">{t("Actions")}</span>
                    </th>
                  </tr>
                </thead>
                {myActiveBuyOrderHtml()}
              </table>
            </div>
          </div>
          <div className="tab-pane" id="complete" role="tabpanel">
            <div className="userRecords-main">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>{t("Pair")}</th>
                    <th>{t("Price")}</th>
                    <th>{t("Amount")}</th>
                    <th>{t("Total")}</th>
                    <th>{t("Date")}</th>
                  </tr>
                </thead>
                {myCompletedBuyOrderHtml()}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuyOrder;
