import React from "react";
import { Component } from "react";
import { withTranslation, useTranslation } from 'react-i18next';

import MainHeader from "./Common/MainHeader";
import Footer from "./Common/Footer";
import DocumentMeta from 'react-document-meta';
import { ProductName } from "./Common/ProductName";


// class AboutUs extends Component {
//   render() {
//     const { t } = this.props;
//     const meta = {
//       title: 'About Us | Cooinjil  Exchange ',
//       description: "Cooinjil  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
//       canonical: 'https://Cooinjil  Exchange.live/about-us',
//       meta: {
//         charset: 'utf-8',
//         name: {
//           keywords: 'BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange'
//         }
//       }
//     };
//     return (
//       <div>
//       <DocumentMeta {...meta}>
//         <div>
//           <MainHeader />
//           <div className="privacy_policy">
//             <div className="container">
//               <h2>{t("About Us")}</h2>
//               <p>
//               {t(ProductName)} {t("Exc is the next-gen cryptocurrency Exc that empowers digital asset users with an all-engulfing trading environment.It makes the buying and selling of crypto coin way more effective and profitable as well. From involving a large number of coins to executing voluminous orders, this platform takes care of everything. It also includes a large bevy of coins that gives multiple opportunities to the traders to earn profits.")}
//                 {ProductName} {t("Exc takes crypto trading to a whole new level of performance and functionality.")}
//               </p>
//               <p>
//                 {t("Our effort to make")} {ProductName} {t("Exc an India’s most secure Exc. We work hard to ensure a highly secured and user friendly trading platform for the traders and users in India.")}
//               </p>
//               <p>
//                 {t("Top of the line identity verification systems process your KYC within a few hours of signing up, while ensuring the right KYC protocol. We’re building in robustness to sharply reduce verification times.")}
//               </p>
//               <p>
//               {ProductName} {t("Exc can handle Millions of transactions. Our system infrastructure can scale up in a few seconds to match surging demand.")}
//               </p>
//             </div>
//           </div>
//         </div>
//         <Footer />
//         </DocumentMeta>
//       </div>
//     );
//   }
// }

// export default withTranslation()(AboutUs);


const AboutUs = () => {
  const ProductName = 'DBI';
  const { t } = useTranslation();
  const meta = {
    title: 'About Us | DBI Exchange',
    description: "DBI Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Crypto Exchange In India Like Bitcoin And Dogecoin.",
    canonical: 'https://dbiexchange.com//about-us',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange'
      }
    }
  };

  // Replace with the actual product name if it's dynamic.

  return (
    <div>
      <DocumentMeta {...meta}>
        <div>
          <MainHeader />
          <div className="privacy_policy">
            <div className="container">
              <h2>{t("About Us")}</h2>
              <p>
                {t(ProductName)}{' '}
                {t("Exc is the next-gen cryptocurrency Exc that empowers digital asset users with an all-engulfing trading environment.It makes the buying and selling of crypto coin way more effective and profitable as well. From involving a large number of coins to executing voluminous orders, this platform takes care of everything. It also includes a large bevy of coins that gives multiple opportunities to the traders to earn profits.")}
                {ProductName}{' '}
                {t("Exc takes crypto trading to a whole new level of performance and functionality.")}
              </p>
              <p>
                {t("Our effort to make")} {ProductName}{' '}
                {t(
                  "Exc an India’s most secure Exc. We work hard to ensure a highly secured and user friendly trading platform for the traders and users in India."
                )}
              </p>
              <p>
                {t(
                  "Top of the line identity verification systems process your KYC within a few hours of signing up, while ensuring the right KYC protocol. We’re building in robustness to sharply reduce verification times."
                )}
              </p>
              <p>
                {ProductName}{' '}
                {t(
                  "Exc can handle Millions of transactions. Our system infrastructure can scale up in a few seconds to match surging demand."
                )}
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </DocumentMeta>
    </div>
  );
};

export default AboutUs;
