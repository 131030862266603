import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { baseUrl,imgUrl } from "../../Common/BaseUrl";

const Banners = () => {
    const {i18n, t } = useTranslation();
    const [state, setState] = useState();
    const [bannerList, setBannerList] = useState([]);
    const [bannerTextList, setBannerTextList] = useState([]);

    // useEffect(() => {
    //   //  getBanners()

    // }, [i18n.language])


    const getBanners = () => {
        let lang = localStorage.getItem("lang");
        console.log(lang);
        const config = {
            headers: {
                lang:lang,
             // Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
         // console.log(config);
        axios
            .get(baseUrl + "/frontapi/get-blog",config)
            .then((res) => {
                console.log(res.data)
                if (res.data.status) {
                    setBannerList(res.data.banner_data);
                    setBannerTextList(res.data.banner_text_data)
                }
            })
            .catch((err) => { });
    }


    return (
        <div className="how-main-outer text-center">
            <div className="container">
                <div className="section-heading mb-md-4">
                    <h2>{t("Banners")}</h2>

                </div>
                <div className="how-outer">
                    <div className="row">
                        <div className="col-md-12 col-12" >
                            <div className="banner-list-outer">
                                <ul>
                                    {(bannerList) ? bannerList.map((val, index) =>
                                        <li key={index}><img src={imgUrl+"/static/banner/" + val.banner_image} alt="" className="img-fluid" /></li>
                                    ) : ""}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12 col-12" >
                            <div className="feature-list-outer">
                            <ul>
                                    {(bannerTextList) ? bannerTextList.map((val, index) =>
                                        <li key={index}><span>{val.header}</span></li>
                                    ) : ""}
                                </ul>
                            </div>
                        </div>


                        {/* <div className="col-md-3 col-12 mb-4">
            <div className="how-inner ">
              <div className="img-outer">
                <img src="assets/img/cryptomining-hit.png" alt="" className="img-fluid" />
              </div>
              <div className="content-outer">

                <h3>{t("Verify Your Account")}</h3>
                <p>{t("Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.")}</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12 mb-4 ">
            <div className="how-inner ">
              <div className="img-outer">
                <img src="assets/img/crypto-wallet-hit.png" alt="" className="img-fluid" />
              </div>
              <div className="content-outer">

                <h3>{t("Add Fund in Wallet")}</h3>
                <p>{t("Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.")}</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12 mb-4">
            <div className="how-inner ">
              <div className="img-outer">
                <img src="assets/img/crypto-trading-hit.png" alt="" className="img-fluid" />
              </div>
              <div className="content-outer">

                <h3>{t("Start Trading Instantiy")}</h3>
                <p>{t("Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.")}</p>
              </div>
            </div>
          </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banners