import React from "react";

export const EmailValidation = (name,value) => {
let emailAddressError = "";
value = value.toLowerCase()
if (!value) {
    emailAddressError = "This field is required";
    return emailAddressError
}
// var Email1 = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
//     value
// );
let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
//let regex = /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;
var Email1 = regex.test(
    value
);
//console.log("sfhbsduyfgsdftygvsdfsdf",value,Email1)
if (!Email1) {
    emailAddressError = "Please enter a valid email address";
    return emailAddressError
}
// if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
//     emailAddressError = "Please enter a valid email address";
//     return emailAddressError
// }
if (value.replace(/\s+/g, '').length == 0) {
    emailAddressError = "Please enter a valid email address"
    return emailAddressError
}
return emailAddressError
}
